import API, { graphqlOperation } from '@aws-amplify/api';

import { deleteParts } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPart, IState } from '../../../Interfaces';
import { onNavigateToHomePage } from '../../Home';
import { onDisplayLoader, onResetSearch } from '../../Search';

import { NotificationManager } from "react-notifications";
const onDeletePartInit = () => {
    return {
        type: ACTION_TYPES.PART.DELETE.INIT,
        payload: null,
    };
};

const onDeletePartSuccess = (deletedPart: IPart) => {
    return {
        type: ACTION_TYPES.PART.DELETE.SUCCESS,
        payload: deletedPart,
    };
};

const onDeletePartFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Delete Part');
    console.error('Failure on Delete Part - ', errorMessage);
    return {
        type: ACTION_TYPES.PART.DELETE.FAIL,
        payload: errorMessage,
    };
};


const onDeletePartDone = () => {
    return {
        type: ACTION_TYPES.PART.DELETE.DONE,
        payload: null,
    };
};

const onDeletePart = (partId, history) => {
    return async (dispatch, getState) => {
        dispatch(onDeletePartInit());
        try {
            const state = getState() as IState;
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(true))
              }
            const partToBeDeleted = state.data.parts.loadedParts.find(p => p.id === partId) as IPart;
            const variables = {
                input: {
                    id: partToBeDeleted.id,
                    _version: partToBeDeleted._version,
                },
            };
            const result = await API.graphql(graphqlOperation(deleteParts, variables));
            const deletedPart = ((result as any)?.data?.deleteParts) as IPart;
            dispatch(onDeletePartSuccess(deletedPart));
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(false))   
                dispatch(onNavigateToHomePage(history))
                dispatch(onResetSearch())
                }

        }
        catch (graphqlError) {
            const errorMessage = `Delete part failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onDeletePartFail(errorMessage));
        }
        dispatch(onDeletePartDone());
    };
};

export default onDeletePart;