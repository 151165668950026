import { IBuild, IState, IDisplayBuild } from "../Interfaces";

const getBuildStatusTitle = (current_status) => {
    let buildStatusTitle = '' as string;
    switch (current_status) {
        case 'All':
            buildStatusTitle = 'All';
            break;
        case 'completed':
            buildStatusTitle = 'Completed';
            break;
        case 'failed':
            buildStatusTitle = 'Failed';
            break;
        case 'canceled':
            buildStatusTitle = 'Canceled';
            break;
        default:
            buildStatusTitle = 'In Progress';
            break;
    }
    return buildStatusTitle;
}

const getDisplayBuild = (build: IBuild, state: IState) => {
    const buildId = build.id || '' as string;
    const buildName = build.name || '' as string;
    const buildStatus = build.current_status || '' as string;
    const buildStatusTitle = getBuildStatusTitle(build.current_status) as string;
    const displayBuild = {
        id: buildId as string,
        name: buildName as string,
        status: buildStatus as string,
        buildStatusTitle: buildStatusTitle as string,
        index: state.data.builds.loadedBuilds.indexOf(build) as number,
        isValid: Boolean(build) as boolean,
        isFiltered: Boolean(build.name.toLowerCase().includes(state.data.builds.searchText.toLowerCase()) && (!state.data.builds.selectedBuildStatus || (buildStatusTitle === state.data.builds.selectedBuildStatus))) as boolean,
        isSelected: Boolean(state.data.builds.selectedBuildIds.some(id => id === build.id)) as boolean,
        isHighlighted: Boolean(state.data.builds.highlightedBuildId === build.id) as boolean,
        isRecent: Boolean((((new Date()).getTime() - (new Date(build.createdAt)).getTime()) / (60 * 60 * 1000)) < 24) as boolean,
        isYours: Boolean((build.created_by === state.creator) && !Boolean(build.archive)) as boolean,
        isOthers: Boolean((build.created_by !== state.creator) && !Boolean(build.archive)) as boolean,
        isArchived: Boolean(build.archive) as boolean,
        isMovedToFolder: Boolean(build.folders && build.folders.length > 0),
        createdDate: (new Date(build.createdAt) as Date).toLocaleString() as string,
        creator: build.created_by as string,
        hasParts: Boolean(build.hasParts) as boolean,
        parts: build.parts as string[],
        hasPlates: Boolean(build.hasPlates) as boolean,
        plates: (Boolean(build.plates) ? build.plates.map(plate => JSON.parse(plate)['name']) : []) as string[],
        hasFolders: Boolean(build.hasFolders) as boolean,
        folders: (Boolean(build.folders) ? build.folders.map(folder => JSON.parse(folder)['name']) : []) as string[],
        hasTags: Boolean(build.hasTags) as boolean,
        tags: (Boolean(build.tags) ? build.tags.map(tag => JSON.parse(tag)['name']) : []) as string[],
    };
    // console.log("Created Date" + JSON.stringify(displayBuild, undefined, 4));

    if (false && state.data.folders.selectedFolderIds && state.data.folders.selectedFolderIds.length > 0) {
        displayBuild.isFiltered = displayBuild.isFiltered && build.folders && build.folders.some(folder => state.data.folders.selectedFolderIds.some(id => id === JSON.parse(folder).name));
    }

    return displayBuild as IDisplayBuild;
};

export default getDisplayBuild;