import IPartInfo from "../../../../interfaces/IPartInfo";
import { TabIndex } from "../../../Enums";
import { IBuild, IPart, IState } from "../../../Interfaces";

const onNewBuildSuccessReducer = (state: IState, action) => {
    const addedBuild = action.payload as IBuild;
    
    const newState = { ...state } as IState;
    newState.data.workspace.addedParts = [] as IPart[];
    newState.data.workspace.state.build.parts = [] as IPartInfo[];
    newState.data.workspace.isLoadingInProgress = !newState.data.workspace.isLoadingInProgress;
    
    newState.data.modals.newBuildModalStatus = {
        ...newState.data.modals.newBuildModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };

    newState.data.info.homePageTabIndex = TabIndex.BUILDS;
    
    return newState;
};

export default onNewBuildSuccessReducer;
