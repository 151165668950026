/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "influxPrinterApi",
            "endpoint": "https://l5bmtvg1si.execute-api.us-east-1.amazonaws.com/bv",
            "region": "us-east-1"
        },
        {
            "name": "lavaNotificationApi",
            "endpoint": "https://uymvh2guqg.execute-api.us-east-1.amazonaws.com/bv",
            "region": "us-east-1"
        },
        {
            "name": "lavaPrinterControllerApi",
            "endpoint": "https://szbtv8uq75.execute-api.us-east-1.amazonaws.com/bv",
            "region": "us-east-1"
        },
        {
            "name": "lavaRestApi",
            "endpoint": "https://car9chwm8j.execute-api.us-east-1.amazonaws.com/bv",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://ljmd5ym4pvc67j45upexsz6i5q.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-bbbuf4r6vjcynacjnbntsxgtea",
    "aws_cognito_identity_pool_id": "us-east-1:cd1b249f-2a96-40c6-affb-ba6be7f3c70d",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_Y0HU5nIk1",
    "aws_user_pools_web_client_id": "7q3d6bj1oa8u9gomf4lvav6gm9",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "vizapp-s3125945-bv",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
