import { Storage } from 'aws-amplify';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

import ACTION_TYPES from '../../../actionTypes';
import { IBuild, IState, } from '../../../Interfaces';
import onResetSearch from "../../Search/reset"
import onGetHighlightedBuild from "../../Search/highlightBuild";

import { NotificationManager } from "react-notifications";
const onDownloadLogRequest = () => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.LOG.REQUEST,
        payload: null,
    };
};

const onDownloadLogStart = (contentLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.LOG.START,
        payload: contentLength,
    };
};

const onDownloadLogInProgress = (processedLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.LOG.IN_PROGRESS,
        payload: processedLength,
    };
};

const onDownloadLogSuccess = (receivedLength: number, contentLength: number) => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.LOG.SUCCESS,
        payload: {
            receivedLength,
            contentLength,
        },
    };
};

const onDownloadLogFail = (receivedLength: number, contentLength: number) => {
    NotificationManager.error('Failure on Downloading log');
    console.error('Failure on Downloading log - receivedLength', receivedLength, 'contentLength', contentLength);
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.LOG.FAIL,
        payload: {
            receivedLength,
            contentLength,
        },
    };
};

const onDownloadLogDone = () => {
    return {
        type: ACTION_TYPES.HOME.DOWNLOAD.LOG.DONE,
        payload: null,
    };
};

const onDownloadLog = (buildId) => {
    return async (dispatch, getState) => {
        dispatch(onDownloadLogRequest());
        
        try {
            dispatch(onDownloadLogStart(1024));
            const state = getState() as IState;
            const selectedBuild = !state.data.search.isSearchEnabled ? (state.data.builds.loadedBuilds.find(
                (b) => b.id === buildId
            ) as IBuild) : state.data.search.highlightedBuildData as IBuild
          
            const logPath = `${selectedBuild.bundle.replace('public/', '')}Log/log.txt`;
            const result = await Storage.get(logPath, { download: true });
            dispatch(onDownloadLogInProgress(1024));
            const fileName = logPath.slice(selectedBuild.bundle.replace('public/', '').length);
            const data = (result as any).Body;
            const zip = new JSZip();
            zip.file(fileName, data);
            const content = await zip.generateAsync({ type: 'blob' });
            const name = selectedBuild.name + '_Log.zip';
            saveAs(content, name, 'application/zip');
            dispatch(onDownloadLogSuccess(1024, 1024));
            // if (state.data.search.isSearchEnabled) {
            //     dispatch(onResetSearch())
            // }
        }
        catch (error) {
            dispatch(onDownloadLogFail(1024, 1024));
        }
        dispatch(onDownloadLogDone());
    };
};

export default onDownloadLog;