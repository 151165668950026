import { IState } from "../../../Interfaces";

const onSelectPlateReducer = (state: IState, action) => {
    const plateId = action.payload as string;
    
    const newState = { ...state } as IState;
    newState.data.plates.selectedPlateIds = newState.data.plates.selectedPlateIds.concat(plateId);
    
    return newState;
};

export default onSelectPlateReducer;