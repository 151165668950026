import ACTION_TYPES from "./actionTypes";
import { IState } from "./Interfaces";
import initialState from "./initialState";
import * as Reducers from "./Reducers/index";

const rootReducer = (state: IState = initialState, action) => {
  switch (action.type) {
    case ACTION_TYPES.BUILD.CANCEL.INIT:
      return state;
    case ACTION_TYPES.BUILD.CANCEL.DONE:
      return Reducers.onCancelBuildReducer(state, action);

    case ACTION_TYPES.BUILD.DELETE.ACTION:
      return Reducers.onRebuildReducer(state, action);
    
      case ACTION_TYPES.BUILD.DELETE.UPDATE_PLATE:
      return Reducers.onUpdatePlateAfterBuildDeleteReducer(state, action);

    case ACTION_TYPES.BUILD.REBUILD.ACTION:
      return Reducers.onRebuildSuccessReducer(state, action);

    case ACTION_TYPES.BUILDS.BUILD_STATUS_FILTER.ACTION:
      return Reducers.onBuildStatusFilterChangeReducer(state, action);

    case ACTION_TYPES.BUILDS.HIDE.ALL.ACTION:
      return Reducers.onHideAllBuildsReducer(state, action);
    case ACTION_TYPES.BUILDS.HIDE.ARCHIVED.ACTION:
      return Reducers.onHideArchivedBuildsReducer(state, action);
    case ACTION_TYPES.BUILDS.HIDE.RECENT.ACTION:
      return Reducers.onHideRecentBuildsReducer(state, action);

    case ACTION_TYPES.BUILD.HIGHLIGHT.ACTION:
      return Reducers.onHighlightBuildReducer(state, action);

    case ACTION_TYPES.BUILD.MOVE.OPEN_DIALOG:
      return Reducers.onMoveBuildOpenDialogReducer(state, action);
    case ACTION_TYPES.BUILD.MOVE.NAME_CHANGE:
      return Reducers.onMoveBuildNameChangeReducer(state, action);
    case ACTION_TYPES.BUILD.MOVE.INIT:
      return state;
    case ACTION_TYPES.BUILD.MOVE.SUCCESS:
      return Reducers.onMoveBuildSuccessReducer(state, action);
    case ACTION_TYPES.BUILD.MOVE.FAIL:
      return Reducers.onMoveBuildFailReducer(state, action);
    case ACTION_TYPES.BUILD.MOVE.DONE:
      return state;
    case ACTION_TYPES.BUILD.MOVE.CLOSE_DIALOG:
      return Reducers.onMoveBuildCloseDialogReducer(state, action);

    case ACTION_TYPES.BUILD.ADD.OPEN_DIALOG:
      return Reducers.onNewBuildOpenDialogReducer(state, action);
    case ACTION_TYPES.BUILD.ADD.NAME_CHANGE:
      return Reducers.onNewBuildNameChangeReducer(state, action);
    case ACTION_TYPES.BUILD.ADD.SUCCESS:
      return Reducers.onNewBuildSuccessReducer(state, action);
    case ACTION_TYPES.BUILD.ADD.FAIL:
      return Reducers.onNewBuildFailReducer(state, action);
    case ACTION_TYPES.BUILD.ADD.CLOSE_DIALOG:
      return Reducers.onNewBuildCloseDialogReducer(state, action);
    case ACTION_TYPES.BUILD.SUCCESS.OPEN_DIALOG:
      return Reducers.onNewBuildSuccessOpenDialogReducer(state, action);
    case ACTION_TYPES.BUILD.SUCCESS.CLOSE_DIALOG:
      return Reducers.onNewBuildSuccessCloseDialogReducer(state, action);

    case ACTION_TYPES.SEARCH.GETSEARCHSTRING:
      return Reducers.onGetSearchStringReducer(state, action);

    case ACTION_TYPES.SEARCH.GETSEARCHCATEGORY:
      return Reducers.onGetSearchCategoryReducer(state, action);
    
    case ACTION_TYPES.SEARCH.PARTS_SUCCESS:
      return Reducers.onPartsSearchSuccessReducer(state, action);

    case ACTION_TYPES.SEARCH.PLATES_SUCCESS:
      return Reducers.onPlatesSearchSuccessReducer(state, action);

    case ACTION_TYPES.SEARCH.BUILDS_SUCCESS:
      return Reducers.onBuildsSearchSuccessReducer(state, action);

    case ACTION_TYPES.SEARCH.RESET_RESULTS:
      return Reducers.onResetSearchResultsReducer(state, action);

    case ACTION_TYPES.SEARCH.PARTS_SEARCH_COMPLETED:
      return Reducers.onPartsSearchCompletedReducer(state, action);

    case ACTION_TYPES.SEARCH.PLATES_SEARCH_COMPLETED:
      return Reducers.onPlatesSearchCompletedReducer(state, action);

    case ACTION_TYPES.SEARCH.BUILDS_SEARCH_COMPLETED:
      return Reducers.onBuildsSearchCompletedReducer(state, action);

    case ACTION_TYPES.SEARCH.IS_SEARCH_ENABLED:
      return Reducers.onSearchEnabledReducer(state, action);

    case ACTION_TYPES.SEARCH.DISPLAY_LOADER:
      return Reducers.onDisplayLoaderReducer(state, action);


    case ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.INIT:
      return state;

    case ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.SUCCESS:
      return Reducers.onGetHighlightedBuildReducer(state, action);
    
    case ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.FAIL:
    case ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.DONE:
      return Reducers.onGetHighlightBuildStatusReducer(state, action);

    case ACTION_TYPES.SEARCH.HIGHLIGHT_BUILD.RESET:
      return Reducers.onResetHighlightedBuildReducer(state, action);

  
    case ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.INIT:
      return state;

    case ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.SUCCESS:
      return Reducers.onGetHighlightedPlateReducer(state, action);

    case ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.FAIL:
    case ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.DONE:
      return Reducers.onGetHighlightPlateStatusReducer(state, action);

    case ACTION_TYPES.SEARCH.HIGHLIGHT_PLATE.RESET:
      return Reducers.onResetHighlightedPlateReducer(state, action);

    case ACTION_TYPES.SEARCH.HIGHLIGHT_PART.INIT:
      return state;

    case ACTION_TYPES.SEARCH.HIGHLIGHT_PART.SUCCESS:
      return Reducers.onGetHighlightedPartReducer(state, action);

    case ACTION_TYPES.SEARCH.HIGHLIGHT_PART.FAIL:
    case ACTION_TYPES.SEARCH.HIGHLIGHT_PART.DONE:
      return Reducers.onGetHighlightPartStatusReducer(state, action);

    case ACTION_TYPES.SEARCH.HIGHLIGHT_PART.RESET:
      return Reducers.onResetHighlightedPartReducer(state, action);

    case ACTION_TYPES.BUILDS.SEARCH.ACTION:
      return Reducers.onSearchBuildsTextChangeReducer(state, action);

    case ACTION_TYPES.BUILDS.SHOW.ALL.ACTION:
      return Reducers.onShowAllBuildsReducer(state, action);
    case ACTION_TYPES.BUILDS.SHOW.ARCHIVED.ACTION:
      return Reducers.onShowArchivedBuildsReducer(state, action);
    case ACTION_TYPES.BUILDS.SHOW.RECENT.ACTION:
      return Reducers.onShowRecentBuildsReducer(state, action);

    case ACTION_TYPES.BUILDS.SORT.ASC.ACTION:
      return Reducers.onSortBuildsAscReducer(state, action);
    case ACTION_TYPES.BUILDS.SORT.DESC.ACTION:
      return Reducers.onSortBuildsDescReducer(state, action);

    case ACTION_TYPES.BUILD.UPDATE.INIT:
    case ACTION_TYPES.BUILD.UPDATE.DONE:
    case ACTION_TYPES.BUILD.UPDATE.FAIL:
      return state;
    case ACTION_TYPES.BUILD.UPDATE.SUCCESS:
      return Reducers.onUpdateBuildReducer(state, action);

    case ACTION_TYPES.CONFIGURATION.ADD.OPEN_DIALOG:
      return Reducers.onAddConfigurationOpenDialogReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.ADD.NAME_CHANGE:
      return Reducers.onAddConfigurationNameChangeReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.ADD.TYPE_CHANGE:
      return Reducers.onAddConfigurationTypeChangeReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.ADD.DATA_CHANGE:
      return Reducers.onAddConfigurationDataChangeReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.ADD.SUCCESS:
      return Reducers.onAddConfigurationSuccessReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.ADD.FAIL:
      return Reducers.onAddConfigurationFailReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.ADD.CLOSE_DIALOG:
      return Reducers.onAddConfigurationCloseDialogReducer(state, action);

    case ACTION_TYPES.CONFIGURATION.COPY.OPEN_DIALOG:
      return Reducers.onCopyConfigurationOpenDialogReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.COPY.NAME_CHANGE:
      return Reducers.onCopyConfigurationNameChangeReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.COPY.INIT:
      return state;
    case ACTION_TYPES.CONFIGURATION.COPY.SUCCESS:
      return Reducers.onCopyConfigurationSuccessReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.COPY.FAIL:
      return Reducers.onCopyConfigurationFailReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.COPY.DONE:
      return state;
    case ACTION_TYPES.CONFIGURATION.COPY.CLOSE_DIALOG:
      return Reducers.onCopyConfigurationCloseDialogReducer(state, action);

    case ACTION_TYPES.CONFIGURATION.DELETE.INIT:
    case ACTION_TYPES.CONFIGURATION.DELETE.DONE:
    case ACTION_TYPES.CONFIGURATION.DELETE.FAIL:
      return state;


    case ACTION_TYPES.CONFIGURATION.DELETE.SUCCESS:
      return Reducers.onDeleteConfigurationReducer(state, action);

    case ACTION_TYPES.CONFIGURATION.EDIT.ACTION:
      return Reducers.onEditConfigurationDataReducer(state, action);

    case ACTION_TYPES.CONFIGURATION.HIGHLIGHT.ACTION:
      return Reducers.onHighlightConfigurationReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.HIGHLIGHT.TYPE.ACTION:
      return Reducers.onHighlightConfigurationTypeReducer(state, action);

    case ACTION_TYPES.CONFIGURATION.RENAME.OPEN_DIALOG:
      return Reducers.onRenameConfigurationOpenDialogReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.RENAME.NAME_CHANGE:
      return Reducers.onRenameConfigurationNameChangeReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.RENAME.INIT:
      return state;
    case ACTION_TYPES.CONFIGURATION.RENAME.SUCCESS:
      return Reducers.onRenameConfigurationSuccessReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.RENAME.FAIL:
      return Reducers.onRenameConfigurationFailReducer(state, action);
    case ACTION_TYPES.CONFIGURATION.RENAME.DONE:
      return state;
    case ACTION_TYPES.CONFIGURATION.RENAME.CLOSE_DIALOG:
      return Reducers.onRenameConfigurationCloseDialogReducer(state, action);

    case ACTION_TYPES.CONFIGURATION.RESET.ACTION:
      return Reducers.onResetConfigurationScreenReducer(state, action);

    case ACTION_TYPES.CONFIGURATION.DELETEDCONFIGS.SUCCESS:
      return Reducers.onFetchDeletedConfigurationsSuccessreducer(state,action)

    case ACTION_TYPES.CONFIGURATION.UPDATE.INIT:
    case ACTION_TYPES.CONFIGURATION.UPDATE.DONE:
    case ACTION_TYPES.CONFIGURATION.UPDATE.FAIL:
      return state;
    case ACTION_TYPES.CONFIGURATION.UPDATE.SUCCESS:
      return Reducers.onUpdateConfigurationReducer(state, action);

    case ACTION_TYPES.FOLDER.ARCHIVE.INIT:
    case ACTION_TYPES.FOLDER.ARCHIVE.DONE:
    case ACTION_TYPES.FOLDER.ARCHIVE.FAIL:
      return state;
    case ACTION_TYPES.FOLDER.ARCHIVE.SUCCESS:
      return Reducers.onArchiveFolderReducer(state, action);

    case ACTION_TYPES.FOLDER.DELETE.INIT:
    case ACTION_TYPES.FOLDER.DELETE.DONE:
    case ACTION_TYPES.FOLDER.DELETE.FAIL:
      return state;
    case ACTION_TYPES.FOLDER.DELETE.SUCCESS:
      return Reducers.onDeleteFolderReducer(state, action);

    case ACTION_TYPES.FOLDER.HIGHLIGHT.ACTION:
      return Reducers.onHighlightFolderReducer(state, action);

    case ACTION_TYPES.FOLDER.RENAME.OPEN_DIALOG:
      return Reducers.onRenameFolderOpenDialogReducer(state, action);
    case ACTION_TYPES.FOLDER.RENAME.NAME_CHANGE:
      return Reducers.onRenameFolderNameChangeReducer(state, action);
    case ACTION_TYPES.FOLDER.RENAME.INIT:
      return state;
    case ACTION_TYPES.FOLDER.RENAME.SUCCESS:
      return Reducers.onRenameFolderSuccessReducer(state, action);
    case ACTION_TYPES.FOLDER.RENAME.FAIL:
      return Reducers.onRenameFolderFailReducer(state, action);
    case ACTION_TYPES.FOLDER.RENAME.DONE:
      return state;
    case ACTION_TYPES.FOLDER.RENAME.CLOSE_DIALOG:
      return Reducers.onRenameFolderCloseDialogReducer(state, action);

    case ACTION_TYPES.FOLDER.UNARCHIVE.INIT:
    case ACTION_TYPES.FOLDER.UNARCHIVE.DONE:
    case ACTION_TYPES.FOLDER.UNARCHIVE.FAIL:
      return state;
    case ACTION_TYPES.FOLDER.UNARCHIVE.SUCCESS:
      return Reducers.onUnarchiveFolderReducer(state, action);

    case ACTION_TYPES.FOLDERS.ACCORDIONS.COLLAPSE.ACTION:
      return Reducers.onCollapseAccordionsReducer(state, action);

    case ACTION_TYPES.FOLDERS.ACCORDIONS.EXPAND.ACTION:
      return Reducers.onExpandAccordionsReducer(state, action);

    case ACTION_TYPES.HOME.DOWNLOAD.PART.REQUEST:
    case ACTION_TYPES.HOME.DOWNLOAD.GCODE.REQUEST:
    case ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.REQUEST:
    case ACTION_TYPES.HOME.DOWNLOAD.LOG.REQUEST:
      return Reducers.onDownloadRequestReducer(state, action);

    case ACTION_TYPES.HOME.DOWNLOAD.PART.START:
    case ACTION_TYPES.HOME.DOWNLOAD.GCODE.START:
    case ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.START:
    case ACTION_TYPES.HOME.DOWNLOAD.LOG.START:
      return Reducers.onDownloadStartReducer(state, action);

    case ACTION_TYPES.HOME.DOWNLOAD.PART.IN_PROGRESS:
    case ACTION_TYPES.HOME.DOWNLOAD.GCODE.IN_PROGRESS:
    case ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.IN_PROGRESS:
    case ACTION_TYPES.HOME.DOWNLOAD.LOG.IN_PROGRESS:
      return Reducers.onDownloadInProgressReducer(state, action);

    case ACTION_TYPES.HOME.DOWNLOAD.PART.SUCCESS:
    case ACTION_TYPES.HOME.DOWNLOAD.GCODE.SUCCESS:
    case ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.SUCCESS:
    case ACTION_TYPES.HOME.DOWNLOAD.LOG.SUCCESS:
      return Reducers.onDownloadSuccessReducer(state, action);

    case ACTION_TYPES.HOME.DOWNLOAD.PART.FAIL:
    case ACTION_TYPES.HOME.DOWNLOAD.GCODE.FAIL:
    case ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.FAIL:
    case ACTION_TYPES.HOME.DOWNLOAD.LOG.FAIL:
      return Reducers.onDownloadFailReducer(state, action);

    case ACTION_TYPES.HOME.DOWNLOAD.PART.DONE:
    case ACTION_TYPES.HOME.DOWNLOAD.GCODE.DONE:
    case ACTION_TYPES.HOME.DOWNLOAD.BUNDLE.DONE:
    case ACTION_TYPES.HOME.DOWNLOAD.LOG.DONE:
      return Reducers.onDownloadDoneReducer(state, action);

    case ACTION_TYPES.HOME.FOLDER_FILTER.ACTION:
      return Reducers.onSelectFolderReducer(state, action);

    case ACTION_TYPES.HOME.LOAD.CURRENT_USER_INFO.INIT:
      return Reducers.onLoadCurrentUserInfoReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.CURRENT_USER_INFO.DONE:
      return Reducers.onLoadCurrentUserInfoReducer(state, action);

    case ACTION_TYPES.HOME.LOAD.FOLDERS.STARTED:
      return Reducers.onLoadFoldersStartedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.FOLDERS.IN_PROGRESS:
      return Reducers.onLoadFoldersInProgressReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.FOLDERS.COMPLETED:
      return Reducers.onLoadFoldersCompletedReducer(state, action);

    case ACTION_TYPES.HOME.LOAD.TAGS.STARTED:
      return Reducers.onLoadTagsStartedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.TAGS.IN_PROGRESS:
      return Reducers.onLoadTagsInProgressReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.TAGS.COMPLETED:
      return Reducers.onLoadTagsCompletedReducer(state, action);

    case ACTION_TYPES.HOME.LOAD.PARTS.STARTED:
      return Reducers.onLoadPartsStartedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.PARTS.IN_PROGRESS:
      return Reducers.onLoadPartsInProgressReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.PARTS.COMPLETED:
      return Reducers.onLoadPartsCompletedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.PARTS.CREATED:
      return Reducers.onLoadPartsCreatedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.PARTS.DELETED:
      return Reducers.onLoadPartsDeletedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.PARTS.UPDATED:
      return Reducers.onLoadPartsUpdatedReducer(state, action);

    case ACTION_TYPES.HOME.LOAD.PLATES.STARTED:
      return Reducers.onLoadPlatesStartedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.PLATES.IN_PROGRESS:
      return Reducers.onLoadPlatesInProgressReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.PLATES.COMPLETED:
      return Reducers.onLoadPlatesCompletedReducer(state, action);
    case ACTION_TYPES.PLATES.SEARCH.ACTION:
      return Reducers.onSearchPlatesTextChangeReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.PLATES.CREATED:
      return Reducers.onLoadPlatesCreatedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.PLATES.DELETED:
      return Reducers.onLoadPlatesDeletedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.PLATES.UPDATED:
      return Reducers.onLoadPlatesUpdatedReducer(state, action);

    case ACTION_TYPES.HOME.LOAD.BUILDS.STARTED:
      return Reducers.onLoadBuildsStartedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.BUILDS.IN_PROGRESS:
      return Reducers.onLoadBuildsInProgressReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.BUILDS.COMPLETED:
      return Reducers.onLoadBuildsCompletedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.BUILDS.CREATED:
      return Reducers.onLoadBuildsCreatedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.BUILDS.DELETED:
      return Reducers.onLoadBuildsDeletedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.BUILDS.UPDATED:
      return Reducers.onLoadBuildsUpdatedReducer(state, action);

    case ACTION_TYPES.HOME.LOAD.CONFIGURATIONS.STARTED:
      return Reducers.onLoadConfigurationsStartedReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.CONFIGURATIONS.IN_PROGRESS:
      return Reducers.onLoadConfigurationsInProgressReducer(state, action);
    case ACTION_TYPES.HOME.LOAD.CONFIGURATIONS.COMPLETED:
      return Reducers.onLoadConfigurationsCompletedReducer(state, action);

    case ACTION_TYPES.HOME.NAVIGATE.PAGES.HOME.DONE:
      return Reducers.onNavigateToHomePageReducer(state, action);

    case ACTION_TYPES.HOME.NAVIGATE.PAGES.PLATES.INIT:
      return Reducers.onNavigateToPlatesPageReducer(state, action);

    case ACTION_TYPES.HOME.NAVIGATE.PAGES.PART_REVIEW.INIT:
      return Reducers.onNavigateToPartReviewPageReducer(state, action);

    case ACTION_TYPES.HOME.NAVIGATE.PAGES.BUILD_PREVIEW.INIT:
      return Reducers.onNavigateToBuildPreviewPageReducer(state, action);

    case ACTION_TYPES.HOME.NAVIGATE.PAGES.REVIEW.INIT:
      return Reducers.onNavigateToReviewPageReducer(state, action);

    case ACTION_TYPES.HOME.NEW.FOLDER.OPEN_DIALOG:
      return Reducers.onNewFolderOpenDialogReducer(state, action);
    case ACTION_TYPES.HOME.NEW.FOLDER.NAME_CHANGE:
      return Reducers.onNewFolderNameChangeReducer(state, action);
    case ACTION_TYPES.HOME.NEW.FOLDER.SUCCESS:
      return Reducers.onNewFolderSuccessReducer(state, action);
    case ACTION_TYPES.HOME.NEW.FOLDER.FAIL:
      return Reducers.onNewFolderFailReducer(state, action);
    case ACTION_TYPES.HOME.NEW.FOLDER.CLOSE_DIALOG:
      return Reducers.onNewFolderCloseDialogReducer(state, action);

    case ACTION_TYPES.HOME.NEW.TAG.OPEN_DIALOG:
      return Reducers.onNewTagOpenDialogReducer(state, action);
    case ACTION_TYPES.HOME.NEW.TAG.NAME_CHANGE:
      return Reducers.onNewTagNameChangeReducer(state, action);
    case ACTION_TYPES.HOME.NEW.TAG.SUCCESS:
      return Reducers.onNewTagSuccessReducer(state, action);
    case ACTION_TYPES.HOME.NEW.TAG.FAIL:
      return Reducers.onNewTagFailReducer(state, action);
    case ACTION_TYPES.HOME.NEW.TAG.CLOSE_DIALOG:
      return Reducers.onNewTagCloseDialogReducer(state, action);

    case ACTION_TYPES.HOME.TAB.CHANGE:
      return Reducers.onHomePageTabChangeReducer(state, action);
    
      case ACTION_TYPES.HELP.TAB.CHANGE:
        return Reducers.onHelpPageTabChangeReducer(state, action);

    case ACTION_TYPES.HOME.FOLDER.UNSELECT.ACTION:
      return Reducers.onUnselectFolderReducer(state, action);

    case ACTION_TYPES.HOME.FOLDERS.UNSELECT.ACTION:
      return Reducers.onUnselectFoldersReducer(state, action);

    case ACTION_TYPES.PART.ADD.OPEN_DIALOG:
      return Reducers.onAddPartOpenDialogReducer(state, action);
    case ACTION_TYPES.PART.ADD.FILE_CHANGE:
      return Reducers.onAddPartFileChangeReducer(state, action);
    case ACTION_TYPES.PART.ADD.NAME_CHANGE:
      return Reducers.onAddPartNameChangeReducer(state, action);
    case ACTION_TYPES.PART.ADD.SUCCESS:
      return Reducers.onAddPartSuccessReducer(state, action);
    case ACTION_TYPES.PART.ADD.FAIL:
      return Reducers.onAddPartFailReducer(state, action);
    case ACTION_TYPES.PART.ADD.CLOSE_DIALOG:
      return Reducers.onAddPartCloseDialogReducer(state, action);
    case ACTION_TYPES.PART.ADD.STATUS:
      return Reducers.onAddPartStatus(state, action);
    case ACTION_TYPES.PART.ADD.PROGRESS:
      return Reducers.onAddPartProgress(state, action);

    case ACTION_TYPES.PART.ADD_TO_ADDED_PARTS.ACTION:
      return Reducers.onAddPartToAddedPartsReducer(state, action);
      
    case ACTION_TYPES.PART.PARTS_REPETITION.ACTION:
    case ACTION_TYPES.PARTS.PARTS_REPETITION.ACTION:
      return state;
    
    case ACTION_TYPES.PART.ADD_TO_REVIEW_PAGE.ACTION:
      return Reducers.onAddPartToReviewPageReducer(state, action);

    case ACTION_TYPES.PARTS.ADD_TO_ADDED_PARTS.ACTION:
      return Reducers.onAddPartsToAddedPartsReducer(state, action);

    case ACTION_TYPES.PART.ARCHIVE.INIT:
    case ACTION_TYPES.PART.ARCHIVE.DONE:
    case ACTION_TYPES.PART.ARCHIVE.FAIL:
      return state;
    case ACTION_TYPES.PART.ARCHIVE.SUCCESS:
      return Reducers.onArchivePartReducer(state, action);

    case ACTION_TYPES.PARTS.CLEAR_ADDED_PARTS.ACTION:
      return Reducers.onClearAddedParts(state, action);

    case ACTION_TYPES.PART.COPY.OPEN_DIALOG:
      return Reducers.onCopyPartOpenDialogReducer(state, action);
    case ACTION_TYPES.PART.COPY.NAME_CHANGE:
      return Reducers.onCopyPartNameChangeReducer(state, action);
    case ACTION_TYPES.PART.COPY.INIT:
      return state;
    case ACTION_TYPES.PART.COPY.SUCCESS:
      return Reducers.onCopyPartSuccessReducer(state, action);
    case ACTION_TYPES.PART.COPY.FAIL:
      return Reducers.onCopyPartFailReducer(state, action);
    case ACTION_TYPES.PART.COPY.DONE:
      return state;
    case ACTION_TYPES.PART.COPY.CLOSE_DIALOG:
      return Reducers.onCopyPartCloseDialogReducer(state, action);

    case ACTION_TYPES.PART.DELETE.INIT:
    case ACTION_TYPES.PART.DELETE.DONE:
    case ACTION_TYPES.PART.DELETE.FAIL:
      return state;
    case ACTION_TYPES.PART.DELETE.SUCCESS:
      return Reducers.onDeletePartReducer(state, action);

    case ACTION_TYPES.PARTS.HIDE.SELECTED.ACTION:
      return Reducers.onHideSelectedPartsReducer(state, action);
    case ACTION_TYPES.PARTS.HIDE.ALL.ACTION:
      return Reducers.onHideAllPartsReducer(state, action);
    case ACTION_TYPES.PARTS.HIDE.ARCHIVED.ACTION:
      return Reducers.onHideArchivedPartsReducer(state, action);
    case ACTION_TYPES.PARTS.HIDE.RECENT.ACTION:
      return Reducers.onHideRecentPartsReducer(state, action);

    case ACTION_TYPES.PART.HIGHLIGHT.ACTION:
      return Reducers.onHighlightPartReducer(state, action);

    case ACTION_TYPES.PART.MOVE.OPEN_DIALOG:
      return Reducers.onMovePartOpenDialogReducer(state, action);
    case ACTION_TYPES.PART.MOVE.NAME_CHANGE:
      return Reducers.onMovePartNameChangeReducer(state, action);
    case ACTION_TYPES.PART.MOVE.INIT:
      return state;
    case ACTION_TYPES.PART.MOVE.SUCCESS:
      return Reducers.onMovePartSuccessReducer(state, action);
    case ACTION_TYPES.PART.MOVE.FAIL:
      return Reducers.onMovePartFailReducer(state, action);
    case ACTION_TYPES.PART.MOVE.DONE:
      return state;
    case ACTION_TYPES.PART.MOVE.CLOSE_DIALOG:
      return Reducers.onMovePartCloseDialogReducer(state, action);

    case ACTION_TYPES.PART.REMOVE_FROM_ADDED_PARTS.ACTION:
      return Reducers.onRemovePartToAddedParts(state, action);

    case ACTION_TYPES.PART.RENAME.OPEN_DIALOG:
      return Reducers.onRenamePartOpenDialogReducer(state, action);
    case ACTION_TYPES.PART.RENAME.NAME_CHANGE:
      return Reducers.onRenamePartNameChangeReducer(state, action);
    case ACTION_TYPES.PART.RENAME.INIT:
      return state;
    case ACTION_TYPES.PART.RENAME.SUCCESS:
      return Reducers.onRenamePartSuccessReducer(state, action);
    case ACTION_TYPES.PART.RENAME.FAIL:
      return Reducers.onRenamePartFailReducer(state, action);
    case ACTION_TYPES.PART.RENAME.DONE:
      return state;
    case ACTION_TYPES.PART.RENAME.CLOSE_DIALOG:
      return Reducers.onRenamePartCloseDialogReducer(state, action);

    case ACTION_TYPES.PARTS.SEARCH.ACTION:
      return Reducers.onSearchPartsTextChangeReducer(state, action);

    case ACTION_TYPES.PART.SELECT.ACTION:
      return Reducers.onSelectPartReducer(state, action);

    case ACTION_TYPES.PART.SELECT_ON_ADDED_PARTS.ACTION:
      return Reducers.onSelectPartOnAddedParts(state, action);

    case ACTION_TYPES.PARTS.SELECT_ON_ADDED_PARTS.ACTION:
      return Reducers.onSelectPartsOnAddedParts(state, action);

    case ACTION_TYPES.PARTS.SHOW.SELECTED.ACTION:
      return Reducers.onShowSelectedPartsReducer(state, action);
    case ACTION_TYPES.PARTS.SHOW.ALL.ACTION:
      return Reducers.onShowAllPartsReducer(state, action);
    case ACTION_TYPES.PARTS.SHOW.ARCHIVED.ACTION:
      return Reducers.onShowArchivedPartsReducer(state, action);
    case ACTION_TYPES.PARTS.SHOW.RECENT.ACTION:
      return Reducers.onShowRecentPartsReducer(state, action);

    case ACTION_TYPES.PARTS.SORT.ASC.ACTION:
      return Reducers.onSortPartsAscReducer(state, action);
    case ACTION_TYPES.PARTS.SORT.DESC.ACTION:
      return Reducers.onSortPartsDescReducer(state, action);

    case ACTION_TYPES.PART.UNARCHIVE.INIT:
    case ACTION_TYPES.PART.UNARCHIVE.DONE:
    case ACTION_TYPES.PART.UNARCHIVE.FAIL:
      return state;
    case ACTION_TYPES.PART.UNARCHIVE.SUCCESS:
      return Reducers.onUnarchivePartReducer(state, action);

    case ACTION_TYPES.PART.UNSELECT.ACTION:
      return Reducers.onUnselectPartReducer(state, action);

    case ACTION_TYPES.PARTS.UNSELECT.ACTION:
      return Reducers.onUnselectPartsReducer(state, action);

    case ACTION_TYPES.PART.UNSELECT_ON_ADDED_PARTS.ACTION:
      return Reducers.onUnselectPartOnAddedParts(state, action);

    case ACTION_TYPES.PARTS.UNSELECT_ON_ADDED_PARTS.ACTION:
      return Reducers.onUnselectPartsOnAddedParts(state, action);

    case ACTION_TYPES.PART.UPDATE.INIT:
    case ACTION_TYPES.PART.UPDATE.DONE:
    case ACTION_TYPES.PART.UPDATE.FAIL:
      return state;
    case ACTION_TYPES.PART.UPDATE.SUCCESS:
      return Reducers.onUpdatePartReducer(state, action);

    case ACTION_TYPES.PLATE.ARCHIVE.INIT:
    case ACTION_TYPES.PLATE.ARCHIVE.DONE:
    case ACTION_TYPES.PLATE.ARCHIVE.FAIL:
      return state;
    case ACTION_TYPES.PLATE.ARCHIVE.SUCCESS:
      return Reducers.onArchivePlateReducer(state, action);

    case ACTION_TYPES.PLATE.COPY.OPEN_DIALOG:
      return Reducers.onCopyPlateOpenDialogReducer(state, action);
    case ACTION_TYPES.PLATE.COPY.NAME_CHANGE:
      return Reducers.onCopyPlateNameChangeReducer(state, action);
    case ACTION_TYPES.PLATE.COPY.INIT:
      return state;
    case ACTION_TYPES.PLATE.COPY.SUCCESS:
      return Reducers.onCopyPlateSuccessReducer(state, action);
    case ACTION_TYPES.PLATE.COPY.FAIL:
      return Reducers.onCopyPlateFailReducer(state, action);
    case ACTION_TYPES.PLATE.COPY.DONE:
      return state;
    case ACTION_TYPES.PLATE.COPY.CLOSE_DIALOG:
      return Reducers.onCopyPlateCloseDialogReducer(state, action);

    case ACTION_TYPES.PLATE.DELETE.INIT:
    case ACTION_TYPES.PLATE.DELETE.DONE:
    case ACTION_TYPES.PLATE.DELETE.FAIL:
      return state;
    case ACTION_TYPES.PLATE.DELETE.SUCCESS:
      return Reducers.onDeletePlateReducer(state, action);

    case ACTION_TYPES.PLATES.HIDE.ALL.ACTION:
      return Reducers.onHideAllPlatesReducer(state, action);
    case ACTION_TYPES.PLATES.HIDE.ARCHIVED.ACTION:
      return Reducers.onHideArchivedPlatesReducer(state, action);
    case ACTION_TYPES.PLATES.HIDE.RECENT.ACTION:
      return Reducers.onHideRecentPlatesReducer(state, action);

    case ACTION_TYPES.PLATE.HIGHLIGHT.ACTION:
      return Reducers.onHighlightPlateReducer(state, action);
   
      case ACTION_TYPES.PLATE.HIGHLIGHT_PLATE.ACTION:
      return Reducers.onHighlightPlateObjectReducer(state, action);

    case ACTION_TYPES.PLATE.MOVE.OPEN_DIALOG:
      return Reducers.onMovePlateOpenDialogReducer(state, action);
    case ACTION_TYPES.PLATE.MOVE.NAME_CHANGE:
      return Reducers.onMovePlateNameChangeReducer(state, action);
    case ACTION_TYPES.PLATE.MOVE.INIT:
      return state;
    case ACTION_TYPES.PLATE.MOVE.SUCCESS:
      return Reducers.onMovePlateSuccessReducer(state, action);
    case ACTION_TYPES.PLATE.MOVE.FAIL:
      return Reducers.onMovePlateFailReducer(state, action);
    case ACTION_TYPES.PLATE.MOVE.DONE:
      return state;
    case ACTION_TYPES.PLATE.MOVE.CLOSE_DIALOG:
      return Reducers.onMovePlateCloseDialogReducer(state, action);

    case ACTION_TYPES.PLATE.ADD.OPEN_DIALOG:
      return Reducers.onNewPlateOpenDialogReducer(state, action);
    case ACTION_TYPES.PLATE.ADD.NAME_CHANGE:
      return Reducers.onNewPlateNameChangeReducer(state, action);
    case ACTION_TYPES.PLATE.ADD.SUCCESS:
      return Reducers.onNewPlateSuccessReducer(state, action);
    case ACTION_TYPES.PLATE.ADD.FAIL:
      return Reducers.onNewPlateFailReducer(state, action);
    case ACTION_TYPES.PLATE.ADD.CLOSE_DIALOG:
      return Reducers.onNewPlateCloseDialogReducer(state, action);

    case ACTION_TYPES.PLATE.RENAME.OPEN_DIALOG:
      return Reducers.onRenamePlateOpenDialogReducer(state, action);
    case ACTION_TYPES.PLATE.RENAME.NAME_CHANGE:
      return Reducers.onRenamePlateNameChangeReducer(state, action);
    case ACTION_TYPES.PLATE.RENAME.INIT:
      return state;
    case ACTION_TYPES.PLATE.RENAME.SUCCESS:
      return Reducers.onRenamePlateSuccessReducer(state, action);
    case ACTION_TYPES.PLATE.RENAME.FAIL:
      return Reducers.onRenamePlateFailReducer(state, action);
    case ACTION_TYPES.PLATE.RENAME.DONE:
      return state;
    case ACTION_TYPES.PLATE.RENAME.CLOSE_DIALOG:
      return Reducers.onRenamePlateCloseDialogReducer(state, action);

    case ACTION_TYPES.PLATES.SELECT.PART.CONFIGURATION.ACTION:
      return Reducers.onSelectPartConfigurationReducer(state, action);

    case ACTION_TYPES.PLATES.SELECT.PLATE.CONFIGURATION.ACTION:
      return Reducers.onSelectPlateConfigurationReducer(state, action);

    case ACTION_TYPES.PLATES.SHOW.ALL.ACTION:
      return Reducers.onShowAllPlatesReducer(state, action);
    case ACTION_TYPES.PLATES.SHOW.ARCHIVED.ACTION:
      return Reducers.onShowArchivedPlatesReducer(state, action);
    case ACTION_TYPES.PLATES.SHOW.RECENT.ACTION:
      return Reducers.onShowRecentPlatesReducer(state, action);

    case ACTION_TYPES.PLATES.SORT.ASC.ACTION:
      return Reducers.onSortPlatesAscReducer(state, action);
    case ACTION_TYPES.PLATES.SORT.DESC.ACTION:
      return Reducers.onSortPlatesDescReducer(state, action);

    case ACTION_TYPES.PLATES.TAB.CHANGE:
      return Reducers.onEditPlatePageTabChangeReducer(state, action);

    case ACTION_TYPES.PLATE.UNARCHIVE.INIT:
    case ACTION_TYPES.PLATE.UNARCHIVE.DONE:
    case ACTION_TYPES.PLATE.UNARCHIVE.FAIL:
      return state;
    case ACTION_TYPES.PLATE.UNARCHIVE.SUCCESS:
      return Reducers.onUnarchivePlateReducer(state, action);

    case ACTION_TYPES.PLATE.UPDATE.INIT:
    case ACTION_TYPES.PLATE.UPDATE.DONE:
    case ACTION_TYPES.PLATE.UPDATE.FAIL:
      return state;
    case ACTION_TYPES.PLATE.UPDATE.SUCCESS:
      return Reducers.onUpdatePlateReducer(state, action);

    case ACTION_TYPES.PLATE.SELECT.ACTION:
      return Reducers.onSelectPlateReducer(state, action);
    case ACTION_TYPES.PLATE.UNSELECT.ACTION:
      return Reducers.onUnselectPlateReducer(state, action);

    case ACTION_TYPES.COMMON.FOLDER.VIEW.ACTION:
      return Reducers.onShowFolderViewReducer(state, action);
    case ACTION_TYPES.COMMON.LIST.VIEW.ACTION:
      return Reducers.onShowListViewReducer(state, action);

    case ACTION_TYPES.SUBSCRIPTIONS.SET:
      return Reducers.onSubscriptionsSet(state, action);
    case ACTION_TYPES.SUBSCRIPTIONS.SET_BUILDS:
      return Reducers.onSubscriptionsSetBuilds(state, action);
    case ACTION_TYPES.SUBSCRIPTIONS.SET_PARTS:
      return Reducers.onSubscriptionsSetParts(state, action);
    case ACTION_TYPES.SUBSCRIPTIONS.SET_PRINTERS:
      return Reducers.onSubscriptionsSetPrinters(state, action);

    default:
      return state;
  }
};

export default rootReducer;
