import API, { graphqlOperation } from '@aws-amplify/api';

import { createPlate } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { ICopyPlateModalStatus, IPlate, IState } from '../../../Interfaces';
import { onDisplayLoader, onResetSearch } from '../../Search';
import { onNavigateToHomePage } from '../../Home';

import { NotificationManager } from "react-notifications";
const onCopyPlateInit = () => {
    return {
        type: ACTION_TYPES.PLATE.COPY.INIT,
        payload: null,
    };
};

const onCopyPlateSuccess = (copiedPlate) => {
    return {
        type: ACTION_TYPES.PLATE.COPY.SUCCESS,
        payload: copiedPlate,
    };
};

const onCopyPlateFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Copy Plate');
    console.error('Failure on Copy Plate - ', errorMessage);
    return {
        type: ACTION_TYPES.PLATE.COPY.FAIL,
        payload: errorMessage,
    };
};

const onCopyPlateDone = () => {
    return {
        type: ACTION_TYPES.PLATE.COPY.DONE,
        payload: null,
    };
};

const onCopyPlate = (history) => {
    return async (dispatch, getState) => {

        dispatch(onCopyPlateInit());

        const state = getState() as IState;

        const copyPlateModalStatus = state.data.modals.copyPlateModalStatus as ICopyPlateModalStatus;

        const isPlateNameEmpty = (!copyPlateModalStatus.inputText) as boolean;
        const isPlateNameExists = !state.data.search.isSearchEnabled ? (state.data.plates.loadedPlates.some(p => p.name === copyPlateModalStatus.inputText)
        ) as boolean : (state.data.search.searchPlatesResults.some(p => p.name === copyPlateModalStatus.inputText)
        ) as boolean
      

        if (isPlateNameEmpty) {
            const errorMessage = 'Plate Name is mandatory.' as string;
            dispatch(onCopyPlateFail(errorMessage));
            dispatch(onCopyPlateDone());
            return;
        }
        if (isPlateNameExists) {
            const errorMessage = 'Plate Name already exists.' as string;
            dispatch(onCopyPlateFail(errorMessage));
            dispatch(onCopyPlateDone());
            return;
        }

        try {
            const state = getState() as IState;
            const plateToBeCopied = !state.data.search.isSearchEnabled ? { ...state.data.plates.loadedPlates.find(p => p.id === state.data.plates.highlightedPlateId) } as IPlate
                : state.data.search.highlightedPlateData as IPlate
           // const plateToBeCopied = { ...state.data.plates.loadedPlates.find(p => p.id === state.data.plates.highlightedPlateId) } as IPlate;
            plateToBeCopied.id = copyPlateModalStatus.inputText;
            plateToBeCopied.name = copyPlateModalStatus.inputText;
            plateToBeCopied.plate_search_string = copyPlateModalStatus.inputText;
            delete plateToBeCopied._deleted;
            delete plateToBeCopied.archive;
            delete plateToBeCopied._version;
            delete plateToBeCopied._lastChangedAt;
            delete plateToBeCopied.createdAt;
            delete plateToBeCopied.updatedAt;
            plateToBeCopied.hasBuilds = false;
            plateToBeCopied.builds = [];
            plateToBeCopied.created_by = state.creator as string;
            plateToBeCopied.created_at = new Date().toISOString();
            plateToBeCopied.modified_by = state.creator as string;
            plateToBeCopied.modified_at = new Date().toISOString();
            plateToBeCopied.dumb = 1;
            const variables = {
                input: plateToBeCopied,
            };
            const result = await API.graphql(graphqlOperation(createPlate, variables));
            const copiedPlate = ((result as any)?.data?.createPlate) as IPlate;
            dispatch(onCopyPlateSuccess(copiedPlate));
            dispatch(onCopyPlateDone());
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(false))
                dispatch(onNavigateToHomePage(history))
                dispatch(onResetSearch())
            }
        } catch (graphqlError) {
            const errorMessage = `Copy plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onCopyPlateFail(errorMessage));
            dispatch(onCopyPlateDone());
            dispatch(onDisplayLoader(false))
        }
    };
};

export default onCopyPlate;