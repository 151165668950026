import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onSelectPart, onUnselectPart } from '../../../Actions/Parts';
import { IDisplayPlate, IPlate, IState } from '../../../Interfaces';
import { onNavigateToPartReviewPage } from "../../../Actions/Home";
import DisplayPartOptions from './DisplayPartOptions';
import moment from 'moment';

const Props = (state: IState, ownProps: any) => {
    const props = {
        // displayFolder: ownProps.displayFolder,
        // displayPlate: ownProps.displayPlate as IDisplayPlate,
        // loadedPlates: state.data.plates.loadedPlates as IPlate[],
        // isSelected: ownProps.isSelected,
        // onUnselect: ownProps.onUnselect,
        // onSelect: ownProps.onSelect,
    };

    return props;
};

const Actions = {
    onSelectPart: onSelectPart,
    onUnselectPart: onUnselectPart,
    onNavigateToPartReviewPage: onNavigateToPartReviewPage,
};

class Component extends React.Component<any, any> {
  
    public render() {

        return (
            <>
                <>
                    {
                        this.props.displayPart ?
                            (
                                <div
                                    className={`display-card is-selected-${this.props.displayPart.isSelected} position-relative p-0`}
                                    id={`part-${this.props.displayPart.index}`}
                                    style={{ paddingLeft: '10px' }}
                                    onClick={() =>
                                        !this.props.displayPart.isSelected
                                            ? this.props.onSelectPart(this.props.displayPart.id)
                                            : null
                                    }
                                >
                                    <div className='display-card-select-action pt-2 pl-2 position-absolute'>
                                        <div className='options right-justify'>
                                            <>
                                                {
                                                    this.props.displayPart.isSelected ?
                                                        (
                                                            <>
                                                                <div className='check option'>
                                                                    <img
                                                                        className='icon check'
                                                                        alt=''
                                                                        style={{ margin: '0px', padding: '4px' }}
                                                                        onClick={() =>
                                                                            this.props.onUnselectPart(
                                                                                this.props.displayPart.id
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </>
                                                        )
                                                        :
                                                        (
                                                            <div className='check option'>
                                                                <img className='icon uncheck' style={{ margin: '0px', padding: '4px' }} alt=''></img>
                                                            </div>
                                                        )
                                                }
                                            </>
                                        </div>
                                    </div>
                                    <div className='img-col plate-thumb'>
                                        {/* <img src="/part-thumbnail.svg" alt="thumbnail image" className='thumbnail-img' /> */}
                                        {/* <img src={this.props.displayPart.icon} className='thumbnail-img'/> */}
                                        {
                                            this.props.displayPart.icon ?
                                                <img src={this.props.displayPart.icon} alt="thumbnail image" className='thumbnail-img plate-thumbnail' />
                                                : <img src="/part-thumbnail.svg" alt="thumbnail image" className='thumbnail-img plate-thumb' />
                                        }

                                    </div>
                                    <div className='display-card-row justify-content-center pt-0'>
                                        <div className='display-card-header d-flex'>
                                            <label title={this.props.displayPart.name}>{this.props.displayPart.name} </label>
                                        </div>
                                        <div className="display-card-more-action"
                                            onClick={(e) => e.stopPropagation()}>
                                            <DisplayPartOptions {...{ displayPart: this.props.displayPart }} />
                                        </div>

                                    </div>
                                    <div className='display-card-row'>
                                        <div className='display-card-sub-header'>
                                            <>
                                                <div className='details'>
                                                    <label>Imported Date:</label>
                                                    <span className="data-format" title={(this.props.displayPart?.created_at || this.props.displayPart?.createdDate ? moment(new Date(this.props.displayPart?.created_at || this.props.displayPart?.createdDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available')}>{(this.props.displayPart?.created_at || this.props.displayPart?.createdDate ? moment(new Date(this.props.displayPart?.created_at || this.props.displayPart?.createdDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available')} </span>
                                                </div>
                                                <div className='details'>
                                                    <label>Created By:</label>
                                                    <span title={this.props.displayPart.creator}>{this.props.displayPart.creator}</span>
                                                </div>
                                            </>
                                        </div>
                                    </div>
                                </div>

                            ) :
                            (
                                null
                            )
                    }
                </>
                <>
                    {/* {
                        this.props.displayPalte ?
                            ( */}
                    {/* <div
                                className={`display-card is-selected-${this.props.displayPlate.isSelected}`}
                                id={`plate-${this.props.displayPlate.index}`}
                                key={`${this.props.displayPlate.index}`}
                                onClick={() => {}}
                            >
                                <div className="display-card-row">
                                    <div className="display-card-header">
                                        <label className="text-ellipsis-second-line">
                                            {this.props.displayPlate.name}
                                        </label>
                                    </div>
                                   
                                </div>
                                <div className="display-card-row">
                                    <div className="display-card-sub-header">
                                        <label>{this.props.displayPlate.createdDate}</label>
                                    </div>
                                </div>
                            </div> */}
                    {/* ) :
                            (
                                null
                            )
                    } */}
                </>
            </>
        );
    }
}

const DisplayDashboardPartCardView = withRouter(connect(Props, Actions)(Component));

export default DisplayDashboardPartCardView;
