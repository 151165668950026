import { IFolder, IState } from '../../../Interfaces';

const onRenameFolderSuccessReducer = (state: IState, action) => {
    const renamedFolder = action.payload as IFolder;
    const indexToBeReplaced = state.data.folders.loadedFolders.findIndex(lp => lp.id === renamedFolder.id);
    const isFolderExists = (indexToBeReplaced !== -1);

    const newState = { ...state } as IState;
    if (isFolderExists) newState.data.folders.loadedFolders.splice(indexToBeReplaced, 1, renamedFolder);

    newState.data.modals.renameFolderModalStatus = {
        ...newState.data.modals.renameFolderModalStatus,
        inputText: '',
        isOpen: false,
        isValid: false,
        hasError: false,
        errorMessage: '',
    };
    
    return newState;
};

export default onRenameFolderSuccessReducer;