import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { IState, INewBuildModalStatus } from "../Interfaces";
import {
	onNewBuildCloseDialog,
	onNewBuildNameChange,
	onNewBuild,
} from "../Actions/Builds";
import { API, graphqlOperation } from "aws-amplify";
import { buildSearchByCreatedAt, listBuildSearches } from "../../graphql/queries";

const Props = (state: IState, ownProps: any) => {
	const newBuildModalStatus = state.data.modals
		.newBuildModalStatus as INewBuildModalStatus;

	const props = {
		plateName: state.data.plates.highlightedPlateId ? state.data.plates.highlightedPlateId : state.data.search.highlightedPlateData.id  as string,
		isModalOpen: newBuildModalStatus.isOpen,
		inputText: newBuildModalStatus.inputText,
		hasError: newBuildModalStatus.hasError,
		errorMessage: newBuildModalStatus.errorMessage,
		isValid: newBuildModalStatus.isValid,
	};
	return props;
};

const Actions = {
	onNewBuildCloseDialog: onNewBuildCloseDialog,
	onNewBuildNameChange: onNewBuildNameChange,
	onNewBuild: onNewBuild,
};

class Component extends React.Component<any, any> {
	constructor(props) {
		super(props);
		this.state = {
			plateName: this.props.plateName,
		};
	}
	async componentDidMount() {
		// const result = (await API.graphql(
		// 	graphqlOperation(listBuildSearches)
		// )) as any;
		// const buildSearchResults = (result as any)?.data?.listBuildSearches?.items;
		// this.setState({ buildSearchResults: buildSearchResults });
		this.setState({ plateName: this.props.plateName + '__Build' });	
	}
	
	async componentDidUpdate(prevProps) {
		if (this.props.plateName !== prevProps.plateName) {
			this.setState({ plateName: this.props.plateName+ '__Build' });	
		}
	}


	private onNewBuildNameChange(event) {
		const value = event.target.value.split(/[^a-zA-Z0-9._-]/).join("")	
			this.setState({ plateName: value, isAvailable: true });
			this.props.onNewBuildNameChange(this.state.plateName);
	}

	async onBeginSlicing (){
		
		await this.props.onNewBuildNameChange(this.state.plateName)
		console.log("🚀 ~ file: NewBuild.tsx:52 ~ Component ~ onBeginSlicing ~ this.state.plateName:", this.state.plateName)
		await this.props.onNewBuild(this.props.history)
	}
	public render() {
		return (
			<>
				{this.props.isModalOpen ? (
					<>
						<Modal
							show={this.props.isModalOpen}
							onHide={() => this.props.onNewBuildCloseDialog()}
							backdrop="static"
							keyboard={false}
							dialogClassName="partsDialog"
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header>
								<Modal.Title>This plate will be sliced and turned into a build</Modal.Title>
								<span className="close-btn"
									onClick={() => this.props.onNewBuildCloseDialog()}>
								</span>
							</Modal.Header>
							<Modal.Body>
								<div className="each-dialog-content">
									<div className="each-d-section">
									Name Your New Build
									</div>
									<div className='each-d-section'>
										<input className='f-w'
											type='text'
											value={this.state.plateName}
											onChange={(event) => this.onNewBuildNameChange(event)}
										/>
									</div>
									<div className="hint-msg">
										Name can contain only a-z, A-Z, 0-9, ._-
									</div>
									<div
										className="invalid-feedback d-block"
										hidden={!this.props.hasError}
									>
										{this.props.errorMessage}
									</div>
								</div>
							</Modal.Body>
							<Modal.Footer className="justify-content-center">
								<Button variant="secondary" onClick={() => this.props.onNewBuildCloseDialog()}>
									Cancel
								</Button>
								<Button
									className="btn-primary"
									onClick={() => { this.onBeginSlicing() }}
									autoFocus
								>
									Continue
								</Button>
							</Modal.Footer>
						</Modal>
					</>
				) : null}
			</>
		);
	}
}

const NewBuild = withRouter(connect(Props, Actions)(Component));

export default NewBuild;
