import { IState } from "../../../Interfaces";

const onUnselectPlateReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.plates.selectedPlateIds = [];

    return newState;
};

export default onUnselectPlateReducer;