import React, { useState, Children, useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayPartCardView, DisplayPartListView, AddPart, CopyPart, RenamePart, MovePart, DisplayParts, DisplayPlates, DisplayBuilds, DisplayFolder, DisplayPlateListView, DisplayBuildListView, NewPlate, RenamePlate, CopyPlate, MovePlate, NewBuild, MoveBuild, } from '../..';
import { onHideAllBuilds, onHideRecentBuilds, onShowAllBuilds, onShowArchivedBuilds, onShowRecentBuilds } from '../../../Actions/Builds';
import { onUnselectFolder, onLoadParts, onLoadPlates, onLoadBuilds } from '../../../Actions/Home';
import { onShowRecentParts, onHideRecentParts, onShowAllParts, onHideAllParts, onShowArchivedParts, onHideArchivedParts, onHideSelectedParts, onShowSelectedParts, onSelectPart, onUnselectPart, onClearAddedParts } from '../../../Actions/Parts'; import { SortDirection } from '../../../Enums';
import { onSearchPartsTextChange, onSortPartsAsc, onSortPartsDesc } from '../../../Actions/Parts';
import { onSearchPlatesTextChange, onSortPlatesAsc, onSortPlatesDesc } from '../../../Actions/Plates';
import { onSearchBuildsTextChange, onSortBuildsAsc, onSortBuildsDesc } from '../../../Actions/Builds';
import { IDisplayBuild, IDisplayFolder, IDisplayPart, IDisplayPlate, IState } from '../../../Interfaces';
import { getDisplayBuilds, getDisplayFolders, getDisplayParts, getDisplayPlates } from '../../../Mappers';
import DisplayDashboardParts from '../Parts/DisplayDashboardParts';
import LoadingComponent from "../../../../LoadingComponent/LoadingComponent";
import DisplayDashboardPartCardView from "../Parts/DisplayDashboardPartCardView";
import DisplayDashboardPlateCardView from "../Plates/DisplayDashboardPlateCardView";
import DisplayDashboardBuildCardView from '../Builds/DisplayDashboardBuildCardView';
import DisplayDashboardFolder from './DisplayDashboardFolder';
import NewBuildSuccess from '../../NewBuildSuccess';

const Props = (state: IState, ownProps: any) => {
    const displayFolders = getDisplayFolders(state) as IDisplayFolder[];

    const totalFolders = (
        (state.data.folders.sortDirection === SortDirection.ASC) ?
            displayFolders.reverse() :
            displayFolders
    ) as IDisplayFolder[];

    const filteredFolders = (totalFolders.filter(df => df.isFiltered)) as IDisplayFolder[];

    const selectedFolders = (filteredFolders.filter(ff => ff.isSelected)) as IDisplayFolder[];

    const recentFolders = (filteredFolders.filter(ff => !ff.isSelected && ff.isRecent)) as IDisplayFolder[];

    const allFolders = (filteredFolders.filter(ff => !ff.isSelected && !ff.isArchived)) as IDisplayFolder[];

    const archivedFolders = (filteredFolders.filter(ff => !ff.isSelected && ff.isArchived)) as IDisplayFolder[];

    const hasSelectedFolders = Boolean(selectedFolders.length > 0) as boolean;

    const displayParts = getDisplayParts(state) as IDisplayPart[];

    const totalParts = (
        (state.data.builds.sortDirection === SortDirection.ASC) ?
            displayParts.reverse() :
            displayParts
    ) as IDisplayPart[];

    const filteredParts = (
        totalParts.filter(dp => dp.isFiltered)
    ) as IDisplayPart[];

    const selectedParts = (
        state.data.parts.selectedPartIds.map(id => filteredParts.find(lp => lp.id === id))
    ) as IDisplayPart[];

    const recentParts = (
        filteredParts.filter(fp => !fp.isArchived && fp.isRecent)
    ) as IDisplayPart[];

    const allParts = (
        filteredParts.filter(fp => !fp.isArchived)
    ) as IDisplayPart[];

    const archivedParts = (
        filteredParts.filter(fp => fp.isArchived)
    ) as IDisplayPart[];

    const displayPlates = getDisplayPlates(state) as IDisplayPlate[];
    const totalPlates = (
        (state.data.builds.sortDirection === SortDirection.ASC) ?
            displayPlates.reverse() :
            displayPlates
    ) as IDisplayPlate[];

    const filteredPlates = (
        totalPlates.filter(dp => dp.isFiltered)
    ) as IDisplayPlate[];
    const allPlates = (
        filteredPlates.filter(fp => !fp.isSelected && !fp.isArchived)
    ) as IDisplayPlate[];
    const displayBuilds = getDisplayBuilds(state) as IDisplayBuild[];

    const totalBuilds = (
        (state.data.builds.sortDirection === SortDirection.ASC) ?
            displayBuilds.reverse() :
            displayBuilds
    ) as IDisplayBuild[];

    const filteredBuilds = (
        totalBuilds.filter(db => db.isFiltered)
    ) as IDisplayBuild[];

    const selectedBuilds = (
        filteredBuilds.filter(fb => fb.isSelected)
    ) as IDisplayBuild[];

    const recentBuilds = (
        filteredBuilds.filter(fb => !fb.isSelected && !fb.isArchived && fb.isRecent)
    ) as IDisplayBuild[];

    const allBuilds = (
        filteredBuilds.filter(fb => !fb.isSelected && !fb.isArchived)
    ) as IDisplayBuild[];

    const archivedBuilds = (
        filteredBuilds.filter(fb => !fb.isSelected && fb.isArchived)
    ) as IDisplayBuild[];

    const hasSelectedBuilds = Boolean(selectedBuilds.length > 0) as boolean;

    const showSelectedBuilds = Boolean(state.data.sections.recentBuilds.showItems) as boolean;
    const showRecentBuilds = Boolean(state.data.sections.recentBuilds.showItems) as boolean;
    const showAllBuilds = Boolean(state.data.sections.allBuilds.showItems) as boolean;
    const showArchivedBuilds = Boolean(state.data.sections.archivedBuilds.showItems) as boolean;

    const hasSelectedParts = Boolean(selectedParts.length > 0) as boolean;

    const showSelectedParts = Boolean(state.data.sections.selectedParts.showItems) as boolean;
    const showRecentParts = Boolean(state.data.sections.recentParts.showItems) as boolean;
    const showAllParts = Boolean(state.data.sections.allParts.showItems) as boolean;
    const showArchivedParts = Boolean(state.data.sections.archivedParts.showItems) as boolean;
    const showAllPlates = Boolean(state.data.sections.allPlates.showItems) as boolean;

    const props = {
        showListView: state.data.info.showListView as boolean,
        filteredFolders: filteredFolders as IDisplayFolder[],
        selectedFolders: selectedFolders as IDisplayFolder[],
        recentFolders: recentFolders as IDisplayFolder[],
        allFolders: allFolders as IDisplayFolder[],
        archivedFolders: archivedFolders as IDisplayFolder[],
        hasSelectedFolders: hasSelectedFolders as Boolean,
        filteredParts: filteredParts as IDisplayPart[],
        selectedParts: selectedParts as IDisplayPart[],
        recentParts: recentParts as IDisplayPart[],
        allParts: allParts as IDisplayPart[],
        archivedParts: archivedParts as IDisplayPart[],
        hasSelectedParts: hasSelectedParts as Boolean,
        showSelectedParts: showSelectedParts as Boolean,
        showRecentParts: showRecentParts as Boolean,
        showAllParts: showAllParts as Boolean,
        showArchivedParts: showArchivedParts as Boolean,
        displayParts: ownProps.displayParts,
        filteredPlates: filteredPlates as IDisplayPlate[],
        allPlates: allPlates as IDisplayPlate[],

        showAllPlates: showAllPlates as Boolean,
        filteredBuilds: filteredBuilds as IDisplayBuild[],
        selectedBuilds: selectedBuilds as IDisplayBuild[],
        recentBuilds: recentBuilds as IDisplayBuild[],
        allBuilds: allBuilds as IDisplayBuild[],
        archivedBuilds: archivedBuilds as IDisplayBuild[],
        hasSelectedBuilds: hasSelectedBuilds as Boolean,
        showSelectedBuilds: showSelectedBuilds as Boolean,
        showRecentBuilds: showRecentBuilds as Boolean,
        showAllBuilds: showAllBuilds as Boolean,
        showArchivedBuilds: showArchivedBuilds as Boolean,
        loadedParts: state.data.parts.loadedParts,
        loadedPlates: state.data.plates.loadedPlates,
        loadedBuilds: state.data.builds.loadedBuilds,
				isLoadingComplete: (state.data.parts.isLoadingComplete && 
														state.data.builds.isLoadingComplete && 
														state.data.plates.isLoadingComplete) as boolean,
        searchText: state.data.parts.searchText  as string,
        searchTextMatches: (state.data.parts.searchText === state.data.builds.searchText && 
													  state.data.builds.searchText === state.data.plates.searchText) as boolean,

        buildsSortDirection: state.data.builds.sortDirection,
        partsSortDirection: state.data.parts.sortDirection,
        platesSortDirection: state.data.plates.sortDirection,
        nextToken: (state.data.parts.nextToken || state.data.plates.nextToken || state.data.builds.nextToken),
        displayFolders: ownProps.displayFolders,
    };

    return props;
};

const Actions = {
    onUnselectFolder: onUnselectFolder,
    onShowSelectedParts: onShowSelectedParts,
    onHideSelectedParts: onHideSelectedParts,
    onShowRecentParts: onShowRecentParts,
    onHideRecentParts: onHideRecentParts,
    onShowAllParts: onShowAllParts,
    onHideAllParts: onHideAllParts,
    onShowArchivedParts: onShowArchivedParts,
    onHideArchivedParts: onHideArchivedParts,
    onSelectPart: onSelectPart,
    onUnselectPart: onUnselectPart,
    onShowRecentBuilds: onShowRecentBuilds,
    onHideRecentBuilds: onHideRecentBuilds,
    onShowAllBuilds: onShowAllBuilds,
    onHideAllBuilds: onHideAllBuilds,
    onShowArchivedBuilds: onShowArchivedBuilds,
    onLoadParts: onLoadParts,
    onLoadPlates: onLoadPlates,
    onLoadBuilds: onLoadBuilds,
    onSearchPartsTextChange, 
    onSortPartsAsc: onSortPartsAsc, 
    onSortPartsDesc: onSortPartsDesc,
    onSearchPlatesTextChange: onSearchPlatesTextChange, 
    onSortPlatesAsc: onSortPlatesAsc, 
    onSortPlatesDesc: onSortPlatesDesc,
    onSearchBuildsTextChange: onSearchBuildsTextChange, 
    onSortBuildsAsc: onSortBuildsAsc, 
    onSortBuildsDesc: onSortBuildsDesc,
    onClearAddedParts: onClearAddedParts,
};


const DashboardMethod = (props) => {
    const [searchedText, setSearchedText] = useState(undefined)

    const loadMore = async () => {
        props.onLoadParts();
        props.onLoadPlates();
        props.onLoadBuilds();
    };

    useEffect(() => {
        setSearchedText(props.searchText)
        if (!props.searchTextMatches) {
            props.onSearchPartsTextChange('');
            props.onSearchPlatesTextChange('');
            props.onSearchBuildsTextChange('');
            props.onLoadParts(true);
            props.onLoadPlates(true);
            props.onLoadBuilds(true);
        }
    }, [props.searchText])

    useEffect(() => {
        props.onClearAddedParts();
        props.onUnselectPart();
    }, [])

    useEffect(() => {
      if (props.sorted) {
        if (props.partsSortDirection !== SortDirection.ASC) {
          console.log("Sorting parts");
          props.onSortPartsAsc();
          props.onLoadParts(true);
        }
        if (props.platesSortDirection !== SortDirection.ASC) {
          console.log("Sorting plates");
          props.onSortPlatesAsc();
          props.onLoadPlates(true);
        }
        if (props.buildsSortDirection !== SortDirection.ASC) {
          console.log("Sorting builds");
          props.onSortBuildsAsc();
          props.onLoadBuilds(true);
        }
      }
      else {
        if (props.partsSortDirection !== SortDirection.DESC) {
          console.log("Sorting parts");
          props.onSortPartsDesc();
          props.onLoadParts(true);
        }
        if (props.platesSortDirection !== SortDirection.DESC) {
          console.log("Sorting plates");
          props.onSortPlatesDesc();
          props.onLoadPlates(true);
        }
        if (props.buildsSortDirection !== SortDirection.DESC) {
          console.log("Sorting builds");
          props.onSortBuildsDesc();
          props.onLoadBuilds(true);
        }
      }
    }, [props.sorted])

    
    const partCardView = () => {
        return (
            <>
                <>
                    <DisplayDashboardParts
                        {...{
                            label: 'Selected Parts',
                            displayFolders: [],
                            displayParts: props.selectedParts,
                            showOptions: true,
                            showItems: true,
                            onShowItems: props.onShowSelectedParts,
                            onHideItems: props.onHideSelectedParts,
                            isSelected: true,
                            onUnselect: props.onUnselectPart,
                            onSelect: props.onSelectPart,
                        }}
                    />
                </>
            </>
        )
    };
    
    const { loadedParts, loadedPlates, loadedBuilds, filter } = props;
		const lcfilter = filter?.toLowerCase();
    //  parts
    let idArr1 = loadedParts.map(val => val.id).filter(i => !filter || i.toLowerCase().includes(lcfilter));
    idArr1 = [...new Set(idArr1)];
    const newLoadedParts = idArr1.map(val => {
        return loadedParts.filter(value => value.id == val)[0]
    })
    //  plates
    let idArr2 = loadedPlates.map(val => val.id).filter(i => !filter || i.toLowerCase().includes(lcfilter));
    idArr2 = [...new Set(idArr2)];
    const newloadedPlates = idArr2.map(val => {
        return loadedPlates.filter(value => value.id == val)[0]
    })
    //  builds
    let idArr3 = loadedBuilds.map(val => val.id).filter(i => !filter || i.toLowerCase().includes(lcfilter));
    idArr3 = [...new Set(idArr3)];
    const newloadedBuilds = idArr3.map(val => {
        return loadedBuilds.filter(value => value.id == val)[0]
    })

    const updatedParts = newLoadedParts.map(item => ({ ...item, itemname: 'part' }))
    const updatedPlates = newloadedPlates.map(item => ({ ...item, itemname: 'plate' }))
    const updatedBuilds = newloadedBuilds.map(item => ({ ...item, itemname: 'build' }))
    const totalItems = [...updatedParts, ...updatedPlates, ...updatedBuilds]

    totalItems.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
    if (props.sorted) {
        totalItems.sort().reverse()
    }

    const selectedFolderView = (selectedFolder) => {
		return (
			<>
				<dl className={`${props.showListView ? "grid-view" : ""}`}>
					<dd>
						<div className="options w-100 mt-3">
							<div className="back option">
								<div className="backIcon" onClick={() => props.onUnselectFolder(selectedFolder["id"])}></div>
								<label>{selectedFolder["name"]}</label>
							</div>
						</div>
						{
							props.showListView ?
								(
									<>
										<div className="display-list">
											<div className="display-list-row">
												<div className="display-list-header">
													<label>Type</label>
												</div>
												<div className="display-list-header">
													<label>Name</label>
												</div>
												<div className="display-list-header">
													<label>Build Date</label>
												</div>
												<div className="display-list-header">
													<label>Owner</label>
												</div>
												<div className="display-list-header">
													<label>Status</label>
												</div>
												<div className="display-list-header">
													<label>Folder</label>
												</div>
												<div className="display-list-header">
													<label>Actions</label>
												</div>
											</div>
										</div>
									</>
								)
								:
								(
									null
								)
						}
						{
							selectedFolder["displayPlates"]
								.filter(db => db.folders.some(f => f === selectedFolder["name"]))
								.map((db, i) => props.showListView ? <DisplayPlateListView key={i} displayPlate={db} /> : <DisplayDashboardPlateCardView key={i} displayPlate={db} />)
						}
                        {
							selectedFolder["displayParts"]
								.filter(db => db.folders.some(f => f === selectedFolder["name"]))
								.map((db, i) => props.showListView ? <DisplayPartListView key={i} displayPart={db} /> : <DisplayDashboardPartCardView key={i} displayPart={db} />)
						}
                        {
							selectedFolder["displayBuilds"]
								.filter(db => db.folders.some(f => f === selectedFolder["name"]))
								.map((db, i) => props.showListView ? <DisplayBuildListView key={i} displayBuild={db} /> : <DisplayDashboardBuildCardView key={i} displayBuild={db} />)
						}
					</dd>
				</dl>
			</>
		)
	}
    const buildCardView = () => {
		return (
			<>
				{
					props.hasSelectedFolders ?
						props.selectedFolders.map(selectedFolder => selectedFolderView(selectedFolder)) :
						dashboardView()
				}
			</>
		)
	}
    const dashboardView = () => {
        return (
            <>
                <dl className={`${props.showListView ? "grid-view" : ""}`}>
                    <dd>
                        {
                            props.allFolders
                                .map((df, i) => {
                                    return <DisplayDashboardFolder
                                        key={i}
                                        displayFolder={df}
                                        showParts={true}
                                        showPlates={true}
                                        showBuilds={true}
                                    />
                                })
                        }
                    </dd>
                    <dd>
                        {
                            <>
                                {
                                    props.showListView ?
                                        (
                                            <>
                                                <div className='display-list'>
                                                    <div className='display-list-row'>
                                                        <div className='display-list-header'>
                                                            <label>Type</label>
                                                        </div>
                                                        <div className='display-list-header'>
                                                            <label>Name</label>
                                                        </div>
                                                        <div className='display-list-header'>
                                                            <label>Imported Date</label>
                                                        </div>
                                                        <div className='display-list-header'>
                                                            <label>Owner</label>
                                                        </div>
                                                        <div className='display-list-header'>
                                                            <label>Status</label>
                                                        </div>
                                                        <div className='display-list-header'>
                                                            <label>Folder</label>
                                                        </div>
                                                        <div className='display-list-header'>
                                                            <label>Actions</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                        :
                                        (
                                            null
                                        )
                                }
                                {totalItems?.map((item, i) => {
                                    if (item.itemname === 'part') {
                                        return (props.showListView ? <DisplayPartListView key={i} displayPart={item} /> :
                                            <DisplayDashboardPartCardView
                                                {...{
                                                    key: i,
                                                    displayPart: item,
                                                    isSelected: false,
                                                    onUnselect: props.onUnselectPart,
                                                    onSelect: props.onSelectPart,
                                                }}
                                            // key={i} displayPart={item} 
                                            />)
                                    }
                                    if (item.itemname === 'plate') {
                                        return (props.showListView ? <DisplayPlateListView key={i} displayPlate={item} /> : <DisplayDashboardPlateCardView key={i} displayPlate={item} />)
                                    }
                                    if (item.itemname === 'build') {
                                        return (props.showListView ? <DisplayBuildListView key={i} displayBuild={item} /> : <DisplayDashboardBuildCardView key={i} displayBuild={item} />)
                                    }
                                }
                                )}
                            </>
                        }
                    </dd>
                </dl>
            </>
        )
    }

    if (totalItems.length === 0 && props.isLoadingComplete) {
        return (
            <div className="d-block w-100 text-center search-message">
                <img src="/no-result-found.svg" alt="no-search-result" />
                <p className="justify-content-center p-4">No result found in the name of <b>{props.searchText}</b></p>
            </div>
        )
    }

    return (
        <>


            <LoadingComponent visible={!props.isLoadingComplete} />
            {
                partCardView()
            }
            {
					props.showListView ?
						dashboardView() :
						buildCardView()
				}
            {/* {
                dashboardView()
            } */}
            {
                props.nextToken  && !props.hasSelectedFolders && props.isLoadingComplete && (
                    <div className="d-block w-100 text-center pb-4">
                        <button onClick={() => loadMore()} className="btn btn-primary justify-content-center">
                            Load More<span className="btn-right-arrow"></span></button>
                    </div>
                ) 
                // : (
                //     <div className="d-block w-100 text-center">
				// 			<p className="justify-content-center p-4">
				// 				No data to load</p>
				// 		</div>
                // )
            }
            <AddPart />
            <NewPlate />
            <RenamePlate />
            <CopyPlate />
            <MovePlate />
            <RenamePart />
            <CopyPart />
            <MovePart />
            <NewBuild />
            <MoveBuild />
            <NewBuildSuccess />
        </>
    );
}

const Dashboard = withRouter(connect(Props, Actions)(DashboardMethod));

export default Dashboard;
