import { API, graphqlOperation } from "aws-amplify";
import { createBuild } from "../../../../graphql/mutations";
import ACTION_TYPES from "../../../actionTypes";
import { getNewBuild } from "../../../Instances";
import { IBuild, INewBuildModalStatus, IPlate, IState } from "../../../Interfaces";
import { onNavigateToHomePage, onNavigateToPlatesPage } from "../../Home";
import onUpdatePartAfterDelete from "./updatePart";
import onUpdatePlateAfterDelete from "./updatePlate";
import { error } from "console";
import { NotificationManager } from "react-notifications";
import { getBuild, getBuildSearch, getPlate } from "../../../../graphql/queries";
import IPartInfo from "../../../../interfaces/IPartInfo";
import { onAddPartsToAddedParts, onClearAddedParts } from "../../Parts";


const onNewBuildSuccess = (addedBuild) => {
  return {
    type: ACTION_TYPES.BUILD.ADD.SUCCESS,
    payload: addedBuild,
  };
};
const onNewBuildCloseDialog = () => {
  return {
    type: ACTION_TYPES.BUILD.ADD.CLOSE_DIALOG,
    payload: null,
  };
};

const onNewBuildSuccessOpenDialog = () => {
  return {
    type: ACTION_TYPES.BUILD.SUCCESS.OPEN_DIALOG,
    payload: null,
  };
};

const onNewBuildFail = (errorMessage: string, graphqlErrorMessage: string) => {
  errorMessage = 'Failure on creating new build. '+ errorMessage;
  console.error(errorMessage);
  if (graphqlErrorMessage)
    console.error(graphqlErrorMessage)
  return {
    type: ACTION_TYPES.BUILD.ADD.FAIL,
    payload: errorMessage,
  };
};

const onNewBuild = (history) => {
  return async (dispatch, getState) => {
    const state = getState() as IState;

    const newBuildModalStatus = state.data.modals
      .newBuildModalStatus as INewBuildModalStatus;

    const isBuildNameEmpty = !newBuildModalStatus.inputText as boolean;
    const hasParts = (state.data.workspace.state.build.parts.length >
      0) as boolean;
    const hasMachineConfiguration =
      state.data.workspace.state.build.machineConfig;
    const hasMaterialConfiguration =
      state.data.workspace.state.build.materialConfig;
    const hasRecipeConfiguration = state.data.workspace.state.build.recipe;
    const hasMillConfiguration = state.data.workspace.state.build.millConfig;  

    if (isBuildNameEmpty) {
      const errorMessage = "Build Name is mandatory." as string;
      dispatch(onNewBuildFail(errorMessage, ''));
      return;
    }


    if (!hasParts) {
      const errorMessage = "Parts not selected." as string;
      dispatch(onNewBuildFail(errorMessage, ''));
      return;
    }

    if (!hasMachineConfiguration) {
      const errorMessage = "Machine Configuration not selected." as string;
      dispatch(onNewBuildFail(errorMessage, ''));
      return;
    }

    if (!hasMaterialConfiguration) {
      const errorMessage = "Material Configuration not selected." as string;
      dispatch(onNewBuildFail(errorMessage, ''));
      return;
    }

    if (!hasRecipeConfiguration) {
      const errorMessage = "Recipe Configuration not selected." as string;
      dispatch(onNewBuildFail(errorMessage, ''));
      return;
    }

    if (!hasMillConfiguration) {
      const errorMessage = "Mill Configuration not selected." as string;
      dispatch(onNewBuildFail(errorMessage, ''));
      return;
    }






    try {
      const buildSearch = await API.graphql(
        graphqlOperation(getBuild, {
          id: newBuildModalStatus.inputText,
        })
      )
      const result = (buildSearch as any)?.data?.getBuild
      if (result) {
        const errorMessage = "Build Name already exists." as string;
        dispatch(onNewBuildFail(errorMessage, ''));
        return;
      }
      else {
        try {
          const state = getState() as IState;

          const newBuild = getNewBuild(state);
          const variables = {
            input: newBuild,
          };
          const result = await API.graphql(
            graphqlOperation(createBuild, variables)
          );
          const addedBuild = (result as any)?.data?.createBuild as IBuild;
          console.log("🚀 ~ file: newBuild.tsx:123 ~ return ~ addedBuild:", JSON.stringify(addedBuild,null,4))

         // dispatch(onNavigateToHomePage(history));
        //  dispatch(onNewBuildSuccess(addedBuild));

          const partIdsToBeUpdated = addedBuild.parts.map(
            (part) => JSON.parse(part).properties.PartID
          );
          const partsToBeUpdated = state.data.parts.loadedParts.filter((lp) =>
            partIdsToBeUpdated.some((id) => id === lp.id)
          );

          partsToBeUpdated.forEach((part) =>
            dispatch(onUpdatePartAfterDelete(part, addedBuild))
          );

          const plateToBeUpdated = state.data.plates.loadedPlates.find(
            (lp) => lp.id === state.data.plates.highlightedPlateId ? state.data.plates.highlightedPlateId : state.data.search.highlightedPlateData.id
          );
          dispatch(onUpdatePlateAfterDelete(plateToBeUpdated, addedBuild));

          await dispatch(onClearAddedParts());
          
          const plateToBeEdited = state.data.plates.loadedPlates.find(
            (lp) => lp.id === state.data.plates.highlightedPlateId ? state.data.plates.highlightedPlateId  : state.data.search.highlightedPlateData.id
            ) as IPlate;

            const partIds = plateToBeEdited.parts
              .filter(Boolean)
              .map((stringifiedPart) => JSON.parse(stringifiedPart))
              .filter(Boolean)
              .map((part) => part.properties.PartID)
              .filter(Boolean) as string[];
            const parts = plateToBeEdited.parts
              .filter(Boolean)
              .map((stringifiedPart) => JSON.parse(stringifiedPart))
              .filter(Boolean) as IPartInfo[];
            const partConfigurationIds = plateToBeEdited.parts
              .filter(Boolean)
              .map((stringifiedPart) => JSON.parse(stringifiedPart))
              .filter(Boolean)
              .map((part) => part.properties.PartConfig["originalJson"]["id"])
              .filter(Boolean);
            const partSlicerConfigurationIds = plateToBeEdited.parts
              .filter(Boolean)
              .map((stringifiedPart) => JSON.parse(stringifiedPart))
              .filter(Boolean)
              .map((part) => part.properties.SlicerConfig["originalJson"]["id"])
              .filter(Boolean);
            const plateConfigurationIds = [
              plateToBeEdited.machineId,
              plateToBeEdited.millConfigId,
              plateToBeEdited.materialConfigId,
              plateToBeEdited.recipeId,
            ];

            const ids = {
              partIds,
              parts,
              partConfigurationIds,
              partSlicerConfigurationIds,
              plateConfigurationIds,
            };

          const currentWorkspaces = state?.data?.workspace?.addedParts || []
          console.log(currentWorkspaces, ids, "currentWorkspaces")
          if (state.data.workspace.isNewPlate) {
            await dispatch(onClearAddedParts());
          }
          
          await dispatch(onAddPartsToAddedParts(ids));
          await dispatch(onNewBuildCloseDialog())
          await dispatch(onNewBuildSuccessOpenDialog())
          




        } catch (graphqlError) {
          const graphqlErrorMessage = `Create build failed: ${Object(
            (graphqlError as any)?.errors?.[0]?.message
          ).toString()}`;
          dispatch(onNewBuildFail('', graphqlErrorMessage));
        }
      }
    } catch (graphqlError) {
      const errorMessage = 'Unable to search if the entered build name already exists or not.'
      const graphqlErrorMessage = ` Unable to search if the entered build name already exists or not due to ${Object(
        (graphqlError as any)?.errors?.[0]?.message
      ).toString()}`;
      dispatch(onNewBuildFail(errorMessage, graphqlErrorMessage));
    }
  };
};

export default onNewBuild;
