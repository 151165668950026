import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { TabIndex } from '../Enums';
import { onHomePageTabChange } from '../Actions/Home';
import { IConfiguration, IDisplayPlate, IPlate, IState } from '../Interfaces';
import { Button } from 'react-bootstrap';
import { onNavigateToPlatesPage, onNavigateToReviewPage } from '../Actions/Home';
import { onAddPartOpenDialog } from '../Actions/Parts';
import { getDefaultPrintConfiguration, getDefaultMillConfiguration, getDefaultMaterialConfiguration, getDefaultRecipeConfiguration, getDefaultPartConfiguration, getDefaultPartSlicerConfiguration } from '../DefaultConfigurations';
import { onNewTagOpenDialog, onNewFolderOpenDialog } from '../Actions/Home';
import { setPlateConfigurations } from '../Instances';
import { onHighlightPlate } from '../Actions/Plates';
import ImportPart from "./ImportParts"
import IPartInfo from '../../interfaces/IPartInfo';
import { onNewPlateOpenDialog } from "../Actions/Plates";
import onUpdatedNewPlate from '../Actions/Plates/New/updatedNewPlate';

const Props = (state: IState, ownProps: any) => {
    const workspace = state.data.workspace as any;
    const isSearchEnabled = state.data.search.isSearchEnabled as boolean;
    const defaultPrintConfiguration = getDefaultPrintConfiguration(state) as IConfiguration;
    const defaultMillConfiguration = getDefaultMillConfiguration(state) as IConfiguration;
    const defaultMaterialConfiguration = getDefaultMaterialConfiguration(state) as IConfiguration;
    const defaultRecipeConfiguration = getDefaultRecipeConfiguration(state) as IConfiguration;

    if (ownProps?.addedPart) {
        const addedPartPartConfiguration = state.data.workspace.addedPartsSelectedPartConfigurations[ownProps.addedPart] as IConfiguration;
        const addedPartPartSlicerConfiguration = state.data.workspace.addedPartsSelectedPartSlicerConfigurations[ownProps.addedPart] as IConfiguration;

        const props = {
            isLoadingComplete: state.data.folders.isLoadingComplete as boolean,
            selectedTabIndex: state.data.info.homePageTabIndex as number,
            selectedPartIds: state.data.parts.selectedPartIds as string[],
            selectedConfigurations: state.data.workspace.selectedConfigurations || [] as IConfiguration[],
            ids: {
                partIds: [ownProps.addedPart].filter(Boolean).map(part => part.id).filter(Boolean) as string[],
                partConfigurationIds: [addedPartPartConfiguration?.['id']] as string[],
                partSlicerConfigurationIds: [addedPartPartSlicerConfiguration?.['id']] as string[],
                plateConfigurationIds: [defaultPrintConfiguration?.['id'], defaultMillConfiguration?.['id'], defaultMaterialConfiguration?.['id'], defaultRecipeConfiguration?.['id']].filter(Boolean),
            },
            loadedPlates: state.data.plates.loadedPlates as IPlate[],
            displayPlate: ownProps.displayPlate as IDisplayPlate,
            state: state as any,
            workspace: state.data.workspace,
            selectedIds: state.data.workspace?.selectedConfigurations?.filter(item => item?.id).map(id => (id?.id || id?.name)),
            selectedPlate: state.data.plates.loadedPlates?.filter(el => el.id === state.data.plates.highlightedPlateId)
        };
        return props;
    } else {
        const props = {
            isLoadingComplete: state.data.folders.isLoadingComplete as boolean,
            selectedTabIndex: state.data.info.homePageTabIndex as number,
            selectedPartIds: state.data.parts.selectedPartIds as string[],
            ids: {
                partIds: [...state.data.parts.selectedPartIds].filter(Boolean) as string[],
                partConfigurationIds: [...state.data.parts.selectedPartIds].filter(Boolean).map(id => getDefaultPartConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
                partSlicerConfigurationIds: [...state.data.parts.selectedPartIds].filter(Boolean).map(id => getDefaultPartSlicerConfiguration(state)).filter(Boolean).map(configuration => configuration.id).filter(Boolean) as string[],
                plateConfigurationIds: [defaultPrintConfiguration?.['id'], defaultMillConfiguration?.['id'], defaultMaterialConfiguration?.['id'], defaultRecipeConfiguration?.['id']].filter(Boolean),
            },
            loadedPlates: state.data.plates.loadedPlates as IPlate[],
            displayPlate: ownProps.displayPlate as IDisplayPlate,
            state: state as any,
            workspace: state.data.workspace,
            selectedIds: state.data.workspace?.selectedConfigurations?.filter(item => item?.id).map(id => (id?.id || id?.name)),
            selectedPlate: state.data.plates.loadedPlates?.filter(el => el.id === state.data.plates.highlightedPlateId),
            selectedSearchPlate: state.data.search.highlightedPlateData,
        };
        return props;
    }

    
};

const Actions = {
    onHomePageTabChange: onHomePageTabChange,
    onAddPartOpenDialog: onAddPartOpenDialog,
    onNavigateToPlatesPage: onNavigateToPlatesPage,
    onNewTagOpenDialog: onNewTagOpenDialog,
    onNewFolderOpenDialog: onNewFolderOpenDialog,
    onNavigateToReviewPage: onNavigateToReviewPage,
    onHighlightPlate: onHighlightPlate,
    onNewPlateOpenDialog: onNewPlateOpenDialog,
    onUpdatedNewPlate: onUpdatedNewPlate
};

class Component extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            showImportPartswModal: false
        }
    }


    updateAndRedirect = async () => {
       if (!this.props.isSearchEnabled) {
        await setPlateConfigurations(this.props.state, this.props.selectedIds, false);
            this.props.onNavigateToPlatesPage(this.props.ids, this.props.history, false)
        }
        if (this.props.isSearchEnabled) {
            this.props.onHighlightPlate(this.props.selectedSearchPlate.id);

            const plateToBeEdited = this.props.selectedSearchPlate;

            const partIds = plateToBeEdited.parts
                .filter(Boolean)
                .map(stringifiedPart => JSON.parse(stringifiedPart))
                .filter(Boolean)
                .map(part => part.properties.PartID)
                .filter(Boolean) as string[];

            const parts = plateToBeEdited.parts
                .filter(Boolean)
                .map(stringifiedPart => JSON.parse(stringifiedPart))
                .filter(Boolean) as IPartInfo[];

            const partConfigurationIds = parts
                .map(part => part.properties.PartConfig?.originalJson?.id)
                .filter(c => c);

            const partSlicerConfigurationIds = parts
                .map(part => part.properties.SlicerConfig?.originalJson?.id)
                .filter(c => c);

            const plateConfigurationIds = [
                plateToBeEdited.machineId,
                plateToBeEdited.millConfigId,
                plateToBeEdited.materialConfigId,
                plateToBeEdited.recipeId
            ];

            const ids = {
                partIds,
                parts,
                partConfigurationIds,
                partSlicerConfigurationIds,
                plateConfigurationIds
            };

            this.props.onNavigateToPlatesPage(ids, this.props.history, false);
        }
        }

    componentDidMount() {
        this.props.onHighlightPlate('');
        console.log(this.props.selectedSearchPlate, "selectedSearchPlate")
    }
    handleModalClose = () => this.setState({ showImportPartswModal: false });
    handleModalShow = () => this.setState({ showImportPartswModal: true });
    createNewPlate =() =>{
        this.props.onUpdatedNewPlate(this.props.ids, this.props.history, true);
        this.props.onNewPlateOpenDialog()
    }

    public render() {
        return (
            <>
                <div className='tab-bar'>
                    <div className='options left-justify'>
                        <div
                            className={`tab ${(this.props.selectedTabIndex === TabIndex.ALL) ? 'active' : ''}`}
                            onClick={() => { this.props.onHomePageTabChange(TabIndex.ALL); }}>
                            <label>All</label>
                        </div>
                        <div
                            className={`tab ${(this.props.selectedTabIndex === TabIndex.PARTS) ? 'active' : ''}`}
                            onClick={() => { this.props.onHomePageTabChange(TabIndex.PARTS); this.props.onNavigateToReviewPage(this.props.ids, this.props.history, this.props.selectedTabIndex, true) }}>
                            <label>Parts</label>
                        </div>
                        <div
                            className={`tab ${(this.props.selectedTabIndex === TabIndex.PLATES) ? 'active' : ''}`}
                            onClick={() => { this.props.onHomePageTabChange(TabIndex.PLATES); this.props.onNavigateToReviewPage(this.props.ids, this.props.history, true) }}>
                            <label>Plates</label>
                        </div>
                        <div
                            className={`tab ${(this.props.selectedTabIndex === TabIndex.BUILDS) ? 'active' : ''}`}
                            onClick={() => { this.props.onHomePageTabChange(TabIndex.BUILDS); }}>
                            <label>Builds</label>
                        </div>
                    </div>
                    <div className='flex-space'></div>
                    <div className='options right-justify'>
                        <>
                            {
                                (this.props.selectedTabIndex === TabIndex.ALL) ?
                                    (
                                        <>
                                            <div className='create-folder'>
                                                <Button
                                                    variant="secondary"
                                                    disabled={this.props.isLoadingInProgress}
                                                    onClick={() => this.props.onNewFolderOpenDialog()}
                                                >
                                                    <img src="/folder-icon.png" className='pr-1' alt="Create Folder Icon" /> Create Folder
                                                </Button>
                                            </div>
                                            <div className='import'>
                                                {/* <Button
                                                    variant="secondary"
                                                    className='btn-space'
                                                    disabled={this.props.isLoadingInProgress}
                                                    onClick={() => this.props.onAddPartOpenDialog()}
                                                ><img src="/import-icon.svg" className='pr-3' alt="Import Parts Icon" />Import Parts
                                                </Button> */}
                                                <Button
                                                    variant="secondary"
                                                    className='btn-space'
                                                    disabled={this.props.isLoadingInProgress}
                                                    onClick={this.handleModalShow}>
                                                    <img src="/import-icon.svg" className='pr-3' alt="Import Parts Icon" />Import Parts
                                                </Button>
                                            </div>

                                            <div className='create-new-plate'>
                                                <Button className={this.props.selectedPartIds && this.props.selectedPartIds.length > 0 ? "active" : "btn-inactive"}
                                                    onClick={() =>  this.createNewPlate() }>
                                                    <span className='pr-2'> + </span> Create New Plate
                                                </Button>
                                            </div>
                                            {console.log(this.props.ids)}
                                            {/* this.props.onNavigateToPlatesPage(this.props.ids, this.props.history, true) */}
                                        </>
                                    ) :
                                    (null)
                            }
                        </>
                        <>
                            {
                                (this.props.selectedTabIndex === TabIndex.PARTS) ?
                                    (
                                        <>
                                             <div className='import'>
                                                <Button
                                                    variant="secondary"
                                                    className='btn-space'
                                                    disabled={this.props.isLoadingInProgress}
                                                    onClick={this.handleModalShow}>
                                                    <img src="/import-icon.svg" className='pr-3' alt="Import Parts Icon" />Import Parts
                                                </Button>
                                            </div>

                                            <div className='create-new-plate pl-4'>
                                                <Button className={this.props.selectedPartIds && this.props.selectedPartIds.length > 0 ? "active" : "btn-inactive"}
                                                    // className="btn-space ml-0"
                                                    onClick={() => this.createNewPlate()}>
                                                    Start New Plate <span className='pr-2'> + </span>
                                                </Button>
                                            </div>
                                        </>
                                    ) :
                                    (null)
                            }
                        </>
                        <>
                            {
                                (this.props.selectedTabIndex === TabIndex.PLATES) ?
                                    (
                                        <>
                                            <div className='import'>
                                                <Button
                                                    // className={(this.props.selectedPlate && this.props.selectedPlate.length !== 0) || (this.props.selectedSearchPlate && this.props.selectedSearchPlate.length !== 0) ? "active" : "btn-inactive"}
                                                    variant="secondary"
                                                    disabled={this.props.isLoadingInProgress}
                                                    onClick={() => { this.updateAndRedirect(); this.props.onHomePageTabChange(TabIndex.PARTS) }}
                                                >Edit Build Plate<img src="/edit-build-icon.svg" className='pl-2' alt="Edit Build Plate Icon" />
                                                </Button>
                                            </div>

                                            <div className='create-new-plate d-none'>
                                                <Button className="ml-4"
                                                    onClick={() => { }}>
                                                    Configure Printer Settings <span className='btn-right-arrow pl-2'></span>
                                                </Button>
                                            </div>
                                        </>
                                    ) :
                                    (null)
                            }
                        </>
                        <>
                            {
                                (this.props.selectedTabIndex === TabIndex.BUILDS) ?
                                    (
                                        <>
                                            <div className='create-folder'>
                                                <Button
                                                    variant="secondary"
                                                    disabled={this.props.isLoadingInProgress}
                                                    onClick={() => this.props.onNewFolderOpenDialog()}
                                                >
                                                    <img src="/folder-icon.png" className='pr-1' alt="Create Folder Icon" /> Create Folder
                                                </Button>
                                            </div>
                                        </>
                                    ) :
                                    (null)
                            }
                        </>
                    </div>
                </div>
                <ImportPart
                    showModal={this.state.showImportPartswModal}
                    hideModal={this.handleModalClose}>
                </ImportPart>
            </>

        );
    }
}

const TabBar = withRouter(connect(Props, Actions)(Component));

export default TabBar;