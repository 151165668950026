import API, { graphqlOperation } from '@aws-amplify/api';
import { SortDirection } from "../../../Enums";
import { union } from 'lodash';

import { plateByCreatedAt } from '../../../../graphql/queries';
import * as subscriptions from '../../../../graphql/subscriptions';
import subscriptionsSet from "../../Subscriptions/subscriptionsSet";

import ACTION_TYPES from '../../../actionTypes';
import { IPlate } from '../../../Interfaces';

const getAvailablePlates = (plates: IPlate[] = []) => {
    return plates.filter(b => Boolean(b && !b._deleted));
};

const onLoadPlatesStarted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.STARTED,
        payload: null,
    };
};

const onLoadPlatesInProgress = (newlyLoadedPlates, nextToken) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.IN_PROGRESS,
        payload: {plates: newlyLoadedPlates, nextToken: nextToken},
    };
};

const onLoadPlatesCompleted = () => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.COMPLETED,
        payload: null,
    };
};

const onLoadPlatesUpdated = (newlyLoadedPlates) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.UPDATED,
        payload: newlyLoadedPlates,
    };
};

const onLoadPlatesCreated = (newlyLoadedPlates) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.CREATED,
        payload: newlyLoadedPlates,
    };
};

const onLoadPlatesDeleted = (deletedPlates) => {
    return {
        type: ACTION_TYPES.HOME.LOAD.PLATES.DELETED,
        payload: deletedPlates,
    };
};

//const loadPlates = async (dispatch, getState, response: { loadedPlates: IPlate[], nextToken: any } = { loadedPlates: [] as IPlate[], nextToken: undefined }) => {
const loadPlates = async (dispatch, getState, newRequest) => { 
    try {
        const state = getState();
        var   loadedPlates = newRequest ? [] : state.data?.plates?.loadedPlates || [];
        const nextToken = newRequest ? undefined : state.data?.plates?.nextToken;
        const sortDirection = state.data?.plates?.sortDirection === SortDirection.ASC ? 'ASC' : 'DESC';
        const searchText = state.data?.plates?.searchText;

        if (!newRequest && ! nextToken) {
          return;
        }

        const variables = {
            limit: 50,
            dumb: 1,
            sortDirection: sortDirection,
            nextToken: nextToken,
        };

        var useFilter = (searchText && searchText !== "");
        if (useFilter) {
            (variables as any).filter = {
                plate_search_string: { contains: searchText },
            };
        }

        dispatch(onLoadPlatesStarted());
        do {
					const result = await API.graphql(graphqlOperation(plateByCreatedAt, variables)) as any;

					const newlyLoadedPlates = getAvailablePlates((result as any)?.data?.plateByCreatedAt?.items || [] as IPlate[]);
					const newNextToken = (result as any)?.data?.plateByCreatedAt?.nextToken || undefined;
          if (!newNextToken || (newlyLoadedPlates.length && !useFilter)) {
						dispatch(onLoadPlatesInProgress(union(loadedPlates, newlyLoadedPlates), newNextToken));
            break;
					}
          else {
            loadedPlates = union(loadedPlates, newlyLoadedPlates);
						dispatch(onLoadPlatesInProgress(loadedPlates, undefined));
            variables.nextToken = newNextToken;
          }
        } while(true);

        dispatch(onLoadPlatesCompleted());
        return Promise.resolve();
    } catch (graphqlError) {
        return Promise.resolve();
    }
};

const onLoadPlates = (newRequest = false) => {
    return async (dispatch, getState) => {
        const state = getState();
        await loadPlates(dispatch, getState, newRequest);
        let newSubscriptions = {};

        if (!state.subscriptions?.plateCreate) {
          console.log("Adding PLATE CREATE subscription");
          (newSubscriptions as any).plateCreate = 
            (API.graphql(graphqlOperation(subscriptions.onCreatePlate)) as any).subscribe({
              next: ({ provider, value }) => {
                  console.log({ provider, value })
                  dispatch(onLoadPlatesCreated(value.data.onCreatePlate))
              },
              error: error => console.error(error)
          });
        }
        if (!state.subscriptions?.plateDelete) {
          console.log("Adding PLATE DELETE subscription");
          (newSubscriptions as any).plateDelete = 
            (API.graphql(graphqlOperation(subscriptions.onDeletePlate)) as any).subscribe({
              next: ({ provider, value }) => {
                  console.log({ provider, value })
                  dispatch(onLoadPlatesDeleted(value.data.onDeletePlate))
              },
              error: error => console.error(error)
          });
        }
        if (!state.subscriptions?.plateUpdate) {
          console.log("Adding PLATE UPDATE subscription");
          (newSubscriptions as any).plateUpdate = 
						(API.graphql(graphqlOperation(subscriptions.onUpdatePlate)
          ) as any).subscribe({
              next: ({ provider, value }) => {
                  console.log({ provider, value })
                  dispatch(onLoadPlatesUpdated(value.data.onUpdatePlate))
              },
              error: error => console.warn(error)
          });
        }
				dispatch(subscriptionsSet(newSubscriptions));
    };
};

export default onLoadPlates;
