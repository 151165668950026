import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import IPartInfo from "../../../../interfaces/IPartInfo";
import { onNavigateToPlatesPage } from "../../../Actions/Home";
import { IDisplayPlate, IPlate, IState } from "../../../Interfaces";
import { onHighlightPlate } from '../../../Actions/Plates';
import { onHomePageTabChange } from '../../../Actions/Home';
import DisplayPlateOptions from "./DisplayPlateOptions";
import { TabIndex } from '../../../Enums';
import moment from "moment";

const Props = (state: IState, ownProps: any) => {
	const props = {
		loadedPlates: state.data.plates.loadedPlates as IPlate[],
		displayPlate: ownProps.displayPlate as IDisplayPlate,
	};

	return props;
};

const Actions = {
	onHighlightPlate: onHighlightPlate,
	onNavigateToPlatesPage: onNavigateToPlatesPage,
	onHomePageTabChange: onHomePageTabChange,
};

class Component extends React.Component<any, any> {
	parts = (Boolean(this.props.displayPlate.parts)) as unknown as string[];
	onNavigateToPlatesPage = (plateId) => {

		this.props.onHighlightPlate(plateId);

		const plateToBeEdited = this.props.loadedPlates.find(lp => lp.id === plateId) as IPlate;

		const partIds = plateToBeEdited.parts?.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part?.properties.PartID).filter(Boolean) as string[];
		const parts = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean) as IPartInfo[];
		const partConfigurationIds = parts.map(part => part.properties.PartConfig?.originalJson?.id).filter(c => c);
		const partSlicerConfigurationIds = parts.map(part => part.properties.SlicerConfig?.originalJson?.id).filter(c => c);
		const plateConfigurationIds = [plateToBeEdited.machineId, plateToBeEdited.millConfigId, plateToBeEdited.materialConfigId, plateToBeEdited.recipeId];

		const ids = {
			partIds,
			parts,
			partConfigurationIds,
			partSlicerConfigurationIds,
			plateConfigurationIds,
		};

		this.props.onNavigateToPlatesPage(ids, this.props.history, false);
	}

	public render() {
		return (
			<>
				<div
					className={`display-card is-selected-${this.props.displayPlate.isSelected} p-0`}
					id={`plate-${this.props.displayPlate.index}`}
					key={`${this.props.displayPlate.index}`}
					onClick={(e) => { e.stopPropagation(); this.props.onHomePageTabChange(TabIndex.PARTS); this.onNavigateToPlatesPage(this.props.displayPlate.id) }}
				// onClick={(e) => { e.stopPropagation(); this.onNavigateToPlatesPage(this.props.displayPlate.id)} }
				>
					<div className='img-col plate-thumb'>
						{/* <img src="/plate-thumbnail.png" alt="thumbnail image" className='thumbnail-img' /> */}
						{
							this.props.displayPlate.icon ?
								<img src={this.props.displayPlate.icon} alt="thumbnail image" className='thumbnail-img' />
								: <img src="/plate-thumbnail.svg" alt="thumbnail image" className='thumbnail-img' />
						}
					</div>
					<div className='display-card-row align-items-center pt-0'>
						<div className='display-card-header'>
							<label title={this.props.displayPlate.name}>{this.props.displayPlate.name} </label>
						</div>
						<div className="display-card-more-action"
							onClick={(e) => e.stopPropagation()}>
							<DisplayPlateOptions
								{...{ displayPlate: this.props.displayPlate }}
							/>
						</div>
					</div>
					<div className='display-card-row'>
						<div className='display-card-sub-header'>
							<>
								<div className='details'>
									<label>Created Date:</label>
									<span className="data-format" title={this.props.displayPlate.created_at || this.props.displayPlate?.createdDate ? moment(new Date(this.props.displayPlate.created_at || this.props.displayPlate?.createdDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available'}>{this.props.displayPlate.created_at || this.props.displayPlate?.createdDate ? moment(new Date(this.props.displayPlate.created_at || this.props.displayPlate?.createdDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available'}</span>
								</div>
								<div className='details'>
									<label>Parts Plated</label>
									<span>{this.props.displayPlate.parts.length}</span>
								</div>

								{/* <div className='details'>
									<label>File Size:</label>
									<span>34MB</span>
								</div>
								<div className='details'>
									<label>Dimensions:</label>
									<span>50mm x 50mm x 16mm</span>
								</div> */}
							</>
						</div>
					</div>
					{/* <div className="display-card-row">
						<div className="display-card-header">
							<label className="text-ellipsis-second-line">
								{this.props.displayPlate.name}
							</label>
						</div>
						<div className="display-card-more-action"
							onClick={(e) => e.stopPropagation()}>
							<DisplayPlateOptions
								{...{ displayPlate: this.props.displayPlate }}
							/>
						</div>
					</div>
					<div className="display-card-row">
						<div className="display-card-sub-header">
							<label>{this.props.displayPlate.createdDate}</label> 
							<label>{this.props.displayPlate.createdDate ? this.props.displayPlate.createdDate : this.createdDate}</label>
						</div>
					</div> */}
				</div>
			</>
		);
	}
}

const DisplayPlateCardView = withRouter(connect(Props, Actions)(Component));

export default DisplayPlateCardView;
