import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onSelectPart, onUnselectPart } from '../../../Actions/Parts';
import { IState } from '../../../Interfaces';
import DisplayPartOptions from './DisplayPartOptions';
import { onNavigateToPartReviewPage } from "../../../Actions/Home";

const Props = (state: IState, ownProps: any) => {
	const props = {};

	return props;
};

const Actions = {
	onSelectPart: onSelectPart,
	onUnselectPart: onUnselectPart,
	onNavigateToPartReviewPage: onNavigateToPartReviewPage,
};

class Component extends React.Component<any, any> {
	createdDate = (new Date(this.props.displayPart.created_at) as Date).toLocaleString() as string;
	plates= (Boolean(this.props.displayPart.plates)) as unknown as string[];

	public render() {
		return (
			<>			
					<div
						className={`display-card is-selected-${this.props.displayPart.isSelected}`}
						id={`part-${this.props.displayPart.index}`}
						style={{paddingLeft:'10px'}}
						onClick={() =>
							!this.props.displayPart.isSelected
								? this.props.onSelectPart(this.props.displayPart.id)
								: null
						}
					>
						<div style={{display:'flex', alignItems:'center'}} className='display-card-row'>
							
							<div className='display-card-select-action'>
								<div className='options right-justify'>
									<>
										{
											this.props.displayPart.isSelected ?
												(
													<>
														<div className='check option'>
															<img
																className='icon check'
																alt=''
																style={{margin:'0px', padding:'4px'}}
																onClick={() =>
																	this.props.onUnselectPart(
																		this.props.displayPart.id
																	)
																}
															/>
														</div>
													</>
												)
												:
												(
													<div className='check option'>
														<img className='icon uncheck' style={{margin:'0px', padding:'4px'}} alt=''></img>
													</div>
												)
										}
									</>
								</div>
							</div>
							<div className='display-card-header' style={{paddingLeft:'0px'}}>
								<label>{this.props.displayPart.name}</label>
							</div>
							<div className='display-card-more-action-part d-flex justify-content-between'>
								
									{/* <div className='label-part'>{this.props.displayPart.plates.length} Plate(s)</div> */}
								<DisplayPartOptions {...{ displayPart: this.props.displayPart }} />
							</div>

						</div>
						<div className='display-card-row' style={{flexDirection:'column',paddingLeft:'40px',fontSize:'13px'}}>
							<label style={{marginBottom:'0px'}}>{this.props.displayPart.createdDate ?? this.createdDate}</label>
							<label style={{marginBottom:'0px'}}>{this.props.displayPart.creator}</label>
						</div>																		
						<div className='display-card-row'>
							<div className='display-card-sub-header'>
								<>
									{
										this.props.displayPart.isMovedToFolder ?
											(
												<>
													{
														this.props.displayPart.folders.map(folder => <label>{folder}</label>)
													}
												</>
											)
											:
											(
												<label></label>
											)
									}
									{
										<div>
											<div className='preview-img' style={{marginLeft:'0px'}}>
											{
												this.props.displayPart.icon ?
													<img className='display-card-part-icon' alt="" src={this.props.displayPart.icon} />
													: <img src="/part-thumbnail.svg" alt="thumbnail image" className='thumbnail-img' />
											}
											
											</div>
											
										</div>
									}								
								</>
							</div>

						</div>
					</div>
			</>
		);
	}
}

const DisplayPartCardView = withRouter(connect(Props, Actions)(Component));

export default DisplayPartCardView;
