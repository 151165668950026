import { IPlate, IState } from "../Interfaces";

const getNewPlate = (state: IState) => {

    const build = state.data.workspace.state.build;

    const hasParts = Boolean(build.parts.length > 0);
    const parts = build.parts.map(part => {
        delete part['mesh'];
        return JSON.stringify(part);
    });
    
    const newPlate = ({
        id: state.data.modals.newPlateModalStatus.inputText !=="" ? state.data.modals.newPlateModalStatus.inputText : state.data.plates.highlightedPlateId,
        name: state.data.modals.newPlateModalStatus.inputText !=="" ? state.data.modals.newPlateModalStatus.inputText : state.data.plates.highlightedPlateId,
        hasParts: hasParts,
        parts: parts,
        machineConfig: build.machineConfig ? JSON.stringify(build.machineConfig) : null,
        machineId: build.machineConfig ? build.machineConfig['originalJson']['id'] : null,
        millConfig: build.millConfig ? JSON.stringify(build.millConfig) : null,
        millConfigId: build.millConfig ? build.millConfig['originalJson']['id'] : null,
        materialConfig: build.materialConfig ? JSON.stringify(build.materialConfig) : null,
        materialConfigId: build.materialConfig ? build.materialConfig['originalJson']['id'] : null,
        recipe: build.recipe ? JSON.stringify(build.recipe) : null,
        recipeId: build.recipe ? build.recipe['originalJson']['id'] : null,
        created_by: state.creator,
        created_at: new Date().toISOString(),
        modified_by: state.creator,
        modified_at: new Date().toISOString(),
        dumb: 1,
        plate_search_string: (state.data.modals.newPlateModalStatus.inputText !== "" && state.data.modals.newPlateModalStatus.inputText !== null) ? state.data.modals.newPlateModalStatus.inputText?.toLowerCase() : state.data.plates.highlightedPlateId?.toLowerCase()
    } as IPlate);

    return newPlate;
};

export default getNewPlate;