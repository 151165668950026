import React, { useEffect, useState, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { version } from "../../package.json";
import * as build from "../build.json";
import "./MVPPrintDashboard.scss";
import { MohoVersion } from "../Distributions/MohoVersion";
import API, { graphqlOperation } from "@aws-amplify/api";
import {
  listBuilds,
  buildByCreatedAt,
  buildByInitiated,
  getPrint,
  getPrinter,
} from "../graphql/queries";
import Amplify, { Auth, Storage } from "aws-amplify";
import { createBuild, updatePrint } from "../graphql/mutations";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import { useHistory } from "react-router-dom";
import saveFile from "../utils";
import JSZip from "jszip";
import { createPrinter, updatePrinter } from "../graphql/mutations";
import { listPrinters } from "../graphql/queries";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import LoadingComponent from "../LoadingComponent/LoadingComponent";
import { subscribe } from "graphql";
import MVPToolsDetailsModal from "./MVPToolsDetailsModal";
import { NotificationManager } from "react-notifications";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
interface FetchToolsInfo {
  id: string;
  name: string;
  GrafanaBundle: string
}

const MVPPrintDashboard: React.FunctionComponent = (props) => {
  const [recentElements, setRecentElements] = useState<any[]>([]);
  const [pauseStatus, setPauseStatus] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [currentName, setCurrentName] = useState<string>("");
  const [grafanaName, setGrafanaName] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [printDataList, setPrintDataList] = useState<any>({});
  const [openP, setOpenP] = useState<boolean>(false);
  const [selectedPrinter, setSelectedPrinter] = useState<any[]>([]);
  const [addPrinterError, setAddPrinterError] = useState<boolean>(false);
  const [addGrafanaError, setAddGrafanaError] = useState<boolean>(false);
  const [updateSubscription, setUpdateSubscription] = useState<any>(null);
  const [selectedPrinterName, setSelectedPrinterName] = useState<string>("");
  const [displayToolsDetails, setDisplayToolsDetails] =
    useState<boolean>(false);
  const [selectedPrinterTools, setSelectedPrinterTools] = useState<any[]>([]);
  const [primaryInfoToFetchToolsDetails, setPrimaryInfoToFetchToolsDetails] = useState<FetchToolsInfo[]>([]);;
  const [selectedPrinterId, setSelectedPrinterId] = useState<string>("");
  const [pausePrintModal, setPausePrintModal] = useState<boolean>(false);
  const [printerList, setPrinterList] = useState<any[]>([]);
  const [selectedGrafana, setSelectedGrafana] = useState<string>("");

  const handleModalClose = () => setDisplayToolsDetails(false);
  const handleModalShow = () => setDisplayToolsDetails(true);

  const displayToolsModal = (printer) => {
    setDisplayToolsDetails(true);
    setSelectedPrinterTools(printer.GrafanaBundle)
   
    const primaryInfo: FetchToolsInfo = {
      id: printer.id,
      name: printer.name,
      GrafanaBundle: printer.GrafanaBundle
    };
    setPrimaryInfoToFetchToolsDetails([primaryInfo]);
    setSelectedPrinterId(printer.name);
  };

  const printerAction = async (GrafanaBundleId, status, printerId) => {
    const response = await API.get(
      "lavaPrinterControllerApi",
      `/${GrafanaBundleId}/${status}`,
      {
        headers: {},
        response: true,
      }
    );
    setPausePrintModal(true);
  };

  const history = useHistory();
  let zip = new JSZip();

  const routeChange = (newPath: string) => {
    history.push(newPath);
  };
  const goTolist = (buildItem) => {
    if (printDataList[buildItem.id]) {
      setSelectedPrinterName(buildItem.name);
      setSelectedPrinter(printDataList[buildItem.id]);
      setOpenP(true);
    }
  };
  const handleDialogCloseP = () => {
    setOpenP(false);
    setPausePrintModal(false);
  };

  const goToBulidReview = (buildItem) => {
    // let selectedPartt = printDataList[buildItem.id][0]

    history.push({
      pathname: "/dashboard/printReview",
      state: {
        selectedBuild: buildItem,
        selectedPart: printDataList[buildItem.id],
      },
    });
  };

  const get_current_list = async () => {
    let selfPointer = this;
    setLoading(true);
    let requestResult = await API.graphql(
      graphqlOperation(listPrinters, { limit: 100 })
    );
    console.log("MVPBuildReview ~~~requestResult ~~~~", requestResult);
    let array = (requestResult as any).data.listPrinters.items;
    // let allBuildPrints = {}
    array.forEach(async (e) => {
      let allBuildPrints = printDataList;
      let file = await getFiles(e.GrafanaBundle);
      allBuildPrints[e.id] = file;
      setPrintDataList(allBuildPrints);
      setRecentElements(array);
    });
    setRecentElements(array);
    getPrinterStats(array[1]);
    setUpdateSubscription(
      (
        API.graphql(
          graphqlOperation(`
              subscription OnUpdatePrinter {
                  onUpdatePrinter {
                        id
                        currentPrint
                        currentLayer
                        currentStage
                        lastSyncTime
                        currentBundle
                        lastVisionPath
                        lastThermalPath
                        }
              }`)
        ) as any
      ).subscribe({
        next: ({ provider, value }) => {
          console.log({ provider, value });
          value = value.data.onUpdatePrinter;
          let newPrinters = Object.assign({});
          array.forEach((printer) => {
            if (
              printer.id == value.id &&
              (value.currentLayer || value.currentStage || value.lastStatusSync)
            ) {
              printer.currentLayer = value.currentLayer || printer.currentLayer;
              printer.currentStage = value.currentStage || printer.currentStage;
              printer.lastStatusSync =
                value.lastStatusSync || printer.lastStatusSync;
            }
          });
        },
        error: (error) => console.warn(error),
      })
    );
    setLoading(false);
  };
  const getFiles = async (printerId) => {
    setLoading(true);
    let files = await API.get(
      "lavaRestApi",
      `/queue/printers/${printerId}`,
      {}
    );
    // setFiles(files)
    // let allBuildPrints = printDataList;
    // allBuildPrints[printerId] = files;
    // setPrintDataList(allBuildPrints);
    setLoading(false);
    return files;
  };
  const getPrinterStats = async (pint) => {
    //  event.stopPropagation()
    const currentDate = new Date();
    const startT = new Date(
      new Date(currentDate.setDate(currentDate.getDate() - 7)).toDateString()
    );
    const stopT = new Date(new Date().toDateString());
    if (pint) {
      const response = await API.get("lavaRestApi", "/printers/stats", {
        // OPTIONAL
        headers: {}, // OPTIONAL
        response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: {
          bucket: pint.name,
          start: startT.toISOString(),
          stop: stopT.toISOString(),
        },
      });
      let status = response.data;
      console.log("getPrinterStats ~~~status ~~~~", status);
      if (status.error) {
        console.error(`Printer stats error: ${status.error}`);
      }
      // else{
      //     setStats(status)
      // }
    }
  };

  const optionValueChange = (e, caseType) => {
    get_current_list();
    //  console.log('MVPPrintDashboard~optionValueChange~~', e.target.value);
  };

  const handleDialogClose = () => {
    setCurrentName("");
    setSelectedGrafana("");
    setGrafanaName("");
    setAddPrinterError(false);
    setAddGrafanaError(false);
    setOpen(false);
  };
  const getPrinters = async () => {
    setLoading(true);
    let files = await API.get("lavaRestApi", `/all/printers`, {});
    if (files) {
      setPrinterList(files);
    }
    console.log("getPrinters", files);
    setLoading(false);
    return files;
  };

  const addPrinter = () => {
    setOpen(true);
    getPrinters();
  };
  const aaddPrint = async (ev) => {
    setSelectedGrafana("")
    ev.stopPropagation();
    let dt = new Date() + "";
    let iid = Date.parse(dt);
    const data = {
      id: currentName,
      name: currentName,
      GrafanaBundle: grafanaName,
    };

    const printerExists = recentElements.find(
      (printer) => printer.id === currentName
    );

    // const grafanaBuckets = [
    //   "b1-machine",
    //   "b2-machine",
    //   "b3-machine",
    //   "b4-machine",
    //   "b5-machine",
    //   "b6-machine",
    //   "b7-machine",
    //   "b8-machine",
    //   "b9-machine",
    //   "b10-machine",
    //   "b11-machine",
    //   "b12-machine",
    //   "b13-machine",
    //   "L1-machine",
    //   "L2-machine",
    //   "L3-machine",
    //   "L4-machine",
    //   "mfg-a1a01001",
    //   "mfg-a1a01002",
    //   "mfg-a1a01003",
    //   "mfg-a1a01004",
    //   "mfg-a1a01005",
    //   "mfg-a1a01006",
    //   "mfg-a1a01007",
    //   "mfg-a1a01008",
    //   "mfg-a1a01009",
    //   "a1a01001",
    //   "a1a01002",
    //   "a1a01003",
    //   "a1a01004",
    //   "a1a01005",
    //   "a1a01006",
    //   "a1a01007",
    //   "a1a01008",
    //   "a1a01009",
    // ];

    if (!printerList.includes(grafanaName)) {
      setAddGrafanaError(true);
    } else if (!printerExists && printerList.includes(grafanaName)) {
      try {
        let result = await API.graphql(
          graphqlOperation(createPrinter, { input: data })
        );

        console.log(result);
        LoadMachine();
        get_current_list();
        setOpen(false);
      } catch (e) {
        console.log(e);
      }
    } else {
      setAddPrinterError(true);
    }

    // try{
    //     let result = await API.graphql(graphqlOperation(createPrinter, {input: data}))
    //     console.log(result)
    //     LoadMachine()
    // }
    // catch (e) {
    //     console.log(e)
    // }
  };
  const testAPi = async () => {
    let requestData = {
      body: {
        key: "s3/print-images-dev/b4-machine/media/8c770fee-88c9-11eb-9e0b-0060e07cbafa/vcs/VIZ_L1_FAC_S1_20210319084724.jpg",
      },
    };
    let url = await API.post("lavaRestApi", "/imgeUrl", requestData);
    console.log("url~~~", url);
  };

  const LoadMachine = async () => {
    let requestResult = await API.graphql(
      graphqlOperation(listPrinters, { limit: 100 })
    );
    console.log("LoadMachine ~~~requestResult ~~~~", requestResult);
  };
  function secondsToDhms(seconds) {
    seconds = Number(seconds);
    let d = Math.floor(seconds / (3600 * 24));
    let h = Math.floor((seconds % (3600 * 24)) / 3600);
    let m = Math.floor((seconds % 3600) / 60);
    let s = Math.floor(seconds % 60);
    // console.log(d, h, m, s)
    let dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : "";
    let hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hr, ") : "";
    let mDisplay = m > 0 ? m + (m == 1 ? " min, " : " min ") : "";
    //  let sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : ""
    return dDisplay + hDisplay + mDisplay;
  }
  const printIdVal = (id) => {
    let requestResult = printDataList
      ? printDataList[id]
        ? printDataList[id][0]
        : id
      : id;
    console.log("LoadMachine ~~~requestResult ~~~~", requestResult);
    return requestResult;
  };
  const [isConfigDisabled, setIsConfigDiabled] = useState<Boolean>(false);
  useEffect(() => {
    get_current_list();
    testAPi();
    Auth.currentAuthenticatedUser().then((user) => {
      //console.log(user);

      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"];

      //console.log(groups);

      if (groups == "Operator") {
        setIsConfigDiabled(true);
      } else {
        setIsConfigDiabled(false);
      }
    });
    return function clean() {
      if (updateSubscription) {
        updateSubscription.unsubscribe();
      }
    };
  }, []);
  // useEffect(() => {
  //   let array = recentElements;
  //   setRecentElements(array);
  // }, [printDataList]);
  return (
    <div className="MVPPrintDashboard">
      <div className="dashboard-header">
        <div className="text-h-l">Printers:</div>
        <div className="actionSpace">
          {!isConfigDisabled && (
            <Button className="btn-primary" onClick={() => addPrinter()}>
              Add Printer
            </Button>
          )}
        </div>
      </div>
      <LoadingComponent visible={loading} />

      <div>
        <div></div>
        <div></div>
        <div className="custom-card">
          {recentElements.map((item: any) => {
            const labelId = `checkbox-build-label-${item.name}`;
            const printId = printDataList
              ? printDataList[item.id]
                ? printDataList[item.id][0]
                : ""
              : "";
            return (
              <div key={item.id} className="e-c-card">
                {/* <div  className="e-c-card-h">
                  <div></div>
                </div> */}
                <div className="e-c-card-b"></div>
                <div className="e-c-card-f">
                  <div className="e-c-card-f-h">
                    {/* {printId !== ''? (<div title={item.name}onClick = {()=>{goToBulidReview(item)}}>{printId}</div>):
            (<div title={item.name}onClick = {()=>{goToBulidReview(item)}}>{printIdVal(item.id)}</div>)} */}
                    <div
                      title={item.name}
                      onClick={() => {
                        goToBulidReview(item);
                      }}
                    >
                      {item.id}
                    </div>
                    <div>
                      {item.currentPrintNameOnPrinter
                        ? item.currentPrintNameOnPrinter
                        : "No Data"}
                    </div>
                  </div>
                  <div className="e-c-card-f-e">
                    <div>
                      <div>
                        <div>Printer Status</div>
                        {/* {console.log('Printer' + JSON.stringify(item))} */}
                        <div>
                          {item.currentStatus ? item.currentStatus : "No Data"}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>Print Name</div>
                        <div>
                          {item.currentPrintNameOnPrinter
                            ? item.currentPrintNameOnPrinter
                            : "No Data"}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="e-c-card-f-e">
                    <div className="w-100">
                      <div>
                        <div>
                          Pause Printer Command Status
                          <div>
                            {item.printerPauseCommandStatus
                              ? item.printerPauseCommandStatus
                              : "Idle"}

                            {(item.currentStatus === "Running" &&
                              item.printerPauseCommandStatus === "Stopped") ||
                            item.printerPauseCommandStatus === "" ||
                            item.printerPauseCommandStatus === "Failed" ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-create-new-plate">
                                    Pause Printer
                                  </Tooltip>
                                }
                              >
                                <img
                                alt=""
                                  src="/pause.svg"
                                  className="print-actions ml-2"
                                  onClick={() =>
                                    printerAction(
                                      item.GrafanaBundle,
                                      "stop",
                                      item.id
                                    )
                                  }
                                />
                              </OverlayTrigger>
                            ) : null}

                            {item.currentStatus === "Running" &&
                            item.printerPauseCommandStatus === "Processing" ? (
                              <OverlayTrigger
                                placement="top"
                                overlay={
                                  <Tooltip id="button-create-new-plate">
                                    Pause Printer
                                  </Tooltip>
                                }
                              >
                                <img
                                alt=""
                                  src="/pause.svg"
                                  className="print-actions ml-2"
                                  style={{ opacity: "0.4" }}
                                />
                              </OverlayTrigger>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="e-c-card-f-e mb-5">
                    <div>
                      <div>
                        <div>Remaining Print Time</div>
                        <div>
                          {item.remainingPrintTime
                            ? item.remainingPrintTime === "0"
                              ? "00:00:00 hrs"
                              : secondsToDhms(item.remainingPrintTime)
                            : "No Data"} {"\n"}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div>Remaining Paste needed for Print</div>
                        <div>
                          {item.remainingPasteConsumption
                            ? `${item.remainingPasteConsumption} mL`
                            : "No Data"}{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="e-c-card-f-f">
                  <div className="d-none"></div>
                  <div className="">
                  <Button
                          style={{height: '35px', width : '185px'}}
                         
                          
                          variant="secondary"
                           onClick={() => displayToolsModal(item)}>
                            Overall Tool Status</Button>
                  </div>
                    

                    <div
                      onClick={() => {
                        goTolist(item);
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Modal
        show={open}
        onHide={handleDialogClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="partsDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>New Printer</Modal.Title>
          <span className="close-btn" onClick={handleDialogClose}></span>
        </Modal.Header>
        <Modal.Body>
          <div className="each-dialog-content">
            <div className="each-d-section">
              Enter the name for the new printer
            </div>
            {addGrafanaError && (
              <div style={{ color: "red" }}>
                Invalid Grafana. Please enter a valid Grafana name.
              </div>
            )}
            {addPrinterError && (
              <div style={{ color: "red" }}>
                Printer name already taken. Please use another name
              </div>
            )}
            <div className="each-d-section">
              <input
                className="f-w"
                type={"text"}
                value={currentName}
                onChange={(ev) => {
                  let v = ev.target.value;
                  setCurrentName(v);
                }}
              />
            </div>
            <div className="each-d-section">Select Grafana name</div>
           
              <div className="bp-l-b-e btstrp dropdown">
                <div className="bp-l-b-e-h"></div>
                <div className="btn-group bp-l-b-e-dd">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {selectedGrafana !== ""
                      ? selectedGrafana
                    : "Select the Grafana name"}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  />
                  <div className="dropdown-menu">
                  {printerList.length !==0 ? (printerList.map((printer, idx) => (
                      <div
                        key={idx}
                        id={printer}
                        className="dropdown-item"
                        onClick={() => {
                          setSelectedGrafana(printer); setGrafanaName(printer)
                        }}
                      >
                        <label>{printer}</label>
                      </div>
                    ))):
                    <div style={{height:'150px',display:'flex',justifyContent:'center'}} className="dropdown-item">
                      <img width="50px" src="/img/loader.svg" alt="loading" />
                    </div>}
                  </div>
                </div>
              </div>
            

            {/* <div className="each-d-section">
              <input
                className="f-w"
                type={"text"}
                value={grafanaName}
                onChange={(ev) => {
                  let v = ev.target.value;
                  setGrafanaName(v);
                }}
              />
            </div> */}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button className="btn-primary" onClick={aaddPrint} autoFocus>
            Add Printer
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Pause Printer Modal */}
      <Modal
        show={pausePrintModal}
        onHide={handleDialogCloseP}
        backdrop="static"
        keyboard={false}
        dialogClassName="partsDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title></Modal.Title>
          {/* <span className="close-btn"
            onClick={handleDialogCloseP}>
          </span> */}
        </Modal.Header>
        <Modal.Body>
          <div className="each-dialog-content">
            <div>
              <div className="text-center">
                <h4>
                  <span>Printer Name: </span>
                  {selectedPrinterId}
                </h4>
                <div className="primary-color pt-3">
                  Pause Command issued, <br />
                  there will be a delay before the printing is paused.
                </div>
                <h5 style={{ margin: "30px" }}>Please refresh the page.</h5>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            className="btn btn-secondary"
            onClick={handleDialogCloseP}
            autoFocus
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={openP}
        onHide={handleDialogCloseP}
        backdrop="static"
        keyboard={false}
        dialogClassName="partsDialog"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title>Print Queue {selectedPrinterName}</Modal.Title>
          <span className="close-btn" onClick={handleDialogCloseP}></span>
        </Modal.Header>
        <Modal.Body>
          <div className="each-dialog-content">
            {selectedPrinter.map((item: any) => {
              return (
                <div className="each-d-section">
                  {item.replace("_Lava.gcode", "")}
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          {/* <Button variant="outline-secondary" className='outline-secondarytextactive-btn' onClick= {aaddPrint} autoFocus>
                Add Print
                        </Button> */}
        </Modal.Footer>
      </Modal>
      
      {selectedPrinterTools !== undefined && selectedPrinterTools !== null && selectedPrinterTools.length !==0 ? (
        <MVPToolsDetailsModal
          showModal={displayToolsDetails}
          hideModal={handleModalClose}
          selectedPrinterTools={selectedPrinterTools}
          primaryInfoToFetchToolsDetails ={primaryInfoToFetchToolsDetails}
          printerId={selectedPrinterId}
        ></MVPToolsDetailsModal>
      ) : null}
    </div>
  );
};

export default MVPPrintDashboard;
