import React, { FunctionComponent, useEffect } from "react";
import * as THREE from "three";
import Scene from "../../Scene";
import WorkspaceMeshManager, { IWorkspaceMeshManager } from "./WorkspaceMeshManager";
import WorkspaceSceneController from "./WorkspaceSceneController";

import IPartInfo from "../../interfaces/IPartInfo";

interface IWorkspaceSceneProps {
    parts: IPartInfo[];
    machineConfig?: any;
    materialConfig?: any;
    recipe?: any;
    millConfig?: any;
    dispatch: any;
    refProcessor: (ref: Scene) => void;
    screenName?: string;
    isLoadingStarted?: boolean;
    isLoadingInProgress?: boolean;
    isLoadingComplete?: boolean;
}

const WorkspaceScene: FunctionComponent<IWorkspaceSceneProps> = (props) => {
    const [scene, setScene] = React.useState<Scene | null>(null);

    const [workspaceMeshManager, setWorkspaceMeshManager] = React.useState<IWorkspaceMeshManager | null>(null);

    const setSceneContainer = React.useCallback((element) => {
        if (element) {
            let scene = new Scene(element);
            const buildVolume = props.machineConfig?.Build?.BuildVolumes[0];

            let minVector = buildVolume ?
                new THREE.Vector3(
                    buildVolume?.Size ? -(buildVolume.Size.x / 2) : buildVolume?.Min ? buildVolume?.Min.x : -75,
                    buildVolume?.Size ? -(buildVolume.Size.y / 2) : buildVolume?.Min ? buildVolume?.Min.y : -75,
                    buildVolume?.Size ? 0 : buildVolume?.Min ? buildVolume?.Min.z : 0,
                ) : new THREE.Vector3(-75, -75, 0);

            let maxVector = buildVolume ?
                new THREE.Vector3(
                    buildVolume?.Size ? (buildVolume.Size.x / 2) : buildVolume?.Max ? buildVolume?.Max.x : 75,
                    buildVolume?.Size ? (buildVolume.Size.y / 2) : buildVolume?.Max ? buildVolume?.Max.y : 75,
                    buildVolume?.Size ? (buildVolume.Size.z) : buildVolume?.Max ? buildVolume?.Max.z : 100,
                ) : new THREE.Vector3(75, 75, 100);

            const buildAreaVolume = new THREE.Box3(minVector, maxVector);
            setScene(scene);
            props.refProcessor(scene);
            let workspaceMeshManager = new WorkspaceMeshManager(
                scene,
                props.dispatch,
                buildAreaVolume
            );
            workspaceMeshManager?.updateParts(props.parts, props.screenName);
            setWorkspaceMeshManager(workspaceMeshManager);
        }
    }, []);

    useEffect(()=> {
        workspaceMeshManager?.updateParts(props.parts, props.screenName);
    }, [props.parts.length])

    React.useEffect(()=>{
        applyScaling()
        applyBuild()
    }, [workspaceMeshManager !== null])

    React.useEffect(() => {
        workspaceMeshManager?.updateScreen(true);
    }, [props.isLoadingStarted, props.isLoadingInProgress, props.isLoadingComplete]);

    React.useEffect(()=>{
        let maximumDiameter = 0;
        props.millConfig?.Mill?.Tools.forEach(val =>{
            if (val.ShaftDiam > maximumDiameter)
                maximumDiameter = val.ShaftDiam
        })
        workspaceMeshManager?.setMaximumToolRadius(maximumDiameter /2)
    }, [props.millConfig])

    React.useEffect(() => {
      applyBuild();
    }, [JSON.stringify(props.machineConfig)]);

    function applyBuild() {
      if (workspaceMeshManager) {
        const buildVolume = props.machineConfig?.Build?.BuildVolumes[0];

        let minVector = new THREE.Vector3(
          buildVolume?.Size ? -(buildVolume.Size.x / 2) : buildVolume?.Min ? buildVolume?.Min.x : -75,
          buildVolume?.Size ? -(buildVolume.Size.y / 2) : buildVolume?.Min ? buildVolume?.Min.y : -75,
          buildVolume?.Size ? 0 : buildVolume?.Min ? buildVolume?.Min.z : 0,
        )
        let maxVector = new THREE.Vector3(
          buildVolume?.Size ? (buildVolume.Size.x / 2) : buildVolume?.Max ? buildVolume?.Max.x : 75,
          buildVolume?.Size ? (buildVolume.Size.y / 2) : buildVolume?.Max ? buildVolume?.Max.y : 75,
          buildVolume?.Size ? (buildVolume.Size.z) : buildVolume?.Max ? buildVolume?.Max.z : 100,
        )
        const buildAreaVolume = new THREE.Box3(minVector, maxVector)
        workspaceMeshManager.setBuildAreaVolume(buildAreaVolume);
        workspaceMeshManager.setMaxPartSize(props.machineConfig?.Build?.MaxPartSize);
        workspaceMeshManager.setMaxPartWeight(props.machineConfig?.Build?.MaxPartWeight);
      }
    }
    function applyScaling() {
      if (workspaceMeshManager) {
        const materialObj = props.materialConfig;
        let coeffXY = 1;
        let coeffZ = 1;
        if (materialObj !== undefined) {
          if (materialObj.shrinkage) {
            const parsePercentage = (input: String): number => {
              if (input[input.length - 1] === '%') {
                let parsed = Number(input.substring(0, input.length - 1));
                if (!Number.isNaN(parsed)) {
                  return parsed;
                }
              }
              return NaN;
            };
            if (typeof (materialObj.shrinkage.xy) === "string") {
              let parsed = parsePercentage(materialObj.shrinkage.xy);
              if (!Number.isNaN(parsed) && parsed < 100) {
                coeffXY = 1 / (1 - (parsed / 100));
              }
            } else if (typeof (materialObj.shrinkage.x) === "string") {
              let parsed = parsePercentage(materialObj.shrinkage.x);
              if (!Number.isNaN(parsed) && parsed < 100) {
                coeffXY = 1 / (1 - (parsed / 100));
              }
            }
            if (typeof (materialObj.shrinkage.z) === "string") {
              let parsed = parsePercentage(materialObj.shrinkage.z);
              if (!Number.isNaN(parsed) && parsed < 100) {
                coeffZ = 1 / (1 - (parsed / 100));
              }
            }
          }
        }
        workspaceMeshManager.setMaterialScaling(coeffXY, coeffZ);
        workspaceMeshManager.setMaterialDensity(materialObj?.Density);
      }
    }

    React.useEffect(() => {
        applyScaling();
    }, [JSON.stringify(props.materialConfig)]);

    return (
        <div ref={setSceneContainer} style={{ flexGrow: 1, position: "relative" }}>
            {scene && props.screenName !== 'build-review' ? (
                <WorkspaceSceneController
                    scene={scene}
                    meshManager={workspaceMeshManager!}
                    dispatch={props.dispatch}
                />
            ) : null}
        </div>
    );
};


export default WorkspaceScene;
