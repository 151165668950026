import { IPlate, IState } from '../../../Interfaces';

const onHighlightPlateObjectReducer = (state: IState, action) => {
    const plate = action.payload as IPlate;
    const newState = { ...state } as IState;
    if (plate){
        newState.data.plates.loadedPlates.push(plate)
    }
   
    return newState;
};

export default onHighlightPlateObjectReducer;