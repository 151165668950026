/* section: import - start */
import React, { useEffect, useState, lazy, Suspense } from "react";
import "./MVPPrintReview.scss";
import Button from "react-bootstrap/Button";
import API, { graphqlOperation } from "@aws-amplify/api";
import Amplify, { Auth } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import LoadingComponent from "../LoadingComponent/LoadingComponent";
import IPartInfo from "../interfaces/IPartInfo";
import IMatrix from "../interfaces/IMatrix";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import awsconfig from "../aws-exports";
import awsmobile from "../aws-exports";
import { useHistory } from "react-router-dom";
import { Slider, TextField } from "@material-ui/core";
import { listAllKeys } from "../utils";
import { toNumber } from "lodash";
import { Storage } from "aws-amplify";

import {
  getPrint,
  printByPrinterStart,
  buildByGcodeUUID,
  buildByCreatedAt,
  imageByPrint,
  getPrintFeedback,
  getPrinter,
  getBuild,
} from "../graphql/queries";
import { Print } from "../models";
import { listPrinters } from "../graphql/queries";

import { Modal } from "react-bootstrap";
import SideMenuComponent from "./SideMenuComponent";
/* section: import - end */
const MVPPrintFeedbackModal = lazy(() => import('./MVPPrintFeedback'));
Amplify.configure(awsconfig);

/* section - const - start */
const AntSwitch = withStyles((theme) => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);
type PrintableEntity = IPartInfo | IMatrix;
/* section - const - end */

/* section - components - start */
function MVPPrintReview(props) {
  const [prints, setPrints] = useState<any[]>();
  const [builds, setBuilds] = useState<any>({});
  const [printHasImages, setPrintHasImages] = useState<any>({});
  const [marks, setMarks] = useState<any[]>([{ value: 0, label: "0" }]);
  const [print, setPrint] = useState<Print>();
  const [reviewLoading, setReviewLoading] = useState<boolean>(false);
  const [images, setImages] = useState<any>({ thermal: {}, vision: {} });
  const [buildId, setBuildId] = useState<any>("");
  const [printerId, setPrinterId] = useState<any>("");
  const [printId, setPrintId] = useState<any>("");
  const [bundle_UUID, setBundle_UUID] = useState<any>("");
  const [slicedImagesFullScreen, setSlicedImagesFullScreen] = useState<boolean>(false);
  const [actualImageLayer, setActualImageLayer] = useState<any>(null);
  const [displayPrintFeedback, setDisplayPrintFeedback] = useState<boolean>(false);
  const [displayPrintFeedbackButton, setDisplayPrintFeedbackButton] = useState<boolean>(true);
  const [actualImagesFullScreen, setActualImageFullScreen] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<any>("");
  const [layerTextboxError, setlayerTextboxError] = useState<any>("");
  const [percentage, setPercentage] = useState<any>("");
  const [loading, setLoading] = useState<boolean>(false);



  /* section: SlicedImagesComponent - start */
  const [imageTypes, setImageTypes] = useState<any>({});
  const [imageType, setImageType] = useState("");
  const [currentLayerValue, setCurrentLayerValue] = useState<any>(1);
  const [currentLayer, setCurrentLayer] = useState<any>(1);
  const [link, setLink] = useState("");
  /* section: SlicedImagesComponent - end */

  /* section: ActualImagesComponent - start */
  const [isNormalImageViewSelected, setIsNormalImageViewSelected] = useState(false);
  const [imagePTypes, setImagePTypes] = useState<any>({});
  const [imagePType, setImagePType] = useState("");
  const [currentPLayer, setCurrentPLayer] = useState(1);
  const [linkP, setLinkP] = useState("");
  const [isConfigDisabled, setIsConfigDisabled] = useState<Boolean>(false);
  const [pausePrintModal, setPausePrintModal] = useState<boolean>(false);
  /* section: ActualImagesComponent - end */
  const [isFeedbackDisabled, setIsFeedbackDisabled] = useState<boolean>(true);


  const [layerExists, setLayerExists] = useState<boolean>(true);
  const [isPrinterPrintingCurrentPrint, setIsPrinterPrintingCurrentPrint] = useState<boolean>(false);
  const [layerDetails, setLayerDetails] = useState<any>({
    starLayer:null,
    endLayer:null
  });
  const [layersCompleted, setLayersCompleted] = useState<any>({
    endLayer:null,
  });
  const [printOwner, setPrintOwner] = useState("");
  const [gCodeID, setGCodeID] = useState("");
  const [printID, setPrintID] = useState("");
  const [buildName, setBuildName] = useState("");
  const history = useHistory();
  const [totalLayer, setTotalLayer] = useState(0);
  // const [totalTime, setTotalTime] = useState("");
  const [activeBundle, setActiveBundle] = useState("");
  const [printStatus, setPrintStatus] = useState("");
  const handleClose = () => {
    history.push("/dashboard/print");
  };

  const displayPrintFeedbackModal = () => {
    setDisplayPrintFeedback(true)
  }
  const handleModalClose = () => {
    setDisplayPrintFeedback(false);
    setPausePrintModal(false);
  }

  const printerAction = async(id, status) => {
    const response = await API.get("lavaPrinterControllerApi", `/${id}/${status}`, {
      headers: {}, 
      response: true, 
      },
    );
        setPausePrintModal(true);
  }
  const handleModalShow = () => setDisplayPrintFeedback(true);
  const fetchHasImages = async (printItems) => {
    let localHasImages = Object.fromEntries(await Promise.all(printItems.map(async (print) => {
      let req: any = { printId: print.id, limit: 1 };
      console.log("Processing print ID ", print.id);
      let printImages = (
        await API.graphql(graphqlOperation(imageByPrint, req))
      )["data"]["imageByPrint"]["items"];
      return [print.id, (printImages.length > 0)];
    })));
    setPrintHasImages(localHasImages);
  };

  let bundles: any = builds;//{};
  let layers: any = {};

  const readPrinterPrints = async (printerId) => {
    let requestData = {
      body: {
        measurements: ['stage'],
        events: ['RUN'],
        fields: ['gcode uid', 'id']
      },
    };
    let timeline = await API.post("influxPrinterApi", "/printer-timeline/" + printerId, requestData);
    timeline = timeline.timeline;//.reverse();
    let prints = timeline.reduce((c, v, i, a) => {
      if (c.length) {
        c[c.length - 1].end_time = v._time;
      }
      c.push({
        start_time: v._time,
        end_time: undefined,
        id: v.fields['id'],
        gcode: v.fields['gcode uid']
      });
      return c;
    }, []).reverse();
    console.log("PRINTS:", prints);
    let bundleIDs = timeline.map((v) => v.fields['gcode uid']);
    console.log("bundleIDs", bundleIDs);
    bundleIDs = bundleIDs.filter((v, i, a) => a.indexOf(v) === i);

    let pbundles = await Promise.all(bundleIDs.map(async (v) => {
      let bundle_UUID = v;
      try {
        let bundleResult = await API.graphql(
          graphqlOperation(buildByGcodeUUID, { gcode_uuid: bundle_UUID })
        );
        // setPrintOwner((bundleResult as any).data.buildByGcodeUUID.items[0].created_by);
        // setTotalTime((bundleResult as any).data.buildByGcodeUUID.items[0].build_time);

        return (bundleResult as any).data.buildByGcodeUUID.items[0];
      }
      catch (e) { }
      return undefined;
    }));
    pbundles = pbundles.filter((v) => v !== undefined);
    let bundleMap = {};
    pbundles.forEach((v) => {
      bundleMap[v.gcode_uuid] = v;
    });
    prints = prints.filter((v) => v.gcode in bundleMap);
    prints = prints.reduce((c, v) => {
      const idx = c.findIndex((n) => v.id == n.id);
      if (idx >= 0) {
        c[idx].start_time = v.start_time;
      }
      else {
        c.push({ ...v, id: v.id });
      }
      return c;
    }, []);
    prints = prints.map((v) => ({
      id: v.id,
      bundle_UUID: v.gcode,
      start: v.start_time,
      end: v.end_time,
      visionImages: [],
      thermalImages: [],
      operationTimes: [],
      printerId: printerId,
      comment: '',
      buildID: bundleMap[v.gcode].id,
      estTotalTime: '',
      totalPercentage: '',
      totalPaste: '',
      printerOwner: '',
      ETA: '',
      numberOfLayers: '',
      layerStatus: ''
    }));
    console.log('PRINTS', prints.bundle_UUID);
    bundles = bundleMap;
    setBuilds(bundleMap);
    console.log('SET BUNDLES', bundles);
    setPrints(prints);
    if (prints.length && prints[0]?.id) {
      showPrint(prints[0]);
    }
  }



  const configsDisplay = () => {
    readPrinterPrints(props.location.state.selectedBuild.GrafanaBundle).catch((reason) => {
      console.error(reason);
    });
    const fetchUserDetails = () => {
      Auth.currentAuthenticatedUser().then((user) => {
        if (!unmounted) {
          setUserEmail(user.attributes.email)
        }
        const groups =
          user.signInUserSession.accessToken.payload["cognito:groups"];
        if (groups == "Operator") {
          setIsConfigDisabled(true);
        } else {
          setIsConfigDisabled(false);
        }
      })
    }
    let unmounted = false;
    fetchUserDetails();
  
    return () => {
      unmounted = true;
    };
  }
  useEffect(() => {
    configsDisplay();
  }, [props.location.state.selectedBuild.name]);


  /* section: showPrint - start */
  const showPrint = (print) => {
    console.log("showPrint~~~~", print);
    setLinkP(""); // Hide previous image if there was one
    readImagePTypes(print);
    readImageTypes(print.bundle_UUID);
    setGCodeID(print.bundle_UUID);
    setPrintID(print.id);
    setLayersCompleted('');
  };

  const readMeasurements = async (print) => {
    let id = print.id;
    let printerId = props.location.state.selectedBuild.GrafanaBundle;
    let requestData = {
      body: {
        from_time: print.start,
        to_time: print.end ? print.end : undefined,
        measurements: ['Thermal - Z', 'Vision - Z'],
        fields: ['image file', 'image url', 'STG', 'LYR']
      },
    };
    console.log('Sending: ', requestData.body);
    let timeline = await API.post("influxPrinterApi", "/printer-timeline/" + printerId, requestData);
    timeline = timeline.timeline;
  }

  const readImagePTypes = async (print) => {
    let id = print.id;
    let printerId = props.location.state.selectedBuild.GrafanaBundle;
    setImagePTypes({});
  

    let requestData = {
      body: {
        from_time: print.start,
        to_time: print.end ? print.end : undefined,
        measurements: ['Thermal - Z', 'Vision - Z'],
        fields: ['image file', 'image url', 'STG', 'LYR']
      },
    };
    console.log('Sending: ', requestData.body);
    let timeline = await API.post("influxPrinterApi", "/printer-timeline/" + printerId, requestData);
    timeline = timeline.timeline;
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~ here ~~~~~~~~~~~~~~~~~~~~~~~~~~~

    console.log("TIMELINE:", timeline);
    let printImages = timeline.map((v) => (
      {
        printerId: printerId,
        printId: print.id,
        type: v._measurement === 'Thermal - Z' ? 'thermal' : 'vision',
        step: v.fields['STG'],
        layer: +v.fields['LYR'],
        path: v.fields['image file'],
        time: v._time
      }
    ));
    console.log("IMAGES:", printImages);

    let thisPrint = print;
    console.log("thisPrint~~~~", thisPrint);
    if (props.location.state.selectedBuild.currentStatus === "Running" && props.location.state.selectedBuild.currentPrint === thisPrint.id){
      setIsPrinterPrintingCurrentPrint(true)
    }
   

  
    setPrint(thisPrint);
    setPrinterId(thisPrint.printerId);
    setPrintId(thisPrint.id);
    setBundle_UUID(thisPrint.bundle_UUID);
    setBuildName(thisPrint.buildID)

    let thermalImages = {};
    let visionImages = {};
    const images = { thermal: thermalImages, vision: visionImages };
    let req: any = { printId: thisPrint.id };
    printImages.forEach((image) => {
      if (!images[image.type]) {
        images[image.type] = {};
      }
      if (!images[image.type][image.step]) {
        images[image.type][image.step] = {};
      }
      images[image.type][image.step][image.layer] = { ...image };
    });
    console.log(images);

    let imgPTypes = isNormalImageViewSelected
      ? images["thermal"]
      : images["vision"];
    setImages(images);
    console.log(imgPTypes);
    if (Object.keys(imgPTypes).length > 0) {
      setImagePTypes(imgPTypes);
    }
    let stagesKeys = Object.keys(imgPTypes)
    if (stagesKeys.length > 0) {
      setImagePType(stagesKeys[0])
      await updatePLayer(stagesKeys[0], 1, imgPTypes);
    }
    else {
      setImagePType("");
    }
    setIsFeedbackDisabled(false)
  };

  const readImageTypes = async (bundle_UUID) => {
    setImageTypes({});
    let bundleResult = bundles[bundle_UUID];
    if (!bundleResult) {
      console.error("Bundle not found: ", bundle_UUID);
      return;
    }
    let thisBundle = bundleResult;
    console.log("thisBundle~~~~", thisBundle);
    console.log('Print Owner'+ thisBundle.created_by)
    //setCurrentLayer(1);
    changeLayer(null, 1);
    setImageType("BuildVoids");
    setBuildId(thisBundle.id);
    startPrintReview(thisBundle.bundle);
    setActiveBundle(thisBundle.bundle);
    setPrintOwner(thisBundle.created_by);
    // setTotalTime(thisBundle.build_time);
    setPrintStatus(thisBundle.current_status);
  };

  const startPrintReview = async (bundlePath) => {
    const buildVoids = "layer${pictureLayer + 1}_fixed.png";

    setReviewLoading(true);
    let returnObject = {};
    let allKeys = await listAllKeys(bundlePath.substring("public/".length));
    for (let currentInd = 0; currentInd < allKeys.length; currentInd++) {
      let currentKey = allKeys[currentInd];
      const layerEnd = currentKey.length - "_fixed.png".length;
      const layerStart = currentKey.indexOf("layer");
      if (layerStart === -1 || currentKey.indexOf("_fixed.png") !== layerEnd) {
        continue;
      }

      let levelNumberSubstring = currentKey.substring(
        layerStart + "layer".length,
        layerEnd
      );
      let layer = toNumber(levelNumberSubstring);
      const tag = "BuildVoids";

      if (tag && layer) {
        if (!returnObject[tag]) {
          returnObject[tag] = {};
        }
        returnObject[tag][layer] = currentKey;
      }
    }
    setImageTypes(returnObject);
    setImageType("BuildVoids");
    setReviewLoading(false);
  };
  /* section: showPrint - end */

  /* section: SlicedImagesComponent - start */
  const changeImageType = async (value) => {
    let curImageType = (value as string) || "";
    setImageType(curImageType);
    await updateLayer(curImageType, currentLayer);
  };

  const changeVal = (e, value) => {
    if (currentLayer === value) {
      setCurrentLayer(value + 1);
    }
    setCurrentLayerValue(value);
    e.stopPropagation();
  };

  const changeLayer = (event, value) => {
    setCurrentLayer(value);
    setCurrentLayerValue(value);
    if (value != actualImageLayer) {
      return Promise.all([changePLayer(event, value)]);
      //updateLayer(imageType, value),
    }
  };

  const handleInputChange = (event, value, max) => {
    if (value > max) {
      setlayerTextboxError(`Number should be less than ${max}`)
    }
    else {
      setlayerTextboxError("")
      changeLayer(event, value)
    }
  }



  const updateLayer = async (curImageType, curLayer) => {
    try {
      setLoading(true);
      if (
        imageTypes &&
        imageTypes[curImageType] &&
        imageTypes[curImageType][curLayer]
      ) {
        let requestData = {
          body: {
            key: "public/" + imageTypes[curImageType][curLayer],
            bucket: awsmobile.aws_user_files_s3_bucket,
          },
        };
        let url = await API.post("lavaRestApi", "/imgeUrl", requestData);
        setLink(url.url);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      NotificationManager.error(e);
    }
  };
  /* section: SlicedImagesComponent - end */

  const handleKeyDown = async (event) => {
    let layer = currentLayer;
    if (event.ctrlKey) {
      switch (event.keyCode) {
        case 37:
          layer = layer - 1;
          console.log(layer);
          if (layer >= 0) {
            await changeLayer(event, layer);
          }
          break;
        case 39:
          layer = layer + 1;
          if (layer < Object.keys(imageTypes?.BuildVoids).length) {
            await changeLayer(event, layer);
          }
          break;
      }
    }
  }

  /* section: ActualImagesComponent - start */
  const switchIsNrmlImgViewSelected = async (event, checked) => {
    event.stopPropagation();
    //setImagePTypes({});
    setImagePTypes({ "No images": [] });
    setImagePType("");
    setLinkP("");
    let isNrmlImgViewSelected = checked as boolean;
    setIsNormalImageViewSelected(isNrmlImgViewSelected);
    let imgPTypes = isNrmlImgViewSelected
      ? images["thermal"]
      : images["vision"];
    console.log(imgPTypes);
    if (Object.keys(imgPTypes).length > 0) {
      setImagePTypes(imgPTypes);
    }
  };

  const changeImagePType = async (value) => {
    let curImagePType = (value as string) || "";
    setImagePType(curImagePType);
    await updatePLayer(curImagePType, currentPLayer, imagePTypes);
  };

  const changePLayer = async (event, value) => {
    if (event) {
      event.stopPropagation();
    }
    let curPLayer = value as number;
    setCurrentPLayer(curPLayer);
    await updatePLayer(imagePType, curPLayer, imagePTypes);
  };

  async function getUrlForPath(path: string, thumbnail = false) {
    let requestData = {
      body: {
        key: path,
        resized: thumbnail,
      },
    };
    let url = await API.post("lavaRestApi", "/imgeUrl", requestData);
    return url.url;
  }

  const updatePLayer = async (curImagePType, curLayer, curImagePTypes) => {
    try {
      let extractedLayer: any = null;
      let layerToShow = -1;
      if (curImagePTypes && curImagePType && curImagePTypes[curImagePType]) {
        console.log("selected");
        if (curImagePTypes[curImagePType][curLayer]) {
          setActualImageLayer(curLayer);
        }
        const availableLayersInThisprint = Object.keys(curImagePTypes[curImagePType])
        if (Array.isArray(availableLayersInThisprint) && availableLayersInThisprint.length > 0 && availableLayersInThisprint !== undefined) {
          const layerExist = availableLayersInThisprint.some((el) => el == curLayer)
          setLayersCompleted({endLayer: availableLayersInThisprint[availableLayersInThisprint.length-1]})
          if (layerExist) {
            setLayerExists(true)
          }
          else {
            setLayerExists(false)
            setLayerDetails({
              startLayer: availableLayersInThisprint[0],
              endLayer: availableLayersInThisprint[availableLayersInThisprint.length-1],
            })
          }
        }
        
        Object.keys(curImagePTypes[curImagePType]).forEach((layer) => {
          const layerNumber = Number(layer);
          if (layerNumber <= curLayer) {
            layerToShow = layerNumber;
            extractedLayer = curImagePTypes[curImagePType][layer];
          }
        });
        if (extractedLayer) {
          let path = `${extractedLayer.path}`;
          let url = await getUrlForPath(path);
          setLinkP(url);
          setActualImageLayer(layerToShow);
        } else {
          setActualImageLayer(-1);
          setLinkP("");
        }
      } else {
        setLinkP("");
        setActualImageLayer(layerToShow);
      }
    } catch (e) {
      console.error(e);
      NotificationManager.error(e);
    }
  };

  /* section: ActualImagesComponent - end */
  function secondsToDhms(seconds) {
    seconds = Number(seconds)
    let d = Math.floor(seconds / (3600 * 24))
    let h = Math.floor((seconds % (3600 * 24)) / 3600)
    let m = Math.floor((seconds % 3600) / 60)
    let s = Math.floor(seconds % 60)
    // console.log(d, h, m, s)
    let dDisplay = d > 0 ? d + (d == 1 ? " day, " : " days, ") : ""
    let hDisplay = h > 0 ? h + (h == 1 ? " hr, " : " hr, ") : ""
    let mDisplay = m > 0 ? m + (m == 1 ? " min, " : " min ") : ""
    //  let sDisplay = s > 0 ? s + (s == 1 ? " sec" : " sec") : ""
    return dDisplay + hDisplay + mDisplay
  }
  /* section: components - start */

  /* section: PrintReviewHeaderComponent - start */
  const PrintReviewHeaderComponent = () => {
    return (
      <div className="bp-r-h">
        <div className="slider-wrap">
          <Slider
            value={currentLayerValue}
            onChange={(e, v) => changeVal(e, v)}
            onChangeCommitted={(e, v) => changeLayer(e, v)}
            step={1}
            valueLabelDisplay="auto"
            style={{ width: "450px", margin: "0px 30px" }}
            aria-labelledby="discrete-slider-custom"
            getAriaValueText={(value) => `${value}`}
            max={
              imageTypes?.BuildVoids
                ? Object.keys(imageTypes?.BuildVoids).length
                : 0
            }
          />

          <div className="slider-count d-flex" style={{ flexDirection: 'column' }}>
            {imageTypes["BuildVoids"] ? (
              <div>
                {<TextField
                  type="number"
                  value={currentLayer}
                  onChange={(ev) => handleInputChange(ev, ev.target.value, Object.keys(imageTypes["BuildVoids"]).length)}
                  placeholder="current Layer"
                  style={{ "width": "50px" }}
                  margin="normal"
                  InputProps={{ inputProps: { min: 1, max: Object.keys(imageTypes["BuildVoids"]).length } }}
                />}
                <label style={{ position: 'relative', top: '4px' }}>/{Object.keys(imageTypes["BuildVoids"]).length}
                </label>
              </div>
            ) : null}
          </div>

          <div>
            <Button
              className="btn-print-review-primary"
              onClick={async (ev) => {
                if (!print?.id)
                  return
                let imageType = isNormalImageViewSelected
                  ? "thermal"
                  : "vision";

                async function downloadStage(imageStage) {
                  let fileNameToDownload = `${print?.id}_${imageType}_${imageStage}.zip`;
                  let request_url = `s3/print-images-dev/prints/${fileNameToDownload}`;
                  let url = await getUrlForPath(request_url);
                  const link = document.createElement("a");
                  link.href = url;
                  link.download = fileNameToDownload;
                  link.click();
                }

                if (imagePType) {
                  await downloadStage(imagePType);
                }
                else {
                  for (imageType of imagePTypes) {
                    await downloadStage(imageType)
                  }
                }
              }}
            >
              Download
            </Button>
          </div>
        </div>
        <div className="bp-r-h-e dropdown">
          <div className="btn-group bp-r-h-e-dd">
            <button
              type="button"
              className="btn btn-secondary dropdown-text"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              style={{ width: "200px" }}
            >
              {print?.buildID ? `${print.buildID}` : "Select Print Bundle"}
            </button>
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            ></button>
            <div className="dropdown-menu">
              {prints?.map((print) => (
                <div key={print.id} className="dropdown-item" onClick={() => showPrint(print)}>
                  <label className="p-14 c-292929">{`${print.buildID
                    } ${new Date(print.start).toLocaleString()} ${printHasImages[print.id] === false ? "(no images)" : ""}`}</label>
                </div>
              ))}
            </div>
            {print?.buildID ? <a href={`/dashboard/BuildReview/${print?.buildID}`} className="btn btn-primary ml-3" role="button">Build</a> : null}
          </div>
        </div>
      </div>
    );
  };
  /* section: PrintReviewHeaderComponent - end */

  /* section: PrintReviewBodyComponent - start */
  const PrintReviewBodyComponent = () => {
    return (
      <>
       <LoadingComponent visible={loading} />
        <section className="print-review">
          <div
            className={"sliced-images"}
            style={
              actualImagesFullScreen
                ? {
                  display: "none",
                }
                : {}
            }
          >
            {imageTypes && imageTypes[imageType] && link ? (
              <>
                {slicedImagesFullScreen && (
                  <img
                    style={{ float: "right", margin: "20px" }}
                    src="/img/exit_full_screen.svg"
                    alt="exit full screen"
                    onClick={() => setSlicedImagesFullScreen(false)}
                  ></img>
                )}
                {!slicedImagesFullScreen && (
                  <img
                    style={{ float: "right", margin: "20px" }}
                    alt="full screen"
                    src="/img/full_screen.svg"
                    onClick={() => setSlicedImagesFullScreen(true)}
                  ></img>
                )}
              </>
            ) : null}

            {/* <SlicedImagesHeaderComponent /> */}
            <SlicedImagesBodyComponent />
          </div>

          <div
            className={"actual-images"}
            style={
              slicedImagesFullScreen
                ? {
                  display: "none",
                }
              : { height: "85vh" }
          }
        >
          <ActualImagesHeaderComponent />
          <ActualImagesBodyComponent />
        </div>
          <Suspense fallback={<LoadingComponent visible={true}></LoadingComponent>}>
        <MVPPrintFeedbackModal
          showModal={displayPrintFeedback}
          hideModal={handleModalClose}
          userEmail={userEmail}
          displayPrintFeedback={displayPrintFeedback}
          printId={printId}
        ></MVPPrintFeedbackModal>
          </Suspense>
      </section>
        {/* Pause Printer Modal  */}
        <Modal
          show={pausePrintModal}
          onHide={handleModalClose}
          backdrop="static"
          keyboard={false}
          dialogClassName="partsDialog"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header>
            <Modal.Title></Modal.Title>
            {/* <span className="close-btn"
              onClick={handleModalClose}>
            </span> */}
          </Modal.Header>
          <Modal.Body>
            <div className="each-dialog-content">
              <div>
                <div className="text-center">
                  <h4><span>Printer Name : </span>{props.location.state.selectedBuild.GrafanaBundle}</h4>
                  <div className="primary-color pt-3">Pause Command issued, <br />there will be a delay before the printing is paused.</div>
                  <h5 style={{ margin: '30px' }}>Please refresh the page.</h5>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            <Button className='btn btn-secondary' onClick={handleModalClose} autoFocus>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
  /* section: PrintReviewBodyComponent - end */

  /* section: SlicedImagesComponent - start */
  const SlicedImagesHeaderComponent = () => {
    return (
      <hgroup>
        <div>
          <label>Sliced</label>
          {Object.keys(imageTypes).length ? (
            <div className="btstrp dropdown">
              <div className="btn-group">
                <button
                  type="button"
                  className="btn btn-secondary dropdown-text"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {imageType ? imageType : `Select the image type`}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                ></button>
                <div className="dropdown-menu">
                  {Object.keys(imageTypes).map((imageType) => (
                    <div
                      id={imageType}
                      className="dropdown-item"
                      onClick={() => {
                        changeImageType(imageType);
                      }}
                    >
                      <label className="p-14 c-292929">{imageType}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </hgroup>
    );
  };

  const SlicedImagesBodyComponent = () => {
    return (
      <div className="image">
        {imageTypes && imageTypes[imageType] && link ? (
          <img className="print_review_image" src={link} />
        ) : null}
      </div>
    );
  };
  /* section: SlicedImagesComponent - end */

  /* section: ActualImagesComponent - start */
  const ActualImagesHeaderComponent = () => {
    let itemTypes = {
      'DSP': 'Dispense',
      'FAC': 'Facing',
      'PSC': 'Contouring',
    }
    return (
      <hgroup>
        <div style={{ justifyContent: "space-between" }}>
          {Object.keys(imageTypes).length ? (
            <div style={{ display: "flex" }}>
              <div className="btstrp dropdown">
                <div style={{ height: '1em' }}>
                  {currentLayerValue !== currentLayer ? (imagePTypes[imagePType] || [])[currentLayerValue]?.time || "" :
                    (imagePTypes[imagePType] || [])[actualImageLayer]?.time || ""}
                </div>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle dropdown-toggle-split btn-dropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    disabled={print?.id ? !printHasImages[print.id] === false : true}
                  >
                    {itemTypes[imagePType] || `Select the image type`}
                  </button>
                  <div className="dropdown-menu">
                    {Object.keys(imagePTypes).map((imagePType) => (
                      <div
                        key={imagePType}
                        id={imagePType}
                        className="dropdown-item"
                        onClick={() => {
                          changeImagePType(imagePType);
                        }}
                      >
                        <label className="p-14 c-292929">{itemTypes[imagePType] || imagePType}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {currentLayerValue !== currentLayer ? (<LayerPreview layer={currentLayerValue} width={105} height={70} />) :
                (<LayerPreview layer={actualImageLayer} width={105} height={70} />)}
            </div>
          ) : null}

          {imagePType && (
            <div>
              {actualImagesFullScreen && (
                <img
                  src="/img/exit_full_screen.svg"
                  alt="exit full screen"
                  onClick={() => setActualImageFullScreen(false)}
                ></img>
              )}
              {!actualImagesFullScreen && (
                <img
                  alt="full screen"
                  src="/img/full_screen.svg"
                  onClick={() => setActualImageFullScreen(true)}
                ></img>
              )}
            </div>
          )}
        </div>
      </hgroup>
    );
  };

  const LayerPreview = (props) => {
    const key = props.layer < 0 ? 0 : props.layer;
    const layers = imagePTypes[imagePType] || {};
    const path = layers[key] ? layers[key].path : "";
    const width = props.width || 120;
    const height = props.height || 100;
    const [imageUrl, setImageUrl] = useState<any>();
    const [imagePortrait, setImagePortrait] = useState<any>();

    // console.log("Path:", path, "ImageURL: ", imageUrl);
    // console.log("Layers:", layers);
    if (path !== "" && !imageUrl) {
      getUrlForPath(path, true)
        .then((data) => {
          setImageUrl(data);
        })
        .catch((err) => {
          console.error(err);
        });
    }

    let bg = "white";
    let color = "black";
    if (path && path !== "") {
      bg = "darkgrey";
      color = "white";
    }

    const setOnLoad = function(img) {
      if (!img) {
        return;
      }
      img.onload = function() {
        if (this.width < this.height) {
          setImagePortrait(true);
        }
      }
    }

    let imgClass = imagePortrait ? "LayerPreview-image-portrait" : "LayerPreview-image";
    let imgStyle = !imagePortrait ? {"height": height} : {"width": height};
        imgStyle['zIndex'] = '0';
    let divStyle = imagePortrait ? {
      "position": "absolute",
      "bottom": "-"+(height/4)+"px",
      "left": (height/4)+"px"
    } : {};

    return (
      <div style={{position: "relative", width: width+"px", height: height+"px", 
                   background: bg, color: color, fontWeight: "bold"}}>
        <div style={{position: "absolute", zIndex: '1', background: "rgba(1,1,1,0.3)", textAlign: "center",
                     width: "100%", height: "1.5em", margin: "auto auto auto auto"}}><p>Layer #{key}</p></div>
        <div className={imgClass} style={(imagePortrait?divStyle:{}) as React.CSSProperties}>
          {imageUrl!==undefined?(<img ref={setOnLoad} src={imageUrl} style={imgStyle}/>):undefined}
        </div>
      </div>
    )
  }

  const ActualImagesBodyComponent = (props) => {
    return (
      <div
        className="image"
        style={
          actualImagesFullScreen
            ? {
                height: "74vh",
              }
            : {}
        }
      >
        {linkP && layerExists ? <img className="print_review_image" src={linkP} style={{"objectFit": "contain"}}/> : null}
        {(!layerExists && !isPrinterPrintingCurrentPrint ) && (<>
          <p className="no-image-info">Images on layer {layerDetails.startLayer} to {layerDetails.endLayer} are available.</p>
        </>)}
        {(isPrinterPrintingCurrentPrint && !layerExists) && <p className="no-image-info"> Layer {currentLayerValue} has not been printed.</p> }
      </div>
    );
  };

  
  return (
    <>
     <LoadingComponent visible={loading} />
    <div className="printReview" ref={(div) => { div?.focus(); }} tabIndex={-1} onKeyDown={handleKeyDown}>
      {reviewLoading && <LoadingComponent visible={true} />}
      <SideMenuComponent 
      activeBundle={activeBundle}
      percentage={percentage}
      setPercentage={setPercentage}
      setTotalLayer={setTotalLayer}
      selectedBuild={props.location.state.selectedBuild}
      layersCompleted={layersCompleted}
      imageTypes={imageTypes}
      handleClose={handleClose}
      secondsToDhms={secondsToDhms}
      displayPrintFeedbackModal={displayPrintFeedbackModal}
      printerAction={printerAction}
      selectedJob={props.location.state.selectedBuild.currentStatus}
      printOwner={printOwner}
      printStatus={printStatus}
      buildName={buildName}
      // totalTime={totalTime}
      isConfigDisabled={isConfigDisabled}
      gCodeID={gCodeID}
      printID={printID}
      isFeedbackDisabled={isFeedbackDisabled}
      totalLayer={totalLayer}
      displayPrintFeedbackButton={displayPrintFeedbackButton} />
      <div
        className="bp-r"
        style={
          actualImagesFullScreen || slicedImagesFullScreen
            ? {
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                width: "100%",
                margin: 0,
              }
            : {}
        }>
        <h4 className="page-heading">Print Review</h4>
        {PrintReviewHeaderComponent()}
        {PrintReviewBodyComponent()}
      </div>
    </div>
    </>
  );
};
/* section - components - end */

export default withRouter(MVPPrintReview);

