/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateBuild = /* GraphQL */ `
  subscription OnCreateBuild {
    onCreateBuild {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBuild = /* GraphQL */ `
  subscription OnUpdateBuild {
    onUpdateBuild {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBuild = /* GraphQL */ `
  subscription OnDeleteBuild {
    onDeleteBuild {
      id
      name
      hasPlates
      plates
      created_by
      created_at
      current_status
      dumb
      build_result
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      gcodeUUID
      bundle
      build_time
      canceled_by
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      cloudWatchLogName
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      build_json
      machine_info
      result_json
      gcode_uuid
      cloudWatch
      folders
      hasFolders
      tags
      hasTags
      hasParts
      build_messages
      build_search_string
      modified_by
      modified_at
      metrics
      build_complexity_status
      noOfParts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateParts = /* GraphQL */ `
  subscription OnCreateParts {
    onCreateParts {
      id
      name
      creator
      created_at
      modifier
      modified_at
      files
      icon
      dumb
      archive
      folders
      hasFolders
      tags
      hasTags
      hasPlates
      plates
      hasBuilds
      builds
      metrics
      parts_search_string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateParts = /* GraphQL */ `
  subscription OnUpdateParts {
    onUpdateParts {
      id
      name
      creator
      created_at
      modifier
      modified_at
      files
      icon
      dumb
      archive
      folders
      hasFolders
      tags
      hasTags
      hasPlates
      plates
      hasBuilds
      builds
      metrics
      parts_search_string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteParts = /* GraphQL */ `
  subscription OnDeleteParts {
    onDeleteParts {
      id
      name
      creator
      created_at
      modifier
      modified_at
      files
      icon
      dumb
      archive
      folders
      hasFolders
      tags
      hasTags
      hasPlates
      plates
      hasBuilds
      builds
      metrics
      parts_search_string
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateConfigurations = /* GraphQL */ `
  subscription OnCreateConfigurations {
    onCreateConfigurations {
      id
      name
      type
      created_at
      modified_at
      data
      dumb
      default
      hasPlates
      plates
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateConfigurations = /* GraphQL */ `
  subscription OnUpdateConfigurations {
    onUpdateConfigurations {
      id
      name
      type
      created_at
      modified_at
      data
      dumb
      default
      hasPlates
      plates
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteConfigurations = /* GraphQL */ `
  subscription OnDeleteConfigurations {
    onDeleteConfigurations {
      id
      name
      type
      created_at
      modified_at
      data
      dumb
      default
      hasPlates
      plates
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateConfigurationsAllHistory = /* GraphQL */ `
  subscription OnCreateConfigurationsAllHistory {
    onCreateConfigurationsAllHistory {
      id
      ConfigurationGroupId
      name
      type
      created_at
      data
      dumb
      default
      hasPlates
      plates
      eventName
      modified_at
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateConfigurationsAllHistory = /* GraphQL */ `
  subscription OnUpdateConfigurationsAllHistory {
    onUpdateConfigurationsAllHistory {
      id
      ConfigurationGroupId
      name
      type
      created_at
      data
      dumb
      default
      hasPlates
      plates
      eventName
      modified_at
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteConfigurationsAllHistory = /* GraphQL */ `
  subscription OnDeleteConfigurationsAllHistory {
    onDeleteConfigurationsAllHistory {
      id
      ConfigurationGroupId
      name
      type
      created_at
      data
      dumb
      default
      hasPlates
      plates
      eventName
      modified_at
      creator
      modifier
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePrint = /* GraphQL */ `
  subscription OnCreatePrint {
    onCreatePrint {
      id
      bundle_UUID
      start
      end
      visionImages
      thermalImages
      operationTimes
      printerId
      comment
      buildID
      estTotalTime
      totalPercentage
      totalPaste
      printerOwner
      ETA
      numberOfLayers
      layerStatus
      printFeedbackId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePrint = /* GraphQL */ `
  subscription OnUpdatePrint {
    onUpdatePrint {
      id
      bundle_UUID
      start
      end
      visionImages
      thermalImages
      operationTimes
      printerId
      comment
      buildID
      estTotalTime
      totalPercentage
      totalPaste
      printerOwner
      ETA
      numberOfLayers
      layerStatus
      printFeedbackId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePrint = /* GraphQL */ `
  subscription OnDeletePrint {
    onDeletePrint {
      id
      bundle_UUID
      start
      end
      visionImages
      thermalImages
      operationTimes
      printerId
      comment
      buildID
      estTotalTime
      totalPercentage
      totalPaste
      printerOwner
      ETA
      numberOfLayers
      layerStatus
      printFeedbackId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePrinter = /* GraphQL */ `
  subscription OnCreatePrinter {
    onCreatePrinter {
      id
      name
      GrafanaBundle
      currentBundle
      bundlePrintStart
      currentStatus
      lastStatusSync
      currentPrint
      currentLayer
      currentStage
      currentPasteAmount
      printEndTime
      pasteEndTime
      lastSyncTime
      lastVisionPath
      lastThermalPath
      currentPrintNameOnPrinter
      remainingPasteConsumption
      remainingPrintTime
      creator
      created_at
      modifier
      modified_at
      tools
      printerPauseCommandStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePrinter = /* GraphQL */ `
  subscription OnUpdatePrinter {
    onUpdatePrinter {
      id
      name
      GrafanaBundle
      currentBundle
      bundlePrintStart
      currentStatus
      lastStatusSync
      currentPrint
      currentLayer
      currentStage
      currentPasteAmount
      printEndTime
      pasteEndTime
      lastSyncTime
      lastVisionPath
      lastThermalPath
      currentPrintNameOnPrinter
      remainingPasteConsumption
      remainingPrintTime
      creator
      created_at
      modifier
      modified_at
      tools
      printerPauseCommandStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePrinter = /* GraphQL */ `
  subscription OnDeletePrinter {
    onDeletePrinter {
      id
      name
      GrafanaBundle
      currentBundle
      bundlePrintStart
      currentStatus
      lastStatusSync
      currentPrint
      currentLayer
      currentStage
      currentPasteAmount
      printEndTime
      pasteEndTime
      lastSyncTime
      lastVisionPath
      lastThermalPath
      currentPrintNameOnPrinter
      remainingPasteConsumption
      remainingPrintTime
      creator
      created_at
      modifier
      modified_at
      tools
      printerPauseCommandStatus
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateImage = /* GraphQL */ `
  subscription OnCreateImage {
    onCreateImage {
      printerId
      printId
      type
      step
      layer
      path
      time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateImage = /* GraphQL */ `
  subscription OnUpdateImage {
    onUpdateImage {
      printerId
      printId
      type
      step
      layer
      path
      time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteImage = /* GraphQL */ `
  subscription OnDeleteImage {
    onDeleteImage {
      printerId
      printId
      type
      step
      layer
      path
      time
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePlate = /* GraphQL */ `
  subscription OnCreatePlate {
    onCreatePlate {
      id
      name
      created_by
      created_at
      modified_by
      modified_at
      dumb
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      machine_info
      archive
      hasFolders
      folders
      hasTags
      tags
      hasParts
      hasBuilds
      builds
      hasConfigs
      configs
      plate_search_string
      noOfParts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePlate = /* GraphQL */ `
  subscription OnUpdatePlate {
    onUpdatePlate {
      id
      name
      created_by
      created_at
      modified_by
      modified_at
      dumb
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      machine_info
      archive
      hasFolders
      folders
      hasTags
      tags
      hasParts
      hasBuilds
      builds
      hasConfigs
      configs
      plate_search_string
      noOfParts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePlate = /* GraphQL */ `
  subscription OnDeletePlate {
    onDeletePlate {
      id
      name
      created_by
      created_at
      modified_by
      modified_at
      dumb
      machineId
      recipeId
      millConfigId
      materialConfigId
      path
      messages
      print
      moho_version
      marker_name
      Min_Layer
      Max_Layer
      Max_Slab
      Slab_Layers
      Contouring
      Process
      overrides
      machineConfig
      millConfig
      materialConfig
      recipe
      parts
      machine_info
      archive
      hasFolders
      folders
      hasTags
      tags
      hasParts
      hasBuilds
      builds
      hasConfigs
      configs
      plate_search_string
      noOfParts
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePrinterUtilization = /* GraphQL */ `
  subscription OnCreatePrinterUtilization {
    onCreatePrinterUtilization {
      id
      alarmCount
      alarmTotalTime
      runningTotalTime
      stoppedTotalTime
      offlineTime
      startDate
      stopDate
      bucketName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePrinterUtilization = /* GraphQL */ `
  subscription OnUpdatePrinterUtilization {
    onUpdatePrinterUtilization {
      id
      alarmCount
      alarmTotalTime
      runningTotalTime
      stoppedTotalTime
      offlineTime
      startDate
      stopDate
      bucketName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePrinterUtilization = /* GraphQL */ `
  subscription OnDeletePrinterUtilization {
    onDeletePrinterUtilization {
      id
      alarmCount
      alarmTotalTime
      runningTotalTime
      stoppedTotalTime
      offlineTime
      startDate
      stopDate
      bucketName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateFolders = /* GraphQL */ `
  subscription OnCreateFolders {
    onCreateFolders {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      parts
      plates
      builds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateFolders = /* GraphQL */ `
  subscription OnUpdateFolders {
    onUpdateFolders {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      parts
      plates
      builds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteFolders = /* GraphQL */ `
  subscription OnDeleteFolders {
    onDeleteFolders {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      parts
      plates
      builds
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateTags = /* GraphQL */ `
  subscription OnCreateTags {
    onCreateTags {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateTags = /* GraphQL */ `
  subscription OnUpdateTags {
    onUpdateTags {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteTags = /* GraphQL */ `
  subscription OnDeleteTags {
    onDeleteTags {
      id
      name
      hasParts
      noOfParts
      hasPlates
      noOfPlates
      hasBuilds
      noOfBuilds
      creator
      created_at
      modifier
      modified_at
      dumb
      archive
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePrintFeedback = /* GraphQL */ `
  subscription OnCreatePrintFeedback {
    onCreatePrintFeedback {
      id
      Feedback
      printerId
      printId
      bundle_UUID
      buildID
      creator
      created_at
      modifier
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePrintFeedback = /* GraphQL */ `
  subscription OnUpdatePrintFeedback {
    onUpdatePrintFeedback {
      id
      Feedback
      printerId
      printId
      bundle_UUID
      buildID
      creator
      created_at
      modifier
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePrintFeedback = /* GraphQL */ `
  subscription OnDeletePrintFeedback {
    onDeletePrintFeedback {
      id
      Feedback
      printerId
      printId
      bundle_UUID
      buildID
      creator
      created_at
      modifier
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDistribution = /* GraphQL */ `
  subscription OnCreateDistribution {
    onCreateDistribution {
      product_id
      build_id
      commit_url
      deployed_at
      InstallerBucket
      InstallerBucketPath
      mw_expiration_date
      release_note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDistribution = /* GraphQL */ `
  subscription OnUpdateDistribution {
    onUpdateDistribution {
      product_id
      build_id
      commit_url
      deployed_at
      InstallerBucket
      InstallerBucketPath
      mw_expiration_date
      release_note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDistribution = /* GraphQL */ `
  subscription OnDeleteDistribution {
    onDeleteDistribution {
      product_id
      build_id
      commit_url
      deployed_at
      InstallerBucket
      InstallerBucketPath
      mw_expiration_date
      release_note
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEnvironments = /* GraphQL */ `
  subscription OnCreateEnvironments {
    onCreateEnvironments {
      id
      build_id
      commit_url
      product_id
      send_domain
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEnvironments = /* GraphQL */ `
  subscription OnUpdateEnvironments {
    onUpdateEnvironments {
      id
      build_id
      commit_url
      product_id
      send_domain
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEnvironments = /* GraphQL */ `
  subscription OnDeleteEnvironments {
    onDeleteEnvironments {
      id
      build_id
      commit_url
      product_id
      send_domain
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePartsSearch = /* GraphQL */ `
  subscription OnCreatePartsSearch {
    onCreatePartsSearch {
      id
      parts_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePartsSearch = /* GraphQL */ `
  subscription OnUpdatePartsSearch {
    onUpdatePartsSearch {
      id
      parts_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePartsSearch = /* GraphQL */ `
  subscription OnDeletePartsSearch {
    onDeletePartsSearch {
      id
      parts_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePlateSearch = /* GraphQL */ `
  subscription OnCreatePlateSearch {
    onCreatePlateSearch {
      id
      plate_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePlateSearch = /* GraphQL */ `
  subscription OnUpdatePlateSearch {
    onUpdatePlateSearch {
      id
      plate_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePlateSearch = /* GraphQL */ `
  subscription OnDeletePlateSearch {
    onDeletePlateSearch {
      id
      plate_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateBuildSearch = /* GraphQL */ `
  subscription OnCreateBuildSearch {
    onCreateBuildSearch {
      id
      build_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      moho_version
      current_status
      build_result
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateBuildSearch = /* GraphQL */ `
  subscription OnUpdateBuildSearch {
    onUpdateBuildSearch {
      id
      build_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      moho_version
      current_status
      build_result
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteBuildSearch = /* GraphQL */ `
  subscription OnDeleteBuildSearch {
    onDeleteBuildSearch {
      id
      build_search_string
      dumb
      created_by
      created_at
      modified_by
      modified_at
      moho_version
      current_status
      build_result
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePrinterCommand = /* GraphQL */ `
  subscription OnCreatePrinterCommand {
    onCreatePrinterCommand {
      TransactionID
      Acknowledgement
      Command
      DeviceID
      MessageReceivedAt
      RecordCreatedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePrinterCommand = /* GraphQL */ `
  subscription OnUpdatePrinterCommand {
    onUpdatePrinterCommand {
      TransactionID
      Acknowledgement
      Command
      DeviceID
      MessageReceivedAt
      RecordCreatedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePrinterCommand = /* GraphQL */ `
  subscription OnDeletePrinterCommand {
    onDeletePrinterCommand {
      TransactionID
      Acknowledgement
      Command
      DeviceID
      MessageReceivedAt
      RecordCreatedAt
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateHelp = /* GraphQL */ `
  subscription OnCreateHelp {
    onCreateHelp {
      id
      helpDocumentName
      helpDocumentComment
      helpDocumentTypeLink
      created_by
      created_at
      modified_by
      modified_at
      helpDocumentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateHelp = /* GraphQL */ `
  subscription OnUpdateHelp {
    onUpdateHelp {
      id
      helpDocumentName
      helpDocumentComment
      helpDocumentTypeLink
      created_by
      created_at
      modified_by
      modified_at
      helpDocumentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteHelp = /* GraphQL */ `
  subscription OnDeleteHelp {
    onDeleteHelp {
      id
      helpDocumentName
      helpDocumentComment
      helpDocumentTypeLink
      created_by
      created_at
      modified_by
      modified_at
      helpDocumentType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
