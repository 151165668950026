import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	onArchivePart,
	onCopyPartOpenDialog,
	onDeletePart,
	onHighlightPart,
	onMovePartOpenDialog,
	onRenamePartOpenDialog,
	onUnarchivePart,
} from '../../../Actions/Parts';
import { onDownloadPart } from '../../../Actions/Home';
import { IPart, IState } from '../../../Interfaces';
import onGetHighlightedPart from '../../../Actions/Search/highlightPart';
import HorizontalLoader from "../../horizontalLoader";


const Props = (state: IState, ownProps: any) => {
	const isSearchEnabled = state.data.search.isSearchEnabled as boolean;
	const highlightPart = state.data.search.highlightedPartData as IPart;
	const props = {
		isLoadingComplete: state.data.parts.isLoadingComplete as boolean,
		displayPart: ownProps.displayPart,
		isSearchEnabled: isSearchEnabled as boolean,
		highlightPart: highlightPart as IPart,
	};

	return props;
};

const Actions = {
	onHighlightPart: onHighlightPart,
	onRenamePartOpenDialog: onRenamePartOpenDialog,
	onDownloadPart: onDownloadPart,
	onCopyPartOpenDialog: onCopyPartOpenDialog,
	onUnarchivePart: onUnarchivePart,
	onArchivePart: onArchivePart,
	onDeletePart: onDeletePart,
	onMovePartOpenDialog: onMovePartOpenDialog,
	onGetHighlightedPart: onGetHighlightedPart,
};

class Component extends React.Component<any, any> {
	getPart = async (id) => {
		await this.props.onGetHighlightedPart(id);
	};
	public render() {
		let files = this.props?.displayPart?.files || this.props?.highlightPart?.files
		if (files) {
			while ((typeof (files) == 'string') || (files instanceof String)) {
				files = JSON.parse(String(files))
			}
		}

		return (
			<>
				<Dropdown
					onToggle={(isOpen, event) => {
						event.stopPropagation();
						isOpen &&
							(!this.props.isSearchEnabled
								? this.props.onHighlightPart(this.props.displayPart.id)
								: this.props.onGetHighlightedPart(this.props.displayPart.id))

					}}
				>
					<Dropdown.Toggle id='dropdown-basic' variant='light'>
						<div className='options right-justify'>
							<div className='more-check option'>
								<img className='icon more-check' alt='' />
							</div>
						</div>
					</Dropdown.Toggle>
					{!this.props.isSearchEnabled &&(
						<>
						<Dropdown.Menu>
						{/* <Dropdown.Item
							disabled={!this.props.isLoadingComplete}
							onClick={() => this.props.onNavigateToPartReviewPage(this.props.ids, this.props.history, true)}
						>
							Review Part
						</Dropdown.Item> */}
						<Dropdown.Item
							// disabled={!this.props.isLoadingComplete}
							disabled={true}
							onClick={() => this.props.onRenamePartOpenDialog(this.props.displayPart.id)}
						>
							Rename
						</Dropdown.Item>
						{
							files?
							Object.keys(files).map( (key)=>
								<Dropdown.Item key={key}
								onClick={() => this.props.onDownloadPart(this.props.displayPart.id, key)}
							>
								Download {key}
							</Dropdown.Item>):null
						}
						<Dropdown.Item
							disabled={!this.props.isLoadingComplete}
							onClick={() => this.props.onCopyPartOpenDialog(this.props.displayPart.id)}
						>
							Duplicate
						</Dropdown.Item>
						{/* <Dropdown.Item
							disabled={!this.props.displayPart.isArchived}
							onClick={() => this.props.onUnarchivePart(this.props.displayPart.id)}
						>
							Unarchive
						</Dropdown.Item>
						<Dropdown.Item
							disabled={this.props.displayPart.isArchived}
							onClick={() => this.props.onArchivePart(this.props.displayPart.id)}
						>
							Archive
						</Dropdown.Item> */}
						<Dropdown.Item
							disabled={this.props.displayPart.hasPlates || this.props.displayPart.hasBuilds}
							onClick={() => this.props.onDeletePart(this.props.displayPart.id)}
						>
							Delete
						</Dropdown.Item>
						<Dropdown.Item
							onClick={() => this.props.onMovePartOpenDialog(this.props.displayPart.id)}
						>
							Move
						</Dropdown.Item>
					</Dropdown.Menu>
						</>)}
					{this.props.isSearchEnabled &&(
						<>
					<Dropdown.Menu>
						{Object.keys(this.props.highlightPart).length === 0 ? (
							<Dropdown.Item style={{ height: '230px' }}>
								<HorizontalLoader></HorizontalLoader>
							</Dropdown.Item>

						) : (<>
							<Dropdown.Item
								// disabled={!this.props.isLoadingComplete}
								disabled={true}
								onClick={() => this.props.onRenamePartOpenDialog(this.props.displayPart.id)}
							>
								Rename
							</Dropdown.Item>
							<Dropdown.Item
								onClick={() => this.props.onDownloadPart(this.props.displayPart.id)}
							>
								Download
							</Dropdown.Item>
							{
								files ?
									Object.keys(files).map((key) =>
										<Dropdown.Item key={key}
											onClick={() => this.props.onDownloadPart(this.props.displayPart.id, key)}
										>
											Download {key}
										</Dropdown.Item>) : null
							}
							<Dropdown.Item
								disabled={!this.props.isLoadingComplete}
								onClick={() => this.props.onCopyPartOpenDialog(this.props.displayPart.id)}
							>
								Duplicate
							</Dropdown.Item>
							{/* <Dropdown.Item
							disabled={!this.props.displayPart.isArchived}
							onClick={() => this.props.onUnarchivePart(this.props.displayPart.id)}
						>
							Unarchive
						</Dropdown.Item>
						<Dropdown.Item
							disabled={this.props.displayPart.isArchived}
							onClick={() => this.props.onArchivePart(this.props.displayPart.id)}
						>
							Archive
						</Dropdown.Item> */}
							{/* {!this.props.isSearchEnabled && */}
							<Dropdown.Item

								disabled={this.props.displayPart.hasPlates || this.props.displayPart.hasBuilds}

								onClick={() => this.props.onDeletePart(this.props.displayPart.id)}
							>
								Delete
							</Dropdown.Item>
							{/* } */}
							<Dropdown.Item
								onClick={() => this.props.onMovePartOpenDialog(this.props.displayPart.id)}
							>
								Move
							</Dropdown.Item>
						</>)}
					</Dropdown.Menu>
					</>)}
				</Dropdown>
			</>
		);
	}
}

const DisplayPartOptions = withRouter(connect(Props, Actions)(Component));

export default DisplayPartOptions;
