import React from "react";
import { Auth, Storage } from "aws-amplify";
import * as THREE from "three";
import { MeshPhongMaterial, MeshLambertMaterial, TextureLoader } from "three";
import Form from "react-bootstrap/Form";
import { STLLoader } from "three-stl-loader";
import { PLYLoader } from "../loaders/PLYLoader";
import { GCodeLoader } from "../loaders/GCodeLoader";
import JSZip from "jszip";
import { NotificationManager } from "react-notifications";
import "../BundlesPage/S3ObjectTree";
import { TreeNode } from "../BundlesPage/BuildElementControl";
import "./MVPBuildSliceReview.scss";
import SceneSlice from "../SceneSlice";
import { Button, Dropdown, DropdownButton, OverlayTrigger, Tooltip, Popover, Modal } from "react-bootstrap";
import { S3 } from "aws-sdk";
import awsExports from "../aws-exports";
import { getBuildVolumeGridAndVisualizer } from "../utils";
import { materials } from "../loaders/MaterialColors";
import { Checkbox, FormControlLabel, Slider, OutlinedInput, InputLabel, MenuItem, FormControl, ListItemText, Select } from "@material-ui/core";

class IBundlesProps {
  buildData?: any;
  screenChanged?: boolean;
  isLoading: any;
  isOverlaped?: string;
  gcodeSTree: any;
  meshesSTree: any;
  imageBox: any;
  fullScreenChange: any;
  fullScreen: any;
}

class IBundlesState {
  showNonFunctional = true;
  isRoleOperator = false;
  setOpen: any;
  current_bundle: any = {};
  main_log: string = "";
  main_gcode: string = "";
  baseLength?= 0;
  parts: any[] = [];
  gcodeTree: TreeNode = {
    name: "",
    children: [],
    object: undefined,
    mesh: undefined,
    toggled: false,
  };
  operations: TreeNode = { name: "unassigned", children: [], toggled: false };
  levels: TreeNode = { name: "Unassigned", children: [], toggled: false };
  splitFastWork: boolean = false;
  rangeSelectValue = [0, 0];
  rangeSelectFinalValue = [0, 0];
  marks: any = [];
  max = 0;
  showLoading: string[] = ["hideLoading"];
  disabledAction = { disabled: "disabled" };
  isLoading: string = "hide";
  detailedCutList: any[] = [];
  meshValue: any[] = [];
  layerSelected: { name: string; isActive: boolean }[] = [];
  cutLayerValue: string = "None";
  printingImagesChecked?: boolean;
  printingPathChecked?: boolean;
  cutterPathChecked?: boolean;
  checkDryPathChecked?: boolean;
  orthographic?: boolean;
  showGrid?: boolean;
  imageBox: any;
  iscutSimMeshSelected: boolean = false;
  attributeNames: string[] = ["Select Attr"];
  selectedAttributeNames: any[] = [];
  selectedView: string = "";
  isSelected = true;
  warnings:any[]=[];
  isWarningModalVisible? :boolean;
}


class MVPBuildSliceReview extends React.Component<
  IBundlesProps,
  IBundlesState
> {
  stlLoader: STLLoader;
  plyLoader: PLYLoader;
  gcodeLoader: any;

  loading: boolean;
  scene?: SceneSlice;
  // isLoading: string = 'hide';
  sceneContainer: any;
  private basePath: string = "";


  constructor(props) {
    super(props);

    const STLLoader = require("three-stl-loader")(THREE);
    this.stlLoader = new STLLoader();
    this.plyLoader = new PLYLoader();
    this.gcodeLoader = new GCodeLoader();
    this.loading = false;
    // this.isLoading = 'hide';

    this.sceneContainer = React.createRef();
    this.onChangeAttributes = this.onChangeAttributes.bind(this);

    this.state = {
      showNonFunctional: false,
      disabledAction: { disabled: "disabled" },
      showLoading: ["hideLoading"],
      isLoading: "hide",
      main_log: "",
      main_gcode: "",
      current_bundle: {},
      setOpen: false,
      baseLength: 0,
      gcodeTree: { name: "", children: [], object: undefined, mesh: undefined },
      operations: { name: "unassigned" },
      parts: [],
      levels: { name: "unassigned" },
      splitFastWork: false,
      isRoleOperator: false,
      rangeSelectValue: [0, 0],
      rangeSelectFinalValue: [0, 0],
      detailedCutList: [],
      marks: [
        {
          value: 0,
          label: "0",
        },
      ],
      max: 0,
      meshValue: [],
      layerSelected: [],
      cutLayerValue: "None",
      printingImagesChecked: false,
      printingPathChecked: false,
      cutterPathChecked: false,
      checkDryPathChecked: false,
      orthographic: false,
      showGrid: true,
      imageBox: null,
      iscutSimMeshSelected: false,
      attributeNames: ["Additive Sim", "Link", "Additive", "Cut"],
      selectedAttributeNames: [],
      selectedView: "",
      isSelected: true,
      isWarningModalVisible:false,
      warnings: JSON.parse(this.props.buildData.build_messages).warnings !== null ? JSON.parse(this.props.buildData.build_messages).warnings : []
    };
  }
  handleBuildWarningModalShow = () => this.setState({ ...this.state, isWarningModalVisible: true })
  handleBuildWarningModalHide = () => this.setState({ ...this.state, isWarningModalVisible: false })

  onChangeAttributes = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selValue = event.target.value as string[];
    this.setState({ selectedAttributeNames: event.target.value as string[] });
    if (selValue.indexOf(this.state.attributeNames[0]) > -1) {
      this.setState({ printingImagesChecked: true })
    }
    else {
      this.setState({ printingImagesChecked: false })
    }
    if (selValue.indexOf(this.state.attributeNames[1]) > -1) {
      this.setState({ showNonFunctional: true })
    }
    else {
      this.setState({ showNonFunctional: false })
    }
    if (selValue.indexOf(this.state.attributeNames[2]) > -1) {
      this.setState({ printingPathChecked: true })
    }
    else {
      this.setState({ printingPathChecked: false })
    }
    if (selValue.indexOf(this.state.attributeNames[3]) > -1) {
      this.setState({ cutterPathChecked: true })
    }
    else {
      this.setState({ cutterPathChecked: false })
    }

  };

  createTree(parsed, meshes) {
    let levels: TreeNode = {
      name: "levels",
      toggled: true,
      children: [
        {
          name: "Meshes",
          sourceNodes: {
            mesh: meshes,
          },
          children: [],
        },
      ],
      sourceNodes: {
        gcode: parsed,
      },
    };

    let meshLevel = levels.children ? levels.children[0] : null;

    let parts: any[] = [{ name: "None", isActive: false }];
    if (meshLevel) {
      meshLevel.sourceNodes?.mesh?.children?.map((part) => {
        part?.children?.map((mesh_name) => {
          if (mesh_name.name === "Part" || mesh_name.name === "Slabs") return;
          if (!parts.find((val) => val.name === mesh_name.name)) {
            parts.push({ name: mesh_name.name, isActive: false });
          }
        });
      });
    }

    let steps = this.props.gcodeSTree.slabs[0]?.children?.map((value) => {
      const stepName = value.name.split("_")[2];
      return {name: stepName, isActive: false};
    });
    this.setState({
      parts: parts,
      detailedCutList: steps,
      levels: levels,
      max: this.props.gcodeSTree.layers.length - 1,
    });
  }

  async processGcode(parsed) {
    try {
      let recursiveAdder = (node: any) => {
        if (node.object !== undefined) {
          this.scene!.scene.add(node.object);
        }
      };
      recursiveAdder(parsed);

      this.state.disabledAction.disabled = "enable";
    } catch (reason) {
      console.log(reason);
    }
  }

  public processBuild = async () => {
    try {
      const recursiveDeleteSchema = (node: TreeNode) => {
        node.children?.map((childNode) => {
          recursiveDeleteSchema(childNode);
        });
        delete node.children;
        if (node.mesh) {
          this.scene?.scene.remove(node.mesh);
          delete node.mesh;
        }
        if (node.object) {
          this.scene?.scene.remove(node.object);
          delete node.object;
        }
      };

      recursiveDeleteSchema(this.state.gcodeTree);
      recursiveDeleteSchema(this.state.operations);
      recursiveDeleteSchema(this.state.levels);

      this.loading = true;

      const build: any = this.props.buildData;
      if (build) {
        this.setState(
          Object.assign(this.state, {
            gcodeTree: { name: "gcode", toggled: true, loading: true },
          })
        );
        this.basePath = this.props.buildData?.bundle?.slice(7);
        let machineConfig = build.machineConfig?.resultJson
          ? build.machineConfig?.resultJson
          : build.machineConfig;
        let recipe = build.recipe?.resultJson
          ? build.recipe?.resultJson
          : build.recipe;
        const buildVolume = machineConfig?.Build?.BuildVolumes.find(
          (buildVolume) => buildVolume.Name == recipe.Build.BuildPlate
        );

        let minVector = new THREE.Vector3(
          buildVolume?.Size
            ? -(buildVolume.Size.x / 2)
            : buildVolume?.Min
              ? buildVolume?.Min.x
              : -75 / 2,
          buildVolume?.Size
            ? -(buildVolume.Size.y / 2)
            : buildVolume?.Min
              ? buildVolume?.Min.y
              : -75 / 2,
          buildVolume?.Size ? 0 : buildVolume?.Min ? buildVolume?.Min.z : 0
        );
        let maxVector = new THREE.Vector3(
          buildVolume?.Size
            ? buildVolume.Size.x / 2
            : buildVolume?.Max
              ? buildVolume?.Max.x
              : 75 / 2,
          buildVolume?.Size
            ? buildVolume.Size.y / 2
            : buildVolume?.Max
              ? buildVolume?.Max.y
              : 75 / 2,
          buildVolume?.Size
            ? buildVolume.Size.z
            : buildVolume?.Max
              ? buildVolume?.Max.z
              : 100
        );

        const buildAreaVolume = new THREE.Box3(minVector, maxVector);

        getBuildVolumeGridAndVisualizer(this.scene, buildAreaVolume);

        this.scene?.updateBuildPlateSize(buildAreaVolume);
      }
      this.loading = false;
    } catch (err) {
      console.error(err);
      this.loading = false;

      // NotificationManager.error(`error reading bundle: ${err.message}`);
      this.setState({
        ...this.state,
        // loading: false
      });
    }
  };

  public processStateChange(activeN, node) {
    try {
      // ev.currentTarget.checked
      if (node.children || node.object) {
        //if the state not active just turnoff all childs
        const recursiveState = (node, state = false) => {
          if (
            (node.name == "DRY" || node.name == "DRY_PERIMS") &&
            !this.state.checkDryPathChecked
          )
            state = false;
          if (
            (node.name == "DISPENSE" || node.name == "DISPENSE_PERIMS") &&
            !this.state.printingPathChecked
          )
            state = false;
          if (
            (node.name == "CUT" || node.name == "CUT_PERIMS") &&
            !this.state.cutterPathChecked
          )
            state = false;
          if (node.children) {
            node.children?.map((child) => {
              recursiveState(child, state);
            });
          }
          if (node.object) {
            if (state) {
              if (!node.active) {
                node.object.visible = true;
                this.scene?.scene.add(node.object);
              }
            } else {
              if (node.active) {
                let selectedObject = this.scene?.scene.getObjectByName(
                  node.object.name
                );
                this.scene?.scene.remove(selectedObject);
              }
            }
          }
          node.active = state;
        };
        recursiveState(node, activeN);
      }
    } catch (e: any) {
      throw NotificationManager.error(
        `Error updating operation: ${e.toString()}`
      );
    }
    // props.onUpdate();
    this.scene?.render();
  }

  isRoleOperator = false;
  componentDidMount() {
    this.scene = new SceneSlice(this.sceneContainer.current);
    // this.processBuild().then( result => {
    //   console.log("Component mounted")
    // })
    this.processBuild();
    // console.log("~~~props build sliced~~~~", this.props);
    if (this.props.screenChanged) {
      if (this.scene) {
        this.scene.onWindowResize();
      }
    }
    Auth.currentAuthenticatedUser().then((user) => {
      //console.log(user);

      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"];

      //console.log(groups);

      this.isRoleOperator = groups == "Operator";
    });
  }

  componentDidUpdate(prevProps, prevState) {
    console.log("Component updated: ", this.state.detailedCutList);
    if (prevState.selectedView != this.state.selectedView) {
      var elements = document.querySelectorAll('.active-btn');
      elements?.forEach((a) => {
        a.classList.remove("active-btn");
      });
      var element = document.getElementById(this.state.selectedView);
      element?.classList.add("active-btn");
    }

    let needRender = false;

    if (this.props.screenChanged) {
      if (this.scene) {
        this.scene.onWindowResize();
      }
    }

    if (this.state.orthographic !== prevState.orthographic) {
      this.scene?.setCameraMode(this.state.orthographic);
      needRender = true;
    }

    if (this.state.showGrid !== prevState.showGrid) {
      let grid = this.scene?.scene?.getObjectByName("gridVisualizer");
      if (grid) {
        grid.visible = this.state.showGrid;
      }
      needRender = true;
    }

    if (needRender) {
      this.scene?.render();
    }

    if (this.state.printingImagesChecked !== prevState.printingImagesChecked) {
      this.updateLayerPicture();
    }
    if (
      this.props.gcodeSTree &&
      this.props.gcodeSTree.children &&
      this.props.gcodeSTree.children.length > 0
    ) {
      let ll = this.props.gcodeSTree.children.length;
      let l1 = 0;
      if (
        this.state.gcodeTree &&
        this.state.gcodeTree.children &&
        this.state.gcodeTree.children.length > 0
      ) {
        l1 = this.state.gcodeTree.children.length;
      }

      if (this.props.imageBox && this.props.imageBox != this.state.imageBox) {
        this.setState({ imageBox: this.props.imageBox });
      }

      // console.log("~~componentDidUpdate~props build sliced~~if~~");
      if (ll !== l1) {
        this.setState({
          ...this.state,
          gcodeTree: this.props.gcodeSTree,
        });
        this.processGcode(this.props.gcodeSTree);
        this.createTree(this.props.gcodeSTree, this.props.meshesSTree);
      }
    }

    if (this.props.isOverlaped !== "") {
      // ToDo fix with new mesh structure
      // let meshsVal = this.state?.meshesTree?.children;
      // if (meshsVal && meshsVal.length > 0) {
      //   this.handleShowMesh(this.props.isOverlaped);
      // }
    }
  }

  async loadElement(loadRegion) {
    if (!loadRegion.loaded) {
      let s3 = new S3({
        credentials: await Auth.currentCredentials(),
        region: awsExports.aws_user_files_s3_bucket_region,
      });

      const recursiveProcessing = (obj, prefix = "") => {
        obj.children?.map((child) => {
          recursiveProcessing(child, prefix + obj.name);
        });

        if (obj.object) {
          obj.object.name1 = obj.object.name;
          obj.object.name = prefix + obj.name;
          obj.object.visible = true;
        }
      };

      const key = `${this.props.buildData.bundle}${loadRegion.filename}`;
      let block = await s3
        .getObject({
          Bucket: awsExports.aws_user_files_s3_bucket,
          Key: key,
        })
        .promise();
      const parsed = await this.gcodeLoader.parse(block);
      if (loadRegion.name === "CUT_SLAB") {
        parsed.children[0].children.forEach((val) => {
          let relatedChild = loadRegion.children.find(
            (child) => child.name === val.name
          );
          if (relatedChild) {
            relatedChild.object = val.object;
          }
        });
      } else {
        loadRegion.children = parsed.children;
        loadRegion.object = parsed.object;
      }
      recursiveProcessing(loadRegion);
      loadRegion.loaded = true;
    }
  }

  private async downloadMesh(meshName: string) {
    console.log("Downloading mesh", meshName);
    let promises = this.props.meshesSTree?.children?.map(async (part) => {
      let meshFound = part.children.find((mesh) => mesh.name === meshName);
      if (meshFound) {
        var link = document.createElement('a');
        let fileName = meshFound.databasePath.split('/').pop();
        console.log("Mesh found:", meshFound);
        link.download = fileName;
        console.log("Mesh filename:", fileName);
        Storage.get(meshFound.databasePath, { download: true })
          .then(async (result: any) => result.Body.arrayBuffer())
          .then((downloadedBuffer) => {

            let fileExtension = fileName.split(".").pop()?.toLowerCase();
            if (fileExtension === "zip") {
              return JSZip.loadAsync(downloadedBuffer)
                .then((zip: JSZip) => {
                  return Object.values(zip.files)[0].async("arraybuffer");
                });
            }
            return downloadedBuffer;
          })
          .then((buffer) => {
            console.log("Buffer:", buffer);
            var blob = new Blob([buffer], { type: "application/octet-stream" });
            link.href = window.URL.createObjectURL(blob);
            link.click();
          });
      }
    });
    await Promise.all(promises);
  }

  private async downloadMeshIntoNode(databasePath: string, node: any) {
    return Storage.get(databasePath, { download: true })
      .then((result: any) => result.Body.arrayBuffer())
      .then((downloadedBuffer) => {
        let fileExtension = databasePath.split(".").pop()?.toLowerCase();
        if (fileExtension === "zip") {
          return JSZip.loadAsync(downloadedBuffer).then((zip: JSZip) => {
            return Object.values(zip.files)[0].async("arraybuffer");
          });
        }
        return downloadedBuffer;
      })
      .catch((e) => {
        NotificationManager.error(
          `Failed to download from ${databasePath}: ${e}`
        );
      })
      .then((buffer: ArrayBuffer) => {
        //add to scene
        let plyObject = this.plyLoader.parse(buffer);

        if (plyObject.attributes.normals === undefined) {
          plyObject.computeVertexNormals();
        }

        let material: MeshLambertMaterial | MeshPhongMaterial;
        if (plyObject.attributes.color === undefined) {
          material = new MeshPhongMaterial({
            color: 0x007100,
            side: THREE.DoubleSide,
            flatShading: true,
          });
        } else {
          material = new MeshPhongMaterial({
            vertexColors: THREE.VertexColors,
            side: THREE.DoubleSide,
            flatShading: true,
          });
        }
        node.mesh = new THREE.Mesh(plyObject, material);
      })
      .catch(() => {
        NotificationManager.error(`Failed to parse mesh ${databasePath}`);
      });
  }

  onChangeCommittedVal = async (nv) => {
    this.setState({ setOpen: false });
    try {
      if (
        nv[0] !== this.state.rangeSelectFinalValue[0] ||
        nv[1] !== this.state.rangeSelectFinalValue[1]
      ) {
        this.state.showLoading[0] = "showLoading";
        let startRemoveRange = {
          start: this.state.rangeSelectFinalValue[0],
          end: Math.min(this.state.rangeSelectFinalValue[1], nv[0]),
        };
        let endRemoveRange = {
          start: Math.max(this.state.rangeSelectFinalValue[0], nv[1]),
          end: this.state.rangeSelectFinalValue[1],
        };

        console.log(endRemoveRange);

        [startRemoveRange, endRemoveRange].map((range) => {
          for (
            let removeIndex = range.start;
            removeIndex <=
            Math.min(range.end, this.state.gcodeTree["layers"].length - 1);
            removeIndex++
          ) {
            this.processStateChange(
              false,
              this.props.gcodeSTree["layers"][removeIndex]
            );
          }
        });
      }
      let promises: any[] = [];
      for (let loadIndex = nv[0]; loadIndex < nv[1]; loadIndex++) {
        promises.push(
          this.loadAndProcessElement(
            this.props.gcodeSTree.layers[loadIndex],
            this.state.checkDryPathChecked ||
            this.state.printingPathChecked ||
            this.state.cutterPathChecked
          )
        );
      }
      await Promise.all(promises);
      await Promise.all(
        this.props.gcodeSTree["slabs"]?.map(async (slab) => {
          for (let ind = 0; ind < slab.children.length; ind++) {
            try {
              let cutStep = slab.children[ind];
              let cutStepActive = this.state.detailedCutList.reduce(
                (previousValue, currentValue) => {
                  return (
                    previousValue ||
                    (cutStep.name.indexOf(currentValue.name) >= 0 &&
                      currentValue.isActive)
                  );
                },
                false
              );
              let isActive =
                slab.endLayer <= nv[1] + 1 &&
                slab.startLayer >= nv[0] &&
                cutStepActive;
              if (isActive) {
                await this.loadElement(slab);
                this.visibleElement(slab);
              }
              this.processStateChange(isActive, cutStep);
            } catch (e) {
              console.error(e);
            }
          }
        })
      );

      this.state.rangeSelectFinalValue[0] = nv[0];
      this.state.rangeSelectFinalValue[1] = nv[1];
    } catch (reason) {
      console.error(reason);
    } finally {
      this.state.showLoading[0] = "hideLoading";
    }
  };

  private visibleElement(element) {
    const childReverse = (currentObject) => {
      if (currentObject?.object) {
        childReverse(currentObject.object);
      }
      currentObject?.children?.map((child) => {
        childReverse(child);
      });
      const nf = materials["NON_FUNCTIONAL"];
      const jm = materials["JOG_MOVES"];
      if (
        currentObject?.material?.color === nf?.color ||
        currentObject?.material?.color === jm?.color
      ) {
        currentObject.visible = this.state.showNonFunctional;
      }
    };
    childReverse(element);
  }

  private async loadAndProcessElement(element, needLoad) {
    if (needLoad) {
      await this.loadElement(element);
    }
    this.visibleElement(element);
    this.processStateChange(true, element);
  }

  public onChangeVal = async (e, nv) => {
    if (
      nv[0] !== this.state.rangeSelectValue[0] ||
      nv[1] !== this.state.rangeSelectValue[1]
    ) {
      this.setState({
        rangeSelectValue: nv,
      });
    }
  };

  public optionValueChange = (e) => {
    let list : any[] = [];
    if (e === "All") {
        let isChecked =
            this.state.detailedCutList.filter((a) => a.isActive).length !==
            this.state.detailedCutList.length;

        list = this.state.detailedCutList.map(a => ({...a, isActive: isChecked}));
    } else {
        list = this.state.detailedCutList.map(a => (a.name !== e ? a : {...a, isActive: !a.isActive}));
    }

    let isActiveList = list.filter(
      (a) => a.isActive
    ).length;

    let cutLayerVal =
      isActiveList === list.length
        ? "All"
        : isActiveList === 0
          ? "None"
          : `${isActiveList} Selected`;

    this.setState({
      detailedCutList: list,
      cutLayerValue: cutLayerVal,
    });
  };

  public checkIsChecked(e) {
    return e === this.state.cutLayerValue;

    // if (e === "All") {
    //   return this.state.detailedCutList.filter((a) => a.isActive)
    //     .length === this.state.detailedCutList.length;
    // } else {
    //   const selected = this.state.detailedCutList.filter(
    //     (a) => a.name === e
    //   );
    //   if (selected.length > 0) {
    //     return selected[0].isActive;
    //   } else {
    //     return false;
    //   }
    // }
  }

  handleApply = () => {
    this.onChangeCommittedVal(this.state.rangeSelectValue);
  };

  updateShowGrid = (mode) => {
    this.setState({
      showGrid: mode,
    });
  }

  updateOrthoMode = (mode) => {
    this.setState({
      orthographic: mode,
    });
  };

  updateLayerPicture = async () => {
    try {
      if (this.state.printingImagesChecked) {
        const pictureLayer = this.state.rangeSelectValue[0];
        const layer = this.props.gcodeSTree.layers[pictureLayer];
        if (!layer.picture) {
          if (this.props.buildData) {
            const loader = new TextureLoader();
            const key = `${this.props.buildData.bundle.slice(
              "public/".length
            )}Slic3r/layer${pictureLayer + 1}_fixed.png`;
            const result = await Storage.get(key);
            layer.picture = await new Promise((resolve) =>
              loader.load(result, function (tex) {
                resolve(tex);
              })
            );
          }
        }
        if (layer.picture) {
          const material = new THREE.MeshLambertMaterial({
            map: layer.picture,
          });
          let imagesBox = this.props.imageBox;
          let width = imagesBox
            ? imagesBox.maxX - imagesBox.minX
            : layer.picture.image.width;
          let height = imagesBox
            ? imagesBox.maxY - imagesBox.minY
            : layer.picture.image.height;
          const geometry = new THREE.PlaneGeometry(width, height);
          const mesh = new THREE.Mesh(geometry, material);
          let xPos = imagesBox ? (imagesBox.minX + imagesBox.maxX) / 2 : 0;
          let yPos = imagesBox ? (imagesBox.minY + imagesBox.maxY) / 2 : 0;
          mesh.position.set(
            xPos,
            yPos,
            (-0.5 + pictureLayer) *
            (this.props.buildData?.machineConfig?.resultJson?.Dispense
              ?.LayerHeight || 0.1)
          );
          mesh.name = "DISPENSE_IMAGE";
          const oldMesh = this.scene!.scene.getObjectByName("DISPENSE_IMAGE");
          if (oldMesh) {
            this.scene!.scene!.remove(oldMesh);
          }
          this.scene!.scene!.add(mesh);
        }
      } else {
        const oldMesh = this.scene!.scene.getObjectByName("DISPENSE_IMAGE");
        if (oldMesh) {
          this.scene!.scene!.remove(oldMesh);
        }
      }
      this.scene?.render();
    } catch (e) {
      console.error(e);
    }
  };


  render() {
    const { attributeNames, selectedAttributeNames } = this.state;

    //  let prefix = (this.props as any).match.params.buildId + "_" + (this.props as any).match.params.bundleId
    // console.log('~~~this.state ~~~~', this.state);
    return (
      <div>
        <div className="buildViewAction">
          <div className="slider-actions-wrap">
            {!this.props.fullScreen && (
              <h4 className="page-heading">Build Review</h4>
            )}
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {/* <Range allowCross={false}  defaultValue={[0, 0]} min = {0} max = {this.state.max} marks={this.state.marks}  onChange={ (event)=>{
                      this.onChangeVal(event);
                    }} tipFormatter={value => `${value}`}/> */}
              <div className="buildViewActionSlied">
                <Slider
                  value={this.state.rangeSelectValue}
                  //  onChangeCommitted={this.handleClickOpen}
                  onChange={this.onChangeVal}
                  onChangeCommitted={this.updateLayerPicture}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  getAriaValueText={(value) => `${value}`}
                  step={1}
                  marks={this.state.marks}
                  min={0}
                  max={this.state.max}
                  disabled={this.state.disabledAction.disabled === "disabled"}
                />
              </div>
              <Button
                className="btn-build-review-primary"
                onClick={this.handleApply}
                autoFocus
              >
                Apply
              </Button>
              <div className="expand-collapse-btn">
                {!this.props.fullScreen && (
                  <img
                    alt={"full screen"}
                    src="/img/full_screen.svg"
                    onClick={() => {
                      this.props.fullScreenChange("checked");
                    }}
                  />
                )}

                {this.props.fullScreen && (
                  <img
                    src="/img/exit_full_screen.svg"
                    onClick={() => {
                      this.props.fullScreenChange("");
                    }}
                    alt={"exit full screen"}
                  />
                )}
              </div>
            </div>

            <div className="buildViewActionCheck">
              <div className="cut-options">
                <div className="label-text pt-1">Attributes</div>
                <div className="cut-options-val">
                  <FormControl>
                    {/* <InputLabel id="demo-mutiple-checkbox-label">Select the Attributes</InputLabel> */}
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={selectedAttributeNames}
                      onChange={this.onChangeAttributes}
                      input={<OutlinedInput />}
                      renderValue={(selected) => (selected as string[]).join(", ")}
                    >
                      {this.state.attributeNames.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={selectedAttributeNames.indexOf(name) > -1}
                            disabled={
                              this.state.disabledAction.disabled === "disabled"
                            }
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
           
            
                <div className="cut-options">
                  <div className="label-text pt-1">Subtractive</div>
                  <div className="cut-options-val">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={this.state.cutLayerValue}
                    >
                      <Dropdown.Item
                        as="button"
                        eventKey={"All"}
                      >
                        <Form.Check
                          type="checkbox"
                          label={"All"}
                          onChange={() => {
                            this.optionValueChange("All");
                          }}
                          checked={this.checkIsChecked("All")}
                        />
                      </Dropdown.Item>
                      {this.state.detailedCutList?.map((item: any, index) => {
                        const cutIndex = parseInt(item.name)
                        const toolName =
                          this.props.buildData.millConfig.resultJson.Mill
                            ?.MillingSteps[cutIndex]?.ToolName;
                        if (!toolName) return null;
                        const tool =
                          this.props.buildData.millConfig.resultJson.Mill.Tools.find(
                            (val) => {
                              return val.ToolName === toolName;
                            }
                          );
                        const toolNumber = tool.ToolNumber;
                        return (
                          <Dropdown.Item
                            as="button"
                            key={`dropdown_item_${item.name}`}
                            eventKey={item.name}
                          >
                            <Form.Check
                              type="checkbox"
                              style={{
                                color: isNaN(item.name[0])
                                  ? "black"
                                  : isNaN(item.name[1])
                                    ? materials[
                                      "MILL_STEPS" + item.name[0]
                                    ].color.getStyle()
                                    : materials[
                                      "MILL_STEPS" + item.name[0] + item.name[1]
                                    ].color.getStyle(),
                              }}
                              onChange={() => {
                                this.optionValueChange(item.name);
                              }}
                              checked={item.isActive}
                              label={item.name + `(${toolName}#T${toolNumber})`}
                            />
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                </div>
            

             
                <div className="cut-options meshes-dropdown">
                  <div className="label-text pt-1">Meshes</div>
                  <div className="cut-options-val">
                    <DropdownButton
                      id="dropdown-basic-button"
                      title={this.getSelectedMesh()}
                    >
                      {this.state.parts?.map((item: any) => {
                        return (
                          <Dropdown.Item
                            as="button"
                            key={`dropdown_item_${item.name}`}
                            eventKey={item.name}
                            onSelect={(eventKey) => {
                              this.meshValueChange(eventKey);
                              item.name.toLowerCase().substring(0, 6) ===
                                "cutsim"
                                ? this.setState({
                                  ...this.state,
                                  iscutSimMeshSelected: true,
                                })
                                : this.setState({
                                  ...this.state,
                                  iscutSimMeshSelected: false,
                                });
                            }}
                          >
                            {item.name}
                          </Dropdown.Item>
                        );
                      })}
                    </DropdownButton>
                  </div>
                  {(this.getSelectedMesh() !== "None") && (
                    <Button className="cutSim-mesh-btn"
                            title="Download mesh file"
                            onClick={() => this.downloadMesh(this.getSelectedMesh())}
                    >
                      <img src="/img/download.svg" alt="download-icon" />
                    </Button>
                  )}
                </div>
           
              {this.state.iscutSimMeshSelected && (
                <OverlayTrigger
                  trigger={["hover", "focus"]}
                  placement="bottom"
                  overlay={
                    <Popover
                      id="popover-positioned-bottom"
                      className="cutSim-tooltip"
                    >
                      <Popover.Title as="h3">CutSim</Popover.Title>
                      <Popover.Content>
                        <ul>
                          {this.props.buildData?.recipe?.resultJson?.CutDeviationStatistics?.DeviationColoring?.map((entry, i, a) => {
                            return (
                              <li key={i}>
                                <span
                                  style={{ backgroundColor: "rgb(" + entry.Color + ")" }}
                                  className="circle-shape"
                                ></span>
                                Deviation {i < a.length - 1 ? entry.Deviation + "-" + a[i + 1].Deviation : ">= " + entry.Deviation}mm
                              </li>
                            )
                          })}
                        </ul>
                      </Popover.Content>
                    </Popover>
                  }
                >
                  <div className="cut-options">
                    <Button className="cutSim-Legend-btn">Legend</Button>
                  </div>
                </OverlayTrigger>
              )}
            </div>
          </div>
        </div>
        <div className="buildSliceReview">
        
       
         






          <div ref={this.sceneContainer} style={{ flexGrow: 1 }}>
            {
              this.state.warnings.length !== 0 && (
                <>
                  <div className="warning-icon-wrapper" onClick={this.handleBuildWarningModalShow}>
                    <svg style={{ marginLeft: '30px', cursor: 'pointer', width: '40px', height: '40px', alignSelf: 'center', marginTop: '30px' }} xmlns="http://www.w3.org/2000/svg" width="28.832" height="26.135" viewBox="0 0 28.832 26.135">
                      <g id="warning" transform="translate(0 -23.946)">
                        <path id="Path_36041" data-name="Path 36041" d="M201.439,26.125,211.5,43.558a4.35,4.35,0,0,1-3.77,6.523H197.67l-3.481-13.067,3.481-13.067A4.348,4.348,0,0,1,201.439,26.125Z" transform="translate(-183.254 0)" fill="#3b4145" />
                        <path id="Path_36042" data-name="Path 36042" d="M10.646,26.125.581,43.558a4.35,4.35,0,0,0,3.77,6.523H14.416V23.946A4.348,4.348,0,0,0,10.646,26.125Z" transform="translate(0 0)" fill="#3a4145" />
                        <path id="Path_36043" data-name="Path 36043" d="M269.582,73.619,259.517,56.186a2.619,2.619,0,0,0-2.188-1.308l8.608,22.653h1.382a2.61,2.61,0,0,0,2.262-3.912Z" transform="translate(-242.838 -29.19)" fill="#ffd749" />
                        <path id="Path_36044" data-name="Path 36044" d="M54.216,73.589a2.931,2.931,0,0,1,.3,1.3,2.454,2.454,0,0,1-2.252,2.61H33.516a2.61,2.61,0,0,1-2.262-3.912L41.319,56.156a2.622,2.622,0,0,1,2.262-1.309l.075,0a2.238,2.238,0,0,1,1.877,1.308Z" transform="translate(-29.166 -29.161)" fill="#ffd764" />
                        <path id="Path_36045" data-name="Path 36045" d="M256,354.131v2.9a1.45,1.45,0,1,0,0-2.9Z" transform="translate(-241.584 -311.592)" fill="#3b4145" />
                        <path id="Path_36046" data-name="Path 36046" d="M231.7,354.131c.16,0,.29.649.29,1.45s-.13,1.45-.29,1.45a1.45,1.45,0,1,1,0-2.9Z" transform="translate(-217.279 -311.592)" fill="#3a4145" />
                        <path id="Path_36047" data-name="Path 36047" d="M256,132.646v10.732a1.45,1.45,0,0,0,1.45-1.45V134.1A1.45,1.45,0,0,0,256,132.646Z" transform="translate(-241.584 -102.579)" fill="#3b4145" />
                        <path id="Path_36048" data-name="Path 36048" d="M231.7,132.646c.16,0,.29.649.29,1.45v7.832c0,.8-.13,1.45-.29,1.45a1.45,1.45,0,0,1-1.45-1.45V134.1A1.45,1.45,0,0,1,231.7,132.646Z" transform="translate(-217.279 -102.579)" fill="#3a4145" />
                      </g>
                    </svg>
                  </div>

                  <Modal show={this.state.isWarningModalVisible} onHide={this.handleBuildWarningModalHide} >
                    <Modal.Header closeButton>
                      <h5>Warnings</h5>
                    </Modal.Header>
                    <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="warning-container">
                        {this.state.warnings?.length !== 0 && (
                          this.state.warnings?.map((el, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'start' }}>
                              <div style={{ marginRight: '20px' }}>
                                <svg style={{ marginRight: '0px', width: '20px', height: '20px', alignSelf: 'center', marginBottom: '0px' }} xmlns="http://www.w3.org/2000/svg" width="28.832" height="26.135" viewBox="0 0 28.832 26.135">
                                  <g id="warning" transform="translate(0 -23.946)">
                                    <path id="Path_36041" data-name="Path 36041" d="M201.439,26.125,211.5,43.558a4.35,4.35,0,0,1-3.77,6.523H197.67l-3.481-13.067,3.481-13.067A4.348,4.348,0,0,1,201.439,26.125Z" transform="translate(-183.254 0)" fill="#3b4145" />
                                    <path id="Path_36042" data-name="Path 36042" d="M10.646,26.125.581,43.558a4.35,4.35,0,0,0,3.77,6.523H14.416V23.946A4.348,4.348,0,0,0,10.646,26.125Z" transform="translate(0 0)" fill="#3a4145" />
                                    <path id="Path_36043" data-name="Path 36043" d="M269.582,73.619,259.517,56.186a2.619,2.619,0,0,0-2.188-1.308l8.608,22.653h1.382a2.61,2.61,0,0,0,2.262-3.912Z" transform="translate(-242.838 -29.19)" fill="#ffd749" />
                                    <path id="Path_36044" data-name="Path 36044" d="M54.216,73.589a2.931,2.931,0,0,1,.3,1.3,2.454,2.454,0,0,1-2.252,2.61H33.516a2.61,2.61,0,0,1-2.262-3.912L41.319,56.156a2.622,2.622,0,0,1,2.262-1.309l.075,0a2.238,2.238,0,0,1,1.877,1.308Z" transform="translate(-29.166 -29.161)" fill="#ffd764" />
                                    <path id="Path_36045" data-name="Path 36045" d="M256,354.131v2.9a1.45,1.45,0,1,0,0-2.9Z" transform="translate(-241.584 -311.592)" fill="#3b4145" />
                                    <path id="Path_36046" data-name="Path 36046" d="M231.7,354.131c.16,0,.29.649.29,1.45s-.13,1.45-.29,1.45a1.45,1.45,0,1,1,0-2.9Z" transform="translate(-217.279 -311.592)" fill="#3a4145" />
                                    <path id="Path_36047" data-name="Path 36047" d="M256,132.646v10.732a1.45,1.45,0,0,0,1.45-1.45V134.1A1.45,1.45,0,0,0,256,132.646Z" transform="translate(-241.584 -102.579)" fill="#3b4145" />
                                    <path id="Path_36048" data-name="Path 36048" d="M231.7,132.646c.16,0,.29.649.29,1.45v7.832c0,.8-.13,1.45-.29,1.45a1.45,1.45,0,0,1-1.45-1.45V134.1A1.45,1.45,0,0,1,231.7,132.646Z" transform="translate(-217.279 -102.579)" fill="#3a4145" />
                                  </g>
                                </svg>
                              </div>
                              <p> {el.slice(el.search('[warning]') + 8)}</p>
                            </div>
                          ))
                        )}
                      </div>
                    </Modal.Body>

                  </Modal>
                </>



              )
            }
            <div className="buildSliceSceneControls">
              <div className="build-review-reduced-margin">
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => {
                        this.updateOrthoMode(event.target.checked);
                      }}
                      color="primary"
                      checked={this.state.orthographic}
                    />
                  }
                  label={
                    <span className="build-review-scene-controls">
                      Ortho
                    </span>
                  }
                />
              </div>
             
              <div>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip className="overlay-text" id="workspace view">
                      TOP VIEW
                    </Tooltip>
                  }
                >
                  <div className={`icon top-view active-btn`} id="topview"

                    onClick={() => {
                      this.scene?.switchViewDirection(new THREE.Vector3(0, 0, 1))
                      this.setState({	
                        ...this.state,	
                        isSelected: true,	
                        selectedView: "topview",	
                      })
                    }}>
                  </div>
                </OverlayTrigger>
              </div>
              <div>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip className="overlay-text" id="workspace view">
                      LEFT VIEW
                    </Tooltip>
                  }
                >
                  <div className={`icon left-view`} id="leftview" onClick={() => {
                    this.scene?.switchViewDirection(new THREE.Vector3(-1, 0, 0))
                    this.setState({	
                      selectedView: "leftview"	
                    })
                  }}>
                  </div>
                </OverlayTrigger>
              </div>
              <div>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip className="overlay-text" id="workspace view">
                      FRONT VIEW
                    </Tooltip>
                  }
                >
                  <div className={`icon front-view`} id="frontview" onClick={() => {
                    this.scene?.switchViewDirection(new THREE.Vector3(0, -1, 0));
                    this.setState({	
                      selectedView: "frontview"	
                    })
                  }}>
                  </div>
                </OverlayTrigger>
              </div>
            
              <div>
                <OverlayTrigger
                  placement="left"
                  overlay={
                    <Tooltip className="overlay-text" id="workspace view">
                      SHOW GRID
                    </Tooltip>
                  }
                >
                  {/* <div className={`icon grid-view ${this.state.showGrid ? 'active-btn' : ''}`}> */}
                  <div className={`icon grid-view`} id="gridview">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(event) => {
                            this.updateShowGrid(event.target.checked);
                            this.setState({	
                              selectedView: "gridview"	
                            })
                          }}
                          color="primary"
                          checked={this.state.showGrid}
                        />
                      }
                      label=""
                    />
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  getSelectedMesh(): string {
    const foundElement = this.state.parts.find((value) => value.isActive);
    return foundElement?.isActive ? foundElement.name : "None";
  }

  private async meshValueChange(eventKey: string | null) {
    for (const value of this.state.parts) {
      const flag = value.name === eventKey;
      if (value.name === "None") {
        value.isActive = flag;
        continue;
      }
      if (flag !== value.isActive) {
        await Promise.all(
          this.props.meshesSTree?.children?.map(async (part) => {
            let meshFound = part.children.find(
              (mesh) => mesh.name === value.name
            );
            if (meshFound) {
              const meshName = part.name + meshFound.name;
              if (flag) {
                if (!meshFound.mesh) {
                  await this.downloadMeshIntoNode(
                    meshFound.databasePath,
                    meshFound
                  );
                  meshFound.mesh.name = meshName;
                }
                if (meshFound.mesh) {
                  this.scene!.scene.add(meshFound.mesh);
                }
              } else {
                if (meshFound.mesh) {
                  const mesh = this.scene!.scene.getObjectByName(
                    meshFound.mesh.name
                  );
                  this.scene!.scene.remove(mesh);
                }
              }
            }
          })
        );
        value.isActive = flag;
      }
    }
    this.setState({ parts: this.state.parts });
    this.scene!.render();
  }
}

export default MVPBuildSliceReview;
