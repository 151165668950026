import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import IPartInfo from '../../../../interfaces/IPartInfo';
import { onNavigateToPlatesPage } from '../../../Actions/Home';
import { onHighlightPlate, onRenamePlateOpenDialog, onCopyPlateOpenDialog, onUnarchivePlate, onArchivePlate, onDeletePlate, onMovePlateOpenDialog } from '../../../Actions/Plates';
import { IState, IDisplayPlate, IPlate } from '../../../Interfaces';
import onGetHighlightedPlate from '../../../Actions/Search/highlightPlate';
import HorizontalLoader from '../../horizontalLoader';

const Props = (state: IState, ownProps: any) => {
    const isSearchEnabled = state.data.search.isSearchEnabled as boolean;
    const highlightPlate = state.data.search.highlightedPlateData as IPlate;

    const props = {
        isLoadingComplete: state.data.plates.isLoadingComplete as boolean,
        loadedPlates: state.data.plates.loadedPlates as IPlate[],
        displayPlate: ownProps.displayPlate as IDisplayPlate,
        isSearchEnabled: isSearchEnabled as boolean,
        highlightPlate: highlightPlate as IPlate,
    };

    return props;
};

const Actions = {
    onHighlightPlate: onHighlightPlate,
    onNavigateToPlatesPage: onNavigateToPlatesPage,
    onRenamePlateOpenDialog: onRenamePlateOpenDialog,
    onCopyPlateOpenDialog: onCopyPlateOpenDialog,
    onUnarchivePlate: onUnarchivePlate,
    onArchivePlate: onArchivePlate,
    onDeletePlate: onDeletePlate,
    onMovePlateOpenDialog: onMovePlateOpenDialog,
    onGetHighlightedPlate: onGetHighlightedPlate,
};

class Component extends React.Component<any, any> {
    getPlate = async (id) => {
        await this.props.onGetHighlightedPlate(id);
    };

    onNavigateToPlatesPage = (plateId) => {

        this.props.onHighlightPlate(plateId);

        if (!this.props.isSearchEnabled) {
            const plateToBeEdited = this.props.loadedPlates.find(lp => lp.id === plateId) as IPlate;

            const partIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartID).filter(Boolean) as string[];
            const parts = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean) as IPartInfo[];
            const partConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartConfig['originalJson']['id']).filter(Boolean);
            const partSlicerConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.SlicerConfig['originalJson']['id']).filter(Boolean);
            const plateConfigurationIds = [plateToBeEdited.machineId, plateToBeEdited.millConfigId, plateToBeEdited.materialConfigId, plateToBeEdited.recipeId];

            const ids = {
                partIds,
                parts,
                partConfigurationIds,
                partSlicerConfigurationIds,
                plateConfigurationIds,
            };

            this.props.onNavigateToPlatesPage(ids, this.props.history, false);

        }
        if (this.props.isSearchEnabled) {
            const plateToBeEdited = this.props.highlightPlate as IPlate;

            const partIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartID).filter(Boolean) as string[];
            const parts = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean) as IPartInfo[];
            const partConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartConfig['originalJson']['id']).filter(Boolean);
            const partSlicerConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.SlicerConfig['originalJson']['id']).filter(Boolean);
            const plateConfigurationIds = [plateToBeEdited.machineId, plateToBeEdited.millConfigId, plateToBeEdited.materialConfigId, plateToBeEdited.recipeId];

            const ids = {
                partIds,
                parts,
                partConfigurationIds,
                partSlicerConfigurationIds,
                plateConfigurationIds,
            };

            this.props.onNavigateToPlatesPage(ids, this.props.history, false);

        }

    }

    public render() {
        return (
            <>
                <Dropdown
                    onToggle={(open) => open &&
                        (!this.props.isSearchEnabled
                            ? this.props.onHighlightPlate(this.props.displayPlate.id)
                            : this.props.onGetHighlightedPlate(this.props.displayPlate.id))
                    }>

                    <Dropdown.Toggle
                        id="dropdown-basic"
                        variant="light">
                        <div className='options right-justify'>
                            <div className='more-check option'>
                                <img className='icon more-check'
                                    alt='' />
                            </div>
                        </div>
                    </Dropdown.Toggle>

                    {!this.props.isSearchEnabled &&
                        (<Dropdown.Menu>
                            <Dropdown.Item
                                onClick={() => this.onNavigateToPlatesPage(this.props.displayPlate.id)}>
                                Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                                // disabled={!this.props.isLoadingComplete}
                                disabled={true}
                                onClick={() => this.props.onRenamePlateOpenDialog(this.props.displayPlate.id)}>
                                Rename
                            </Dropdown.Item>
                            <Dropdown.Item
                                disabled={!this.props.isLoadingComplete}
                                onClick={() => this.props.onCopyPlateOpenDialog(this.props.displayPlate.id)}>
                                Duplicate
                            </Dropdown.Item>
                            {/* <Dropdown.Item
                            disabled={!this.props.displayPlate.isArchived}
                            onClick={() => this.props.onUnarchivePlate(this.props.displayPlate.id)}>
                            Unarchive
                        </Dropdown.Item>
                        <Dropdown.Item
                            disabled={this.props.displayPlate.isArchived}
                            onClick={() => this.props.onArchivePlate(this.props.displayPlate.id)}>
                            Archive
                        </Dropdown.Item> */}
                            <Dropdown.Item
                                disabled={this.props.displayPlate?.builds?.length !== 0 &&
                                    this.props.displayPlate?.builds !== null &&
                                    this.props.displayPlate?.builds !== undefined}
                                onClick={() => this.props.onDeletePlate(this.props.displayPlate.id)}>
                                Delete
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={() => this.props.onMovePlateOpenDialog(this.props.displayPlate.id)}>
                                Move
                            </Dropdown.Item>
                        </Dropdown.Menu>)}



                    {this.props.isSearchEnabled &&
                        (<Dropdown.Menu>
                            {Object.keys(this.props.highlightPlate).length === 0 ? (
                                <Dropdown.Item style={{ height: '200px' }}>
                                    <HorizontalLoader></HorizontalLoader>
                                </Dropdown.Item>

                            ) : (<>
                                <Dropdown.Item
                                    onClick={() => this.onNavigateToPlatesPage(this.props.displayPlate.id)}>
                                    Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                    // disabled={!this.props.isLoadingComplete}
                                    disabled={true}
                                    onClick={() => this.props.onRenamePlateOpenDialog(this.props.displayPlate.id)}>
                                    Rename
                                </Dropdown.Item>
                                <Dropdown.Item
                                    disabled={!this.props.isLoadingComplete}
                                    onClick={() => this.props.onCopyPlateOpenDialog(this.props.displayPlate.id)}>
                                    Duplicate
                                </Dropdown.Item>
                                {/* <Dropdown.Item
                            disabled={!this.props.displayPlate.isArchived}
                            onClick={() => this.props.onUnarchivePlate(this.props.displayPlate.id)}>
                            Unarchive
                        </Dropdown.Item>
                        <Dropdown.Item
                            disabled={this.props.displayPlate.isArchived}
                            onClick={() => this.props.onArchivePlate(this.props.displayPlate.id)}>
                            Archive
                        </Dropdown.Item> */}
                                <Dropdown.Item
                                    disabled={this.props.displayPlate?.builds?.length !== 0 &&
                                        this.props.displayPlate?.builds !== null &&
                                        this.props.displayPlate?.builds !== undefined}
                                    onClick={() => this.props.onDeletePlate(this.props.displayPlate.id, this.props.history)}>
                                    Delete {this.props.displayPlate?.builds?.length}
                                </Dropdown.Item>
                                <Dropdown.Item
                                    onClick={() => this.props.onMovePlateOpenDialog(this.props.displayPlate.id)}>
                                    Move
                                </Dropdown.Item>
                            </>)}


                        </Dropdown.Menu>)}


                </Dropdown>
            </>
        );
    }
}

const DisplayPlateOptions = withRouter(connect(Props, Actions)(Component));

export default DisplayPlateOptions;