import { TabIndex, SortDirection, EditPlatePhase, ReviewPageTabs, HelpPageTabs } from './Enums';
import getDefaultWorkspaceState from './Instances/getDefaultWorkspaceState';
import { IAddConfigurationModalStatus, IAddPartModalStatus, IAllBuilds, IAllParts, IAllPlates, IArchivedBuilds, IArchivedParts, IArchivedPlates, IBuild, IConfiguration, ICopyConfigurationModalStatus, ICopyPartModalStatus, ICopyPlateModalStatus, IFolder, IMoveBuildModalStatus, IMovePartModalStatus, IMovePlateModalStatus, INewBuildModalStatus, INewFolderModalStatus, INewPlateModalStatus, INewTagModalStatus, IPart, IPlate, IRecentBuilds, IRecentParts, IRecentPlates, IRenameConfigurationModalStatus, IRenameFolderModalStatus, IRenamePartModalStatus, IRenamePlateModalStatus, ISelectedParts, IState, ITag, ISelectedPlates, IDocument, IAddDocumentModalStatus } from './Interfaces';


const initialState: IState = {
    data: {
        buildByCreatedAt: {
            nextToken: [] as IBuild[],
            loadedBuilds: [] as IBuild[],
            items: [] as IBuild[],
        },
        folders: {
            loadedFolders: [] as IFolder[],
            highlightedFolderId: '' as string,
            selectedFolderIds: [] as string[],
            isLoadingStarted: false as boolean,
            isLoadingInProgress: false as boolean,
            isLoadingComplete: false as boolean,
            searchText: '' as string,
            sortDirection: SortDirection.DESC as SortDirection,
        },
        tags: {
            loadedTags: [] as ITag[],
            highlightedTagId: '' as string,
            selectedTagIds: [] as string[],
            isLoadingStarted: false as boolean,
            isLoadingInProgress: false as boolean,
            isLoadingComplete: false as boolean,
            searchText: '' as string,
            sortDirection: SortDirection.DESC as SortDirection,
        },
        parts: {
            loadedParts: [] as IPart[],
            highlightedPartId: '' as string,
            selectedPartIds: [] as string[],
            selectedParts: [] as IPart[],
            isLoadingStarted: false as boolean,
            isLoadingInProgress: false as boolean,
            isLoadingComplete: false as boolean,
            searchText: '' as string,
            nextToken: undefined,
            sortDirection: SortDirection.DESC as SortDirection,
        },
        plates: {
            loadedPlates: [] as IPlate[],
            highlightedPlateId: '' as string,
            highlightedPlate: {} as IPlate,
            selectedPlateIds: [] as string[],
            selectedPlates: [] as IPlate[],
            isLoadingStarted: false as boolean,
            isLoadingInProgress: false as boolean,
            isLoadingComplete: false as boolean,
            searchText: '' as string,
            nextToken: undefined,
            sortDirection: SortDirection.DESC as SortDirection,
            partsScaledVolume: 0 as number,
            partsScaledArea:0 as number
        },
        builds: {
            loadedBuilds: [] as IBuild[],
            highlightedBuildId: '' as string,
            selectedBuildIds: [] as string[],
            isLoadingStarted: false as boolean,
            isLoadingInProgress: false as boolean,
            isLoadingComplete: false as boolean,
            searchText: '' as string,
            nextToken: undefined,
            sortDirection: SortDirection.DESC as SortDirection,
            selectedBuildStatus: '' as string,
            buildByCurrentStatus: '' as string,
            buildByName: '' as string,
        },
        documents: {
            loadedDocuments: [] as IDocument[],
        },
        configurations: {
            loadedConfigurations: [] as IConfiguration[],
            deletedConfigurations:[] as IConfiguration[],
            highlightedConfigurationId: '' as string,
            selectedConfigurationIds: [] as string[],
            isLoadingStarted: false as boolean,
            isLoadingInProgress: false as boolean,
            isLoadingComplete: false as boolean,
            sortDirection: SortDirection.DESC as SortDirection,
            highlightedConfigurationType: '' as string,
            highlightedConfigurationData: '' as string,
        },
        modals: {
            addConfigurationModalStatus: {} as IAddConfigurationModalStatus,
            addPartModalStatus: {} as IAddPartModalStatus,
            addDocumentModalStatus: {} as IAddDocumentModalStatus,
            copyConfigurationModalStatus: {} as ICopyConfigurationModalStatus,
            copyPartModalStatus: {} as ICopyPartModalStatus,
            copyPlateModalStatus: {} as ICopyPlateModalStatus, 
            newBuildModalStatus: {} as INewBuildModalStatus,
            newFolderModalStatus: {} as INewFolderModalStatus,
            newPlateModalStatus: {} as INewPlateModalStatus,
            newTagModalStatus: {} as INewTagModalStatus,
            renameConfigurationModalStatus: {} as IRenameConfigurationModalStatus,
            renameFolderModalStatus: {} as IRenameFolderModalStatus,
            renamePartModalStatus: {} as IRenamePartModalStatus,
            renamePlateModalStatus: {} as IRenamePlateModalStatus,
            moveBuildModalStatus: {} as IMoveBuildModalStatus,
            movePartModalStatus: {} as IMovePartModalStatus,
            movePlateModalStatus: {} as IMovePlateModalStatus,
            newBuildSuccessModalStatus: false as boolean,
        },
        workspace: {
            state: getDefaultWorkspaceState() as any,
            isNewPlate: true as boolean,
            isEditPlate: false as boolean,
            isLoadingStarted: false as boolean,
            isLoadingInProgress: false as boolean,
            isLoadingComplete: false as boolean,
            addedParts: [] as IPart[],
            isAllAddedPartsSelected: false as boolean,
            addedPartsSelectedPartIndices: [] as number[],
            addedPartsSelectedPartConfigurations: [] as IConfiguration[],
            addedPartsSelectedPartSlicerConfigurations: [] as IConfiguration[],
            selectedConfigurations: [] as IConfiguration[],
            isReviewPart: false as boolean,
        },
        info: {
            showFolderView: true as boolean,
            showListView: false as boolean,
            collapseAll: true as boolean,
            expandAll: false as boolean,
            homePageTabIndex: TabIndex.ALL,
            editPlatePageTabIndex: EditPlatePhase.PARTS,
            reviewPageTabIndex: ReviewPageTabs.PARTS,
            helpPageTabIndex: HelpPageTabs.SOFTWARENOTES,
        },
        sections: {
            selectedParts: {} as ISelectedParts,
            selectedPlates: {} as ISelectedPlates,
            recentParts: {} as IRecentParts,
            allParts: {} as IAllParts,
            archivedParts: {} as IArchivedParts,
            recentPlates: {} as IRecentPlates,
            allPlates: {} as IAllPlates,
            archivedPlates: {} as IArchivedPlates,
            recentBuilds: {} as IRecentBuilds,
            allBuilds: {} as IAllBuilds,
            archivedBuilds: {} as IArchivedBuilds,
        },
        search: {
            searchString: '' as string,
            searchCategory:''as string,
            searchPartsResults: [] as {}[],
            searchPlatesResults: [] as {}[],
            searchBuildsResults: [] as {}[],
            partsSearchCompleted: false as boolean,
            platesSearchCompleted: false as boolean,
            buildsSearchCompleted: false as boolean,
            isSearchEnabled :false as boolean,
            highlightedBuildData :{} as IBuild,
            highlightBuildStatus: '' as string,
            highlightedPlateData: {} as IPlate,
            highlightPlateStatus: '' as string,
            highlightedPartData: {} as IPart,
            highlightPartStatus: '' as string,
            displayLoader: false as boolean,
            highlightedPlateId: '' as string,
        },
        configurationTypes: [
            'printConfig',
            'millConfig',
            'recipe',
            'material',
            'partConfig',
            'partSlicerConfig',
        ] as string[],
    },
    totalDownloadRequested: 0,
    totalDownloadCompleted: 0,
    downloadInProgressContentLength: 0,
    downloadInProgressReceivedLength: 0,
    creator: undefined,
    subscriptions: {
        buildUpdate: undefined,
        buildCreate: undefined,
        buildDelete: undefined,
        plateUpdate: undefined,
        plateCreate: undefined,
        plateDelete: undefined,
        partCreate: undefined,
        partUpdate: undefined,
        printerCreate: undefined,
        printerUpdate: undefined
    }
};

export default initialState;
