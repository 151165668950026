import { SortDirection } from '../../../Enums';
import { IState, IPlate } from '../../../Interfaces';

const onLoadPlatesStartedReducer = (state: IState, action) => {

    const newState = { ...state } as IState;
    newState.data.plates = {
        ...newState.data.plates,
        highlightedPlateId: '' as string,
        selectedPlateIds: [] as string[],
        isLoadingStarted: true as boolean,
        isLoadingInProgress: false as boolean,
        isLoadingComplete: false as boolean,
    };

    return newState;
};

export default onLoadPlatesStartedReducer;
