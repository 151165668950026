import React from "react";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  onAddConfigurationCloseDialog,
  onAddConfigurationDataChange,
  onAddConfigurationNameChange,
  onAddConfigurationTypeChange,
  onAddConfiguration,
} from "../Actions/Configurations";
import { IState, IAddConfigurationModalStatus } from "../Interfaces";
import JSONEditor from "./JSONEditor";

const Props = (state: IState, ownProps: any) => {
  const configurationTypes = state.data.configurationTypes as string[];
  const addConfigurationModalStatus = state.data.modals
    .addConfigurationModalStatus as IAddConfigurationModalStatus;

  const props = {
    configurationTypes: configurationTypes,
    isModalOpen: addConfigurationModalStatus.isOpen,
    inputText: addConfigurationModalStatus.inputText,
    configurationType: addConfigurationModalStatus.configurationType,
    data: addConfigurationModalStatus.data,
    hasError: addConfigurationModalStatus.hasError,
    errorMessage: addConfigurationModalStatus.errorMessage,
    isValid: addConfigurationModalStatus.isValid,
  };

  return props;
};

const Actions = {
  onAddConfigurationCloseDialog: onAddConfigurationCloseDialog,
  onAddConfigurationDataChange: onAddConfigurationDataChange,
  onAddConfigurationNameChange: onAddConfigurationNameChange,
  onAddConfigurationTypeChange: onAddConfigurationTypeChange,
  onAddConfiguration: onAddConfiguration,
};

class Component extends React.Component<any, any> {
  private onAddConfigurationNameChange(event) {
    const value = event.target.value.split(/[^a-zA-Z0-9._-]/).join("");
    this.props.onAddConfigurationNameChange(value);
  }

  public render() {
    console.log("DELETE" + JSON.stringify(this.props, null, 4));
    return (
      <>
        {this.props.isModalOpen ? (
          <>
            <Modal
              className="add-new-mp"
              show={this.props.isModalOpen}
              onHide={() => this.props.onAddConfigurationCloseDialog()}
              backdrop="static"
              keyboard={false}
              dialogClassName="configurationsDialog"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header>
                <Modal.Title>New Configuration</Modal.Title>
                <span className="close-btn"
                onClick={() =>
                  this.props.onAddConfigurationCloseDialog()
                }>
              </span>
              </Modal.Header>
              <Modal.Body>
                <div className="mp">
                  <div className="mp-l">
                    <label className="p-14 c-292929">
                      Enter the name for new Configuration
                    </label>
                    <input
                      className="btstrp form-control"
                      aria-describedby="newConfigurationName"
                      type="text"
                      placeholder="-- new configuration name --"
                      value={this.props.inputText}
                      onChange={(event) =>
                        this.onAddConfigurationNameChange(event)
                      }
                    />
                    <div className="hint-msg">
                      Name can contain only a-z, A-Z, 0-9, ._-
                    </div>
                    <div className="bp-l-b-e btstrp dropdown">
                      <div className="bp-l-b-e-h">
                        <label className="p-14 c-292929">
                          Configuration Type
                        </label>
                      </div>
                      <div className="btn-group bp-l-b-e-dd">
                        <button
                          className="btn btn-secondary"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {this.props.configurationType ||
                            `Select the config type`}
                        </button>
                        <button
                          className="btn btn-secondary dropdown-toggle dropdown-toggle-split"
                          type="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        />
                        <div className="dropdown-menu">
                          {this.props.configurationTypes.map(
                            (configurationType) => (
                              <div
                                id={configurationType}
                                className="dropdown-item"
                                onClick={() => {
                                  this.props.onAddConfigurationTypeChange(
                                    configurationType
                                  );
                                }}
                              >
                                <label className="p-14 c-292929">
                                  {configurationType}
                                </label>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className="invalid-feedback d-block"
                      hidden={!this.props.hasError}
                    >
                      {this.props.errorMessage}
                    </div>
                  </div>
                  <div className="mp-r">
                    <label className="p-14 c-292929">Configuration</label>
                    <JSONEditor
                      value={this.props.data}
                      onChange={(data) =>
                        this.props.onAddConfigurationDataChange(data)
                      }
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                <Button
                  className='btn-primary'
                  onClick={() => this.props.onAddConfiguration()}
                  autoFocus
                >
                  Save
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        ) : null}
      </>
    );
  }
}

const AddConfiguration = withRouter(connect(Props, Actions)(Component));

export default AddConfiguration;
