import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayPartCardView, DisplayPlateCardView, DisplayFolder, DisplayPartListView } from '../..';

import { IState } from '../../../Interfaces';
import React from 'react';
import DisplayDashboardPartCardView from './DisplayDashboardPartCardView';
import DisplayDashboardFolder from '../Common/DisplayDashboardFolder';


const Props = (state: IState, ownProps: any) => {

    const hasDisplayItems =
        Boolean(ownProps.displayFolders && ownProps.displayFolders.length > 0) ||
        Boolean(ownProps.displayParts && ownProps.displayParts.length > 0);

    const props = {
        hasDisplayItems: hasDisplayItems as boolean,
        isLoadingComplete: state.data.parts.isLoadingComplete as boolean,
        label: ownProps.label,
        displayFolders: ownProps.displayFolders,
        displayParts: ownProps.displayParts,
        showOptions: ownProps.showOptions,
        showItems: ownProps.showItems,
        onShowItems: ownProps.onShowItems,
        onHideItems: ownProps.onHideItems,
        isSelected: ownProps.isSelected,
        onUnselect: ownProps.onUnselect,
        onSelect: ownProps.onSelect,
        hasPlates: ownProps.hasPlates,
        hasBuilds: ownProps.hasBuilds,
        displayPlates: ownProps.displayPlates,
        showListView: state.data.info.showListView as boolean,
        searchText: state.data.parts.searchText as string,
    };

    return props;
};

const Actions = {};

class Component extends React.Component<any, any> {

    public render() {
        return (
            <>
                <>
                    {
                        this.props.hasDisplayItems ?
                            (
                                <>
                                    <dl className={`${this.props.showListView ? "grid-view" : ""}`}>

                                        <>
                                            {
                                                this.props.showItems ?
                                                    (
                                                        <dd>
                                                            {
                                                                <>
                                                                    {/* {
                                                                        this.props.displayFolders.map((df, i) => (
                                                                            <DisplayFolder
                                                                                key={i}
                                                                                displayFolder={df}
                                                                                showParts={true}
                                                                                showPlates={false}
                                                                                showBuilds={false}
                                                                            />
                                                                        ))
                                                                    } */}
                                                                </>
                                                            }
                                                        </dd>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                        </>
                                        <>
                                            {
                                                <dt>
                                                    <div className='options left-justify'>
                                                        <div
                                                            className={`${this.props.showItems ? 'expand' : 'collapse'} option`}
                                                        >
                                                            <img
                                                                className={`icon ${this.props.showItems ? 'down-arrow' : 'right-arrow'}`}
                                                                alt=''
                                                                onClick={() =>
                                                                    this.props.showItems
                                                                        ? this.props.onHideItems()
                                                                        : this.props.onShowItems()
                                                                }
                                                            />
                                                            <label>{this.props.label}</label>
                                                            <span>
                                                                {/* {` listing ${this.props.displayParts.length} parts, listing ${this.props.displayFolders.length} folders`} */}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {/* <DisplayPartsOptions showOptions={this.props.showOptions} /> */}
                                                </dt>
                                            }
                                        </>
                                        <>
                                            {
                                                this.props.showItems ?
                                                    (
                                                        <dd>
                                                            {
                                                                <>
                                                                    {
                                                                        this.props.showListView ?
                                                                            (
                                                                                <>
                                                                                    <div className='display-list'>
                                                                                        <div className='display-list-row'>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Type</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Name</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Imported Date</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Owner</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>No of Plates</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Folder</label>
                                                                                            </div>
                                                                                            <div className='display-list-header'>
                                                                                                <label>Actions</label>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                            :
                                                                            (
                                                                                null
                                                                            )
                                                                    }
                                                                    {console.log( this.props.displayParts)}

                                                                    {
                                                                        this.props.displayParts.map((dp, i) => (this.props.showListView ? <DisplayPartListView key={i} displayPart={dp} /> : <DisplayDashboardPartCardView key={i} displayPart={dp} />))
                                                                    }
                                                                </>
                                                            }
                                                        </dd>
                                                    )
                                                    :
                                                    (
                                                        null
                                                    )
                                            }
                                        </>
                                    </dl>
                                </>
                            )
                            :
                            (
                                null
                            )
                    }
                </>
            </>
        );
    }
}

const DisplayDashboardParts = withRouter(connect(Props, Actions)(Component));

export default DisplayDashboardParts;
