import React from "react";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import IPartInfo from "../../../../interfaces/IPartInfo";
import { onNavigateToPlatesPage, onNavigateToPlateReviewPage, onNavigateToReviewPage } from "../../../Actions/Home";
import { IDisplayPlate, IPlate, IState } from "../../../Interfaces";
import { onHighlightPlate, onSelectPlate, onUnselectPlate } from '../../../Actions/Plates';
import { onClearAddedParts, onSelectPart, onUnselectPart } from "../../../Actions/Parts";
import moment from "moment";

const Props = (state: IState, ownProps: any) => {
	const props = {
		loadedPlates: state.data.plates.loadedPlates as IPlate[],
		displayPlate: ownProps.displayPlate as IDisplayPlate,
	};

	return props;
};

const Actions = {
	onHighlightPlate: onHighlightPlate,
	onNavigateToPlatesPage: onNavigateToPlatesPage,
	onNavigateToPlateReviewPage: onNavigateToPlateReviewPage,
	onClearAddedParts: onClearAddedParts,
	onNavigateToReviewPage: onNavigateToReviewPage,
	onSelectPlate: onSelectPlate,
	onUnselectPlate: onUnselectPlate,
};

const DisplayReviewPlateCardViewMethod = (props) => {




	const onNavigateToPlateReviewPage = (plateId) => {

		props.onHighlightPlate(plateId);

		const plateToBeEdited = props.loadedPlates.find(lp => lp.id === plateId) as IPlate;

		const partIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartID).filter(Boolean) as string[];
		const parts = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean) as IPartInfo[];
		const partConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.PartConfig['originalJson']['id']).filter(Boolean);
		const partSlicerConfigurationIds = plateToBeEdited.parts.filter(Boolean).map(stringifiedPart => JSON.parse(stringifiedPart)).filter(Boolean).map(part => part.properties.SlicerConfig['originalJson']['id']).filter(Boolean);
		const plateConfigurationIds = [plateToBeEdited.machineId, plateToBeEdited.millConfigId, plateToBeEdited.materialConfigId, plateToBeEdited.recipeId];

		const ids = {
			partIds,
			parts,
			partConfigurationIds,
			partSlicerConfigurationIds,
			plateConfigurationIds,
			plateId,
		};

		props.onNavigateToPlateReviewPage(ids, props.history, true);
	}

	const createdDate = props.displayPlate.createdDate;
	const formatedDate = navigator.userAgent.indexOf('Mac OS X') != -1 ? createdDate.substring(3, 5) + "/" + createdDate.slice(0, 3) + createdDate.substring(5, createdDate.length) : props.displayPlate.createdDate
		return (
			<>
				<div
					className={`display-card is-selected-${props.displayPlate.isHighlighted}`}
					id={`plate-${props.displayPlate.index}`}
					key={`${props.displayPlate.index}`}
					onClick={() => {
						props.displayPlate.isSelected
							? props.onSelectPlate(props.displayPlate.id) :
							onNavigateToPlateReviewPage(props.displayPlate.id)
					}}
				>
					<div>
						<div className="thumbnail-preview">
							<img src='/plate-thumbnail.svg' className='default-thumb-image' alt="PlateImage" />
						</div>
						<div className="display-card-row">
							<div className="display-card-header">
								<label className="text-ellipsis-second-line">
									{props.displayPlate.name}
								</label>
							</div>
							{/* <div className="display-card-more-action"
							onClick={(e) => e.stopPropagation()}>
							<DisplayPlateOptions
								{...{ displayPlate: props.displayPlate }}
							/>
						</div> */}
						</div>
						<div className="display-card-row">
							<div className="display-card-sub-header">
								<div className='details'>
									<label>Created Date:</label>
									<span>{props.displayPlate.createdDate ? moment(new Date(formatedDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not available'}</span>
								</div>
								<div className='details'>
									<label>Parts Plated:</label>
									<span>{props.displayPlate.parts.length}</span>
								</div>
								{/* <div className='details'>
                                    <label>File Size:</label>
                                    <span>34MB</span>
                                </div>
                                <div className='details'>
                                    <label>Dimensions:</label>
                                    <span>150mm x 150mm x 45mm</span>
                                </div> */}
							</div>
						</div>
					</div>
				</div>
			</>
		);

}

const DisplayPlateCardView = withRouter(connect(Props, Actions)(DisplayReviewPlateCardViewMethod));

export default DisplayPlateCardView;
