import API, { graphqlOperation } from '@aws-amplify/api';

import { updateParts } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPart } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";
const onUnarchivePartInit = () => {
    return {
        type: ACTION_TYPES.PART.UNARCHIVE.INIT,
        payload: null,
    };
};

const onUnarchivePartSuccess = (unarchivedPart: IPart) => {
    return {
        type: ACTION_TYPES.PART.UNARCHIVE.SUCCESS,
        payload: unarchivedPart,
    };
};

const onUnarchivePartFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Unarchive Part');
    console.error('Failure on Unarchive Part - ', errorMessage);
    return {
        type: ACTION_TYPES.PART.UNARCHIVE.FAIL,
        payload: errorMessage,
    };
};


const onUnarchivePartDone = () => {
    return {
        type: ACTION_TYPES.PART.UNARCHIVE.DONE,
        payload: null,
    };
};

const onUnarchivePart = (partId) => {
    return async (dispatch, getState) => {
        dispatch(onUnarchivePartInit());
        try {
            const state = getState();
            const partToBeUnarchived = state.data.parts.loadedParts.find(p => p.id === partId) as IPart;
            partToBeUnarchived.archive = null;
            delete partToBeUnarchived._deleted;
            delete partToBeUnarchived._lastChangedAt;
            delete partToBeUnarchived.createdAt;
            delete partToBeUnarchived.updatedAt;
            const variables = {
                input: partToBeUnarchived,
            };
            const result = await API.graphql(graphqlOperation(updateParts, variables));
            const unarchivedPart = ((result as any)?.data?.updateParts) as IPart;
            dispatch(onUnarchivePartSuccess(unarchivedPart));
        }
        catch (graphqlError) {
            const errorMessage = `Unarchive part failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onUnarchivePartFail(errorMessage));
        }
        dispatch(onUnarchivePartDone());
    };
};

export default onUnarchivePart;