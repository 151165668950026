import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { onHighlightFolder, onUnarchiveFolder, onArchiveFolder, onDeleteFolder, onRenameFolderOpenDialog } from '../../../Actions/Folders';
import { IState } from '../../../Interfaces';

const Props = (state: IState, ownProps: any) => {

    const props = {
        isLoadingComplete: state.data.folders.isLoadingComplete as boolean,
        displayFolder: ownProps.displayFolder,
    };

    return props;
};

const Actions = {
    onHighlightFolder: onHighlightFolder,
    onRenameFolderOpenDialog: onRenameFolderOpenDialog,
    onUnarchiveFolder: onUnarchiveFolder,
    onArchiveFolder: onArchiveFolder,
    onDeleteFolder: onDeleteFolder,
};

class Component extends React.Component<any, any> {
    public render() {
        return (
            <>
                <Dropdown
                    onToggle={(isOpen, event) => {
                        event.stopPropagation();
                        return (isOpen && this.props.onHighlightFolder(this.props.displayFolder.id))
                    }}>
                    <Dropdown.Toggle
                        id="dropdown-basic"
                        variant="light">
                        <div className='options right-justify'>
                            <div className='more-check option'>
                                <img className='icon more-check'
                                    alt='' />
                            </div>
                        </div>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                    <Dropdown.Item
                            disabled={!this.props.isLoadingComplete}
                            onClick={() => this.props.onRenameFolderOpenDialog(this.props.displayFolder.id)}>
                            Rename
                        </Dropdown.Item>
                        {/* <Dropdown.Item
                            disabled={!this.props.displayFolder.isArchived}
                            onClick={() => this.props.onUnarchiveFolder(this.props.displayFolder.id)}>
                            Unarchive
                        </Dropdown.Item>
                        <Dropdown.Item
                            disabled={this.props.displayFolder.isArchived}
                            onClick={() => this.props.onArchiveFolder(this.props.displayFolder.id)}>
                            Archive
                        </Dropdown.Item> */}
                        <Dropdown.Item
                            onClick={() => this.props.onDeleteFolder(this.props.displayFolder.id)}>
                            Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        );
    }
}

const DisplayFolderFolderOptions = withRouter(connect(Props, Actions)(Component));

export default DisplayFolderFolderOptions;