import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { AddedPartOptions } from '.';
import { IState } from '../Interfaces';
import moment from 'moment';

const Props = (state: IState, ownProps: any) => {

    const props = {

    };

    return props;
};

const Actions = {

};
class Component extends React.Component<any, any> {
    public render() {
        return (
            <>
                {this.props.addedPart?.id ?
                    <div className='display-card is-added-true'
                        id={`added-part-${this.props.addedPart?.index}`}>

                        {/* <div className='display-card-row'>
                        <div className='display-card-header'>
                            <label>{this.props.addedPart?.label}</label>
                        </div>
                        <div className='display-card-select-action'>
                        </div>
                    </div> */}
                        <div>
                            <div className='thumbnail-preview'>
                                {
                                    this.props.addedPart.icon ?
                                        <img className='part-image' src={this.props.addedPart.icon} />
                                        : <img src="/part-thumbnail.svg" alt="thumbnail image" className='default-thumb-image' />
                                }
                            </div>
                            <div className='display-card-row'>
                                <div className='display-card-header'>
                                    <label title={this.props.addedPart?.name}>{this.props.addedPart.name} - {this.props.addedPart?.label}</label>
                                </div>
                                <div className='display-card-select-action'>
                                    <div className='options right-justify'>
                                        <div className='display-card-more-action'>
                                            <AddedPartOptions addedPart={this.props.addedPart} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='display-card-row'>

                                <div className='display-card-sub-header'>
                                    <div className='details'>
                                        <label>Imported Date:</label>
                                        <span>{this.props.addedPart?.created_at ? moment(new Date(this.props.addedPart?.created_at)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not available'}</span>
                                        {/* <span>{this.createdDate ? moment(new Date(this.formatedDate)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not available'}</span> */}
                                    </div>
                                    <div className='details'>
                                        <label>Created By:</label>
                                        <span>{this.props.addedPart?.creator}</span>
                                    </div>
                                    {/* <div className='details'>
                                        <label>File Size:</label>
                                        <span>34MB</span>
                                    </div>
                                    <div className='details'>
                                        <label>Dimensions:</label>
                                        <span>50mm x 50mm x 16mm</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className='display-card-row'>
                        <div className='display-card-sub-header'>
                            <label>{this.props.addedPart?.name}</label>
                        </div>
                        <div className='display-card-more-action'>
                            <AddedPartOptions addedPart={this.props.addedPart} />
                        </div>
                    </div> */}
                    </div> : null}
            </>
        );
    }
}

const AddedPart = withRouter(connect(Props, Actions)(Component));

export default AddedPart;