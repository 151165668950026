import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
	WorkspaceSection,
	ReviewPageTabBar,
	PartsHeaderMinified,
	NewPlate,
	NewBuild,
	AddPart,
	ReviewPartsSection,
	ReviewPlatesSection,
	ReviewBuildsSection,
} from "../Components"
import { onEditPlatePageTabChange, onSelectPlateConfiguration } from "../Actions/Plates";
import {
	IState,
	IConfiguration,
	IDisplayPart,
} from "../Interfaces";
import {
	getConfigurationsBasedOnType,
	getDisplayParts,
} from "../Mappers";
import { SortDirection, TabIndex } from "../Enums";
import { onClearAddedParts } from "../Actions/Parts";
import { onHomePageTabChange, onLoadBuilds, onLoadParts, onNavigateToHomePage, onLoadPlates } from "../Actions/Home";
import { onSearchPartsTextChange } from '../Actions/Parts';
import { onSearchPlatesTextChange } from '../Actions/Plates';
import { onSearchBuildsTextChange } from '../Actions/Builds';

const Props = (state: IState, ownProps: any) => {
	const displayParts = getDisplayParts(state) as IDisplayPart[];
	const totalParts =
		state.data.parts.sortDirection === SortDirection.ASC
			? displayParts.reverse()
			: (displayParts as IDisplayPart[]);
	const filteredParts = totalParts.filter(dp => dp.isFiltered) as IDisplayPart[];

	const printConfigurations = getConfigurationsBasedOnType(
		state,
		"printConfig"
	) as IConfiguration[];
	const millConfigurations = getConfigurationsBasedOnType(
		state,
		"millConfig"
	) as IConfiguration[];
	const recipeConfigurations = getConfigurationsBasedOnType(
		state,
		"recipe"
	) as IConfiguration[];
	const materialConfigurations = getConfigurationsBasedOnType(
		state,
		"material"
	) as IConfiguration[];

	const props = {
		selectedTabIndex: state.data.info.homePageTabIndex as number,

		filteredParts: filteredParts as IDisplayPart[],

		selectedConfigurations: state.data.workspace.selectedConfigurations || [] as IConfiguration[],
		printConfigurations: printConfigurations as IConfiguration[],
		millConfigurations: millConfigurations as IConfiguration[],
		recipeConfigurations: recipeConfigurations as IConfiguration[],
		materialConfigurations: materialConfigurations as IConfiguration[],
		displayParts: ownProps.displayParts,
		loadedPartsCount: state.data.parts.loadedParts.length,
		loadedPlatesCount: state.data.plates.loadedPlates.length,
		loadedBuildsCount: state.data.builds.loadedBuilds.length,
		searchText: (state.data.parts.searchText && state.data.plates.searchText && state.data.builds.searchText) as string,
		partsSearchResultsCount: state.data.search?.searchPartsResults.length,
		isSearchEnabled: state.data.search.isSearchEnabled as boolean,
	};

	return props;
};

const Actions = {
	onEditPlatePageTabChange: onEditPlatePageTabChange,
	onSelectPlateConfiguration: onSelectPlateConfiguration,
	onClearAddedParts: onClearAddedParts,
	onHomePageTabChange: onHomePageTabChange,
	onNavigateToHomePage: onNavigateToHomePage,
	onLoadParts: onLoadParts,
	onLoadPlates: onLoadPlates,
	onLoadBuilds: onLoadBuilds,
	onSearchPartsTextChange: onSearchPartsTextChange,
	onSearchPlatesTextChange: onSearchPlatesTextChange,
	onSearchBuildsTextChange: onSearchBuildsTextChange,
};

class Component extends React.Component<any, any> {

	componentDidMount() {
		this.props.selectedConfigurations.forEach(selectedConfiguration => {
			this.props.onSelectPlateConfiguration(selectedConfiguration?.id);
		});
		this.props.onHomePageTabChange(this.props.selectedTabIndex)
	}

	componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
		if (prevProps.searchText !== '' && this.props.searchText === '') {
			this.props.onSearchPartsTextChange('') &&
				this.props.onSearchPlatesTextChange('') &&
				this.props.onSearchBuildsTextChange('') &&
				this.props.onLoadParts();
				this.props.onLoadPlates();
				this.props.onLoadBuilds();
		}
	}

	// componentWillUnmount() {
	// 	this.props.onClearAddedParts();
	// }

	public render() {
		return (
			<>
				<section className="plates page review-page">

					<section className="plate view">
						<div className="left-menu">
						<PartsHeaderMinified />

							{/* <div className="options left-justify back-container">
								<div className="back option"
									onClick={() =>
										this.props.onNavigateToHomePage(this.props.history)
									}>
									<img
										className="icon back ml-0 pl-0"
										alt="back"

									/>
									<label className="back-btn">Home</label>
								</div>
							</div> */}
							{/* <header>

								<div className="tab-bar options left-justify">
									<div
										className={`tab ${(this.props.selectedTabIndex === TabIndex.PARTS) ? 'active' : ''}`}
										onClick={() => { this.props.onHomePageTabChange(TabIndex.PARTS) }}>
										<label>Parts</label>
									</div>
									<div
										className={`tab ${(this.props.selectedTabIndex === TabIndex.PLATES) ? 'active' : ''}`}
										onClick={() => { this.props.onHomePageTabChange(TabIndex.PLATES); }}>
										<label>Plates</label>
									</div>
									<div
										className={`tab ${(this.props.selectedTabIndex === TabIndex.BUILDS) ? 'active' : ''}`}
										onClick={() => { this.props.onHomePageTabChange(TabIndex.BUILDS); }}>
										<label>Builds</label>
									</div>
								</div>
							</header> */}
							<div className="ml-4 mt-2">
								{this.props.isSearchEnabled && this.props.partsSearchResultsCount !== 0 && (
									<span className="ml-4">{`${this.props.partsSearchResultsCount} parts loaded`}</span>
								)}
							</div>
							<div className="display-parts">
								<>
									{this.props.selectedTabIndex === TabIndex.PARTS ? (
										<>
											<section>
												<div>
													<ReviewPartsSection />
												</div>
											</section>
										</>
									) : null}
								</>
								<>
									{this.props.selectedTabIndex === TabIndex.PLATES ? (
										<>
											<p className="sub-heading">Saved Plates</p>
											<section>
												<div>
													{/* className="scrollable-content" */}
													<ReviewPlatesSection />
												</div>
											</section>
										</>
									) : null}
								</>
								<>
									{this.props.selectedTabIndex === TabIndex.BUILDS ? (
										<>
											<p className="sub-heading">Sliced Builds</p>
											<section>
												<div key={`div_${this.props.selectedTabIndex}`}>
													<ReviewBuildsSection />
												</div>
											</section>
										</>
									) : null}
								</>
							</div>
							<div className="tap-text">
								Double tap to load into viewer.
							</div>
						</div>
						<div className="right-view preview-controls">
							{/* <ReviewPageTabBar /> */}
							<WorkspaceSection />
						</div>
						<NewPlate />
					</section>
				</section>
			</>
		);
	}
}

const ReviewPage = withRouter(
	connect(Props, Actions)(Component)
);

export default ReviewPage;
