import API, { graphqlOperation } from '@aws-amplify/api';

import { deletePlate } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IBuild, IPart, IPlate, IState } from '../../../Interfaces';
import onUpdateBuildAfterDelete from './updateBuild';
import onUpdatePartAfterDelete from './updatePart';
import { onDisplayLoader, onResetSearch } from '../../Search';
import { onNavigateToHomePage } from '../../Home';

import { NotificationManager } from "react-notifications";
const onDeletePlateInit = () => {
    return {
        type: ACTION_TYPES.PLATE.DELETE.INIT,
        payload: null,
    };
};

const onDeletePlateSuccess = (deletedPlate: IPlate) => {
    return {
        type: ACTION_TYPES.PLATE.DELETE.SUCCESS,
        payload: deletedPlate,
    };
};

const onDeletePlateFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Delete Plate');
    console.error('Failure on Delete Plate - ', errorMessage);
    return {
        type: ACTION_TYPES.PLATE.DELETE.FAIL,
        payload: errorMessage,
    };
};


const onDeletePlateDone = () => {
    return {
        type: ACTION_TYPES.PLATE.DELETE.DONE,
        payload: null,
    };
};

const onDeletePlate = (plateId,history) => {
    
    return async (dispatch, getState) => {
        
        dispatch(onDeletePlateInit());
        
        try {
            const state = getState() as IState;
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(true))
            }

            const plateToBeDeleted = !state.data.search.isSearchEnabled ? (state.data.plates.loadedPlates.find(
                (plate) => plate.id === plateId
            ) as IPlate) : state.data.search.highlightedPlateData as IPlate

          
            const variables = {
                input: {
                    id: plateToBeDeleted.id,
                    _version: plateToBeDeleted._version,
                },
            };
            const result = await API.graphql(graphqlOperation(deletePlate, variables));
            const deletedPlate = ((result as any)?.data?.deletePlate) as IPlate;
            dispatch(onDeletePlateSuccess(deletedPlate));

            const partIdsToBeUpdated = deletedPlate.parts.map(part => JSON.parse(part).properties.PartID);
            const partsToBeUpdated = state.data.parts.loadedParts.filter(lp => partIdsToBeUpdated.some(id => id === lp.id));
           
            partsToBeUpdated.forEach(part => dispatch(onUpdatePartAfterDelete(part, deletedPlate)));

            const buildsToBeUpdated = state.data.builds.loadedBuilds.filter(lb => lb.plates && lb.plates.some(plate => JSON.parse(plate)['name'] === deletedPlate.id)) as IBuild[];
            buildsToBeUpdated.forEach(build => dispatch(onUpdateBuildAfterDelete(build, deletedPlate)));
            if (state.data.search.isSearchEnabled) {
                dispatch(onDisplayLoader(false))
                dispatch(onNavigateToHomePage(history))
                dispatch(onResetSearch())
            }
        }
        catch (graphqlError) {
                dispatch(onDisplayLoader(false))
            const errorMessage = `Delete plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onDeletePlateFail(errorMessage));
        }

        dispatch(onDeletePlateDone());
    };
};

export default onDeletePlate;