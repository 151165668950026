import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { IConfiguration, IState, IAddedPart, IPlate } from "../Interfaces";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { onNavigateToHomePage } from "../Actions/Home";
import { onNewBuildOpenDialog } from "../Actions/Builds";
import { onNewPlateOpenDialog, onUpdatePlate, onPartReplicate } from "../Actions/Plates";
import {
  getDefaultMillConfiguration,
  getDefaultPrintConfiguration,
  getDefaultMaterialConfiguration,
  getDefaultRecipeConfiguration,
  getDefaultPartConfiguration,
  getDefaultPartSlicerConfiguration,
} from "../DefaultConfigurations";
import { onAddPartToAddedParts } from "../Actions/Parts";
import { NotificationManager } from "react-notifications";
import moment from 'moment';

const Props = (state: IState, ownProps: any) => {
  const defaultPrintConfiguration = getDefaultPrintConfiguration(
    state
  ) as IConfiguration;
  const defaultMillConfiguration = getDefaultMillConfiguration(
    state
  ) as IConfiguration;
  const defaultMaterialConfiguration = getDefaultMaterialConfiguration(
    state
  ) as IConfiguration;
  const defaultRecipeConfiguration = getDefaultRecipeConfiguration(
    state
  ) as IConfiguration;

  const isSearchEnabled = state.data.search.isSearchEnabled as boolean;
  const highlightPlate = state.data.search.highlightedPlateData as IPlate;

  const props = {
    plateName: state.data.plates.highlightedPlateId as string,
    isLoadingComplete: state.data.builds.isLoadingComplete as boolean,
    isNewPlate: state.data.workspace.isNewPlate as boolean,
    isEditPlate: state.data.workspace.isEditPlate as boolean,
    isReviewPart: state.data.workspace.isReviewPart as boolean,
    selectedPlate: state.data.plates.loadedPlates?.filter(
      (el) => el.id === state.data.plates.highlightedPlateId
    ),
	selectedSearchPlate: state.data.search.searchPlatesResults?.filter(el => el.id === state.data.plates.highlightedPlateId),

    isShowPattern: false,
    selectedPartIds: state.data.parts.selectedPartIds as string[],
	isSearchEnabled: isSearchEnabled as boolean,
    highlightPlate: highlightPlate as IPlate,
	selectedSearchPlateName: state.data.search.highlightedPlateData,
    ids: {
      // partIds: [ownProps.addedPart].filter(Boolean).map(part => part.id).filter(Boolean) as string[],

      partIds: [...state.data.parts.selectedPartIds].filter(
        Boolean
      ) as string[],
      partConfigurationIds: [...state.data.parts.selectedPartIds]
        .filter(Boolean)
        .map((id) => getDefaultPartConfiguration(state))
        .filter(Boolean)
        .map((configuration) => configuration.id)
        .filter(Boolean) as string[],
      partSlicerConfigurationIds: [...state.data.parts.selectedPartIds]
        .filter(Boolean)
        .map((id) => getDefaultPartSlicerConfiguration(state))
        .filter(Boolean)
        .map((configuration) => configuration.id)
        .filter(Boolean) as string[],
      plateConfigurationIds: [
        defaultPrintConfiguration?.["id"],
        defaultMillConfiguration?.["id"],
        defaultMaterialConfiguration?.["id"],
        defaultRecipeConfiguration?.["id"],
      ].filter(Boolean),
    },
  };

  return props;
};

const Actions = {
  onNavigateToHomePage: onNavigateToHomePage,
  onUpdatePlate: onUpdatePlate,
  onNewPlateOpenDialog: onNewPlateOpenDialog,
  onNewBuildOpenDialog: onNewBuildOpenDialog,
  onAddPartToAddedParts: onAddPartToAddedParts,
  onPartReplicate: onPartReplicate,
};

class Component extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isShowPattern: false,
      numOfParts: 0,
      partsSpacing: 0,
    };
  }

	public render() {
		return (
			<>
				<div className="tab-bar plates-page">
					<div className="options left-justify">
						<div className="back option">							
								<div className="backIcon" onClick={() =>
										this.props.onNavigateToHomePage(this.props.history)
									}></div>
							
						</div>
						{/* <>
							{this.props.isNewPlate ? (
								<>
									<div className="plate-status info">
										<label>New Plate</label>
									</div>
								</>
							) : null}
						</> */}
						<>
							{this.props.isEditPlate ? (
								<>
									<div className="plate-status info">
										<label>Edit Plate</label>
										{this.props.selectedPlate.length !== 0 ? (
											<label className="plate-name">
												{this.props.selectedPlate[0].name ? this.props.selectedPlate[0].name : ""}
											</label>
										)
											: (
												<label className="plate-name">
													{this.props.highlightPlate.name}
												</label>
											)
										}
										
									</div>
									{this.props.selectedPlate[0] ?
									(
										<div className="display-info">
																	  
											<label>Last updated on :  {this.props.selectedPlate[0].modified_at ? moment(new Date(this.props.selectedPlate[0].modified_at)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available'}</label>
											<label>Updated by :  {this.props.selectedPlate[0].modified_by ?this.props.selectedPlate[0].modified_by : 'Not Available'}</label>
										</div>
										)
										: (
											<div className="display-info">
																	  
											<label>Last updated on :  {this.props.highlightPlate.modified_at ? moment(new Date(this.props.highlightPlate.modified_at)).format('MMMM Do YYYY, HH:mm:ss A') : 'Not Available'}</label>
											<label>Updated by :  {this.props.highlightPlate.modified_by ?this.props.highlightPlate.modified_by : 'Not Available'}</label>
										</div>
										)
									}
								</>
							) : null}
						</>
					</div>
    
		<>
            {
              <div className="action-items">
                <div
                  className={`pattern-view`}
                  id="patternview"
                  onClick={() => {
                    this.setState({
                      isShowPattern: true,
                    });
                  }}
                ></div>
                <label>Part Replication</label>
              </div>
            }
          </>
          <>
            {this.state.isShowPattern ? (
              <>
                <div className="pattern-matrix">
                  <div className="d-flex heading-actions">
                    <h6>Pattern </h6>
                    <span className="ml-5 mt-1 d-flex">
                      <span
                        className="close-btn"
                        onClick={() =>
                          this.setState({
                            isShowPattern: false,
                          })
                        }
                      ></span>
                      <span
                        className="ml-3 tick-btn"
                        onClick={
                          () => {
                                  this.props.onPartReplicate(this.props.ids, this.state.numOfParts, this.state.partsSpacing);
                                  this.setState({isShowPattern: false, numOfParts: 0, partsSpacing: 10});
                                }
                        }
                      ></span>
                    </span>
                  </div>
                  <div>
                    <label title="Maximum number of copies will be used if value is not provided.">Parts "N" number</label>
                    <input 
                      type="number" min="2"
                      onChange={(e) =>
                        this.setState({ numOfParts: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <label title="Minimum 10 will be used if value is not provided.">Spacing</label>
                    <input type="number" min="10" defaultValue="10" placeholder="Enter 10 or greater"
                    onChange={(e) =>
                      this.setState({ partsSpacing: e.target.value })
                    }/>
                  </div>
                </div>
              </>
            ) : null}
          </>
					<div className="flex-space"></div>
					<div className="options right-justify">
						<div className="cancel">
							<OverlayTrigger
								placement="top"
								overlay={<Tooltip id="button-build">Cancel</Tooltip>}
							>
								<Button
									className="inactive btn btn-secondary"
									onClick={() =>
										this.props.onNavigateToHomePage(this.props.history)
									}
								>
									<span>Cancel</span>
								</Button>
							</OverlayTrigger>
						</div>
						<>
							{/* {
								this.props.isNewPlate ? (
									<>
										<div className="create-new-plate btn-space">
											<OverlayTrigger
												placement="top"
												overlay={
													<Tooltip id="button-create-new-plate">
														Create New Plate
													</Tooltip>
												}
											>
												<Button
													className="active"
													onClick={() => this.props.onNewPlateOpenDialog()}
												>
													<span>Save</span>
												</Button>
											</OverlayTrigger>
										</div>
									</>
								) : null
							} */}
						</>
						<>
							{
								this.props.isEditPlate ? (
									<>
										<div className="create-new-plate  btn-space">
											<OverlayTrigger
												placement="top"
												overlay={
													<Tooltip id="button-update-existing-plate">
														update existing plate
													</Tooltip>
												}
											>
												<Button
													className="active"
													onClick={() => {
														for (let attempt = 0; attempt < 2; attempt++) {
															try {
																this.props.onUpdatePlate(this.props.history);
																break;
															}
															catch (err) {
																console.log("Exception passed " + err)
															}
														}
													}}
												>
													<span>Update</span>
												</Button>
											</OverlayTrigger>
										</div>
									</>
								) : null
							}
						</>
						<div className="build">
							{/* {this.props.isNewPlate && 
								<OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-disabled">Please save the plate before proceeding.</Tooltip>}>
								<span className="d-inline-block">
									<Button disabled style={{ pointerEvents: 'none' }}>
											Begin Slicing
									</Button>
								</span>
							</OverlayTrigger>
							} */}
							{this.props.isEditPlate && 
								<Button
									className="active pr-1"
									disabled={!this.props.isLoadingComplete}
									onClick={() => {
										if (this.props.isEditPlate) {
											this.props.onUpdatePlate(this.props.history);
										}
										this.props.onNewBuildOpenDialog();
									}}
								>
									Begin Slicing <span className="right-arrow"></span>
								</Button>}
						</div>
					</div>
				</div>
			</>
		);
	}
}

const TabBar = withRouter(connect(Props, Actions)(Component));

export default TabBar;
