import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
	WorkspaceSection,
	PlateConfiguration,
	PlateParts,
	EditPlatePageTabBar,
	AddedParts,
	LoadedParts,
	PartsHeaderMinified,
	NewPlate,
	NewBuild,
} from "../Components";
import { onEditPlatePageTabChange, onSelectPlateConfiguration } from "../Actions/Plates";
import {
	IState,
	IConfiguration,
	IAddedPart,
	IDisplayPart,
} from "../Interfaces";
import {
	getAddedParts,
	getConfigurationsBasedOnType,
	getDisplayParts,
} from "../Mappers";
import { EditPlatePhase, SortDirection } from "../Enums";
import { onClearAddedParts } from "../Actions/Parts";
import { setPlateConfigurations } from "../Instances";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";
import NewBuildSuccess from "../Components/NewBuildSuccess";

const Props = (state: IState, ownProps: any) => {
	const addedParts = getAddedParts(state) as IAddedPart[];
	const displayParts = getDisplayParts(state) as IDisplayPart[];
	const totalParts =
		state.data.parts.sortDirection === SortDirection.ASC
			? displayParts.reverse()
			: (displayParts as IDisplayPart[]);
	const filteredParts = totalParts.filter(dp => dp.isFiltered) as IDisplayPart[];

	const printConfigurations = getConfigurationsBasedOnType(
		state,
		"printConfig"
	) as IConfiguration[];
	const millConfigurations = getConfigurationsBasedOnType(
		state,
		"millConfig"
	) as IConfiguration[];
	const recipeConfigurations = getConfigurationsBasedOnType(
		state,
		"recipe"
	) as IConfiguration[];
	const materialConfigurations = getConfigurationsBasedOnType(
		state,
		"material"
	) as IConfiguration[];

	const props = {
		selectedTabIndex: state.data.info.editPlatePageTabIndex as EditPlatePhase,
		addedParts: addedParts as IAddedPart[],
		filteredParts: filteredParts as IDisplayPart[],
		selectedConfigurations: state.data.workspace.selectedConfigurations || [] as IConfiguration[],
		printConfigurations: printConfigurations as IConfiguration[],
		millConfigurations: millConfigurations as IConfiguration[],
		recipeConfigurations: recipeConfigurations as IConfiguration[],
		materialConfigurations: materialConfigurations as IConfiguration[],
		state: state as any,
		isLoadingComplete: (state.data.parts.isLoadingComplete && 
			state.data.builds.isLoadingComplete && 
			state.data.plates.isLoadingComplete) as boolean,
	};

	return props;
};

const Actions = {
	onEditPlatePageTabChange: onEditPlatePageTabChange,
	onSelectPlateConfiguration: onSelectPlateConfiguration,
	onClearAddedParts: onClearAddedParts,
};

class Component extends React.Component<any, any> {

	componentDidMount() {
		this.props.selectedConfigurations.forEach(selectedConfiguration => {
			this.props.onSelectPlateConfiguration(selectedConfiguration?.id);
		});
		if(this.props.location.state.params){
			setPlateConfigurations(this.props.state,this.props.location.state.params,false)
		}
	}

	componentWillUnmount() {
		this.props.onClearAddedParts();
	}
	getSelectedId = (type:string) => {
		return this.props.selectedConfigurations.find(val => val?.type === type)
	}

	public render() {
		return (
			<>
			 <LoadingComponent visible={!this.props.isLoadingComplete} />
				<section className="plates page review-page">
					<div>
						<EditPlatePageTabBar />
						<NewPlate />
						<NewBuild />
						<NewBuildSuccess />
					</div>
					<section className="plate view">
						<div className="left-menu">
						
						<PartsHeaderMinified  />

							<header>
								<div className="tab-bar options left-justify-plate ml-0">
									<div
										className={`tab ${this.props.selectedTabIndex === EditPlatePhase.PARTS
											? "active"
											: ""
											}`}
										onClick={() => {
											this.props.onEditPlatePageTabChange(EditPlatePhase.PARTS);
										}}
									>
										<label>Parts</label>
									</div>
									<div
										className={`tab ${this.props.selectedTabIndex ===
											EditPlatePhase.CONFIGURATIONS
											? "active"
											: ""
											}`}
										onClick={() => {
											this.props.onEditPlatePageTabChange(
												EditPlatePhase.CONFIGURATIONS
											);
										}}
									>
										<label>Configurations</label>
									</div>
								</div>
							</header>
							<div className="display-parts edit">
								{this.props.selectedTabIndex === EditPlatePhase.PARTS ? (
									<>
										<AddedParts
											{...{
												label: "Added Parts",
												addedParts: this.props.addedParts,
												showOptions: true,
											}}
										/>
										<LoadedParts
											{...{
												label: "Loaded Parts",
												loadedParts: this.props.filteredParts,
											}}
										/>
									</>
								) : null}

								<>
									{this.props.selectedTabIndex ===
										EditPlatePhase.CONFIGURATIONS ? (
										<>
											<PlateConfiguration
												{...{
													label: "Printer",
													loadedConfigurations: this.props.printConfigurations,
													selectedConfiguration: this.getSelectedId('printConfig'),
												}}
											/>
											<PlateConfiguration
												{...{
													label: "Subtractive Strategy",
													loadedConfigurations: this.props.millConfigurations,
													selectedConfiguration: this.getSelectedId('millConfig'),
												}}
											/>
											<PlateConfiguration
												{...{
													label: "Drying Strategy",
													loadedConfigurations: this.props.recipeConfigurations,
													selectedConfiguration:this.getSelectedId('recipe'),
												}}
											/>
											<PlateConfiguration
												{...{
													label: "Material + Scaling",
													loadedConfigurations: this.props.materialConfigurations,
													selectedConfiguration: this.getSelectedId('material'),
												}}
											/>
											<PlateParts
												{...{
													label: "Part Selection",
													addedParts: this.props.addedParts
												}}
											/>
										</>
									) : null}
								</>
							</div>
						</div>
						<div className="right-view">
							<WorkspaceSection />
						</div>
					</section>
				</section>
			</>
		);
	}
}

const PlatesEditConfigurationsPage = withRouter(
	connect(Props, Actions)(Component)
);

export default PlatesEditConfigurationsPage;
