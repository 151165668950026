import { IPlate, IState, IDisplayPlate } from "../Interfaces";

const getDisplayPlate = (plate: IPlate, state: IState) => {
    const plateId = plate.id || '';
    const plateName = plate.name || '';
    const displayPlate = {
        id: plateId as string,
        name: plateName as string,
        index: state.data.plates.loadedPlates.indexOf(plate) as number,
        isValid: Boolean(plate) as boolean,
        isFiltered: Boolean(plate.name.toLowerCase().includes(state.data.plates.searchText.toLowerCase())) as boolean,
        isSelected: Boolean(state.data.plates.selectedPlateIds.some((id) => id === plate.id)) as boolean,
        // isSelected: Boolean(state.data.plates.selectedPlateIds.some(id => id === plate.id)) as boolean,
        // isSelected: Boolean(
		// 	state.data.plates.selectedPlateIds.length <= 1 ? state.data.plates.selectedPlateIds.some((id) => id === plate.id) : [state.data.plates.selectedPlateIds[state.data.plates.selectedPlateIds.length - 1]].some((id) => id === plate.id)
		// ) as boolean,
        isHighlighted: Boolean(state.data.plates.highlightedPlateId === plate.id) as boolean,
        isRecent: Boolean((((new Date()).getTime() - (new Date(plate.createdAt)).getTime()) / (60 * 60 * 1000)) < 24) as boolean,
        isYours: Boolean((plate.created_by === state.creator) && !Boolean(plate.archive)) as boolean,
        isOthers: Boolean((plate.created_by !== state.creator) && !Boolean(plate.archive)) as boolean,
        isArchived: Boolean(plate.archive),
        isMovedToFolder: Boolean(plate.folders && plate.folders.length > 0),
        createdDate: (new Date(plate.createdAt) as Date).toLocaleString() as string,
        creator: plate.created_by as string,
        hasParts: Boolean(plate.hasParts) as boolean,
        parts: plate.parts as string[],
        hasConfig: Boolean(plate.hasConfig) as boolean,
        config: (Boolean(plate.config) ? plate.config.map(c => JSON.parse(c)['name']) : []) as string[],
        hasBuilds: Boolean(plate.hasBuilds) as boolean,
        builds: (Boolean(plate.builds) ? plate.builds.map(build => JSON.parse(build)['name']) : []) as string[],
        hasFolders: Boolean(plate.hasFolders) as boolean,
        folders: (Boolean(plate.folders) ? plate.folders.map(folder => JSON.parse(folder)['name']) : []) as string[],
        hasTags: Boolean(plate.hasTags) as boolean,
        tags: (Boolean(plate.tags) ? plate.tags.map(tag => JSON.parse(tag)['name']) : []) as string[],
    };

    if (false && state.data.folders.selectedFolderIds && state.data.folders.selectedFolderIds.length > 0) {
        displayPlate.isFiltered = displayPlate.isFiltered && plate.folders && plate.folders.some(folder => state.data.folders.selectedFolderIds.some(id => id === JSON.parse(folder).name));
    }

    return displayPlate as IDisplayPlate;
};

export default getDisplayPlate;