import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayBuildOptions } from '../..';
import { IState } from '../../../Interfaces';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { onNavigateToBuildReviewPage } from '../../../Actions/Home';
import { Auth } from 'aws-amplify';

const Props = (state: IState, ownProps: any) => {
	const props = {};

	return props;
};

const Actions = {
	onNavigateToBuildReviewPage: onNavigateToBuildReviewPage,
};

class Component extends React.Component<any, any> {
	createdDate = (new Date(this.props.displayBuild.created_at) as Date).toLocaleString() as string;
	isConfigDisabled = false;
	displayFolderview = this.props.displayBuild.current_status !== "processing" ?
		this.props.displayBuild.current_status : this.props.displayBuild.build_result;
	componentDidMount() {
		Auth.currentAuthenticatedUser().then((user) => {
			const groups =
				user.signInUserSession.accessToken.payload['cognito:groups'];
			if (groups == 'Operator') {
				this.isConfigDisabled = true;
			} else {
				this.isConfigDisabled = false;
			}
		});
	}
	public render() {
		let buildProgress = 0;
		if ((this.props.displayBuild.current_status === "processing" || this.props.displayBuild.current_status === "Progress") ) {
			buildProgress = parseFloat(this.props.displayBuild.current_status.replace("%", "").split(" ")[1]);
			if (isNaN(buildProgress)) {
				buildProgress = 0;
			}
		}
		return (
			<>
				<div
					className='display-list build-list-view'
					id={`build-${this.props.displayBuild.index}`}
					key={`${this.props.displayBuild.index}`}
					onClick={() =>
						this.props.displayBuild.current_status &&
							(
								(this.props.displayBuild.current_status.toLowerCase().includes('completed')) ||
								(this.props.displayBuild.current_status.toLowerCase().includes('fail') && !this.isConfigDisabled)
							) ?
							(this.props.onNavigateToBuildReviewPage(this.props.displayBuild.id, this.props.history)) : (null)
					}
				>
					<div className='display-list-row'>
						<div className='display-list-sub-header'>
							<div className='options left-justify'>
								<div className='build option'>
									<img className='icon build' alt='build' />
								</div>
							</div>
						</div>
						<div className='display-list-sub-header'>
							<label>{this.props.displayBuild.name}</label>
						</div>
						<div className='display-list-sub-header'>
							<label>{this.props.displayBuild.createdDate ?? this.createdDate}</label>
						</div>
						<div className='display-list-sub-header'>
							<label>{this.props.displayBuild.creator}</label>
						</div>
						<div className='display-list-sub-header'>
						{(this.props.displayBuild.current_status === "completed" ||
								this.props.displayBuild.status === "completed") && (
									<span className="capitalise-text font-weight-bold">

										<img src="/img/slicing-completed.svg" alt="Completed Status Icon" />
										{" " + "Slicing Completed"}
									</span>
								)}

							{(this.props.displayBuild.current_status === "failed" ||
								this.props.displayBuild.status === "failed") && (
									<span className="capitalise-text font-weight-bold">
										<img src="/img/slicing-failed.svg" alt="Failed Status Icon" />
										{" " + "Slicing Failed"}
									</span>
								)}
							{(this.props.displayBuild.current_status === "canceled" ||
								this.props.displayBuild.status === "canceled") && (
									<span className="capitalise-text font-weight-bold">
										<img src="/img/slicing-failed.svg" alt="Canceled Status Icon" />
										{" " + "Slicing Canceled"}
									</span>
								)}
							{(this.props.displayBuild.current_status === "processing" || this.props.displayBuild.current_status === "waiting" ||	
								this.props.displayBuild.status === "processing" || this.props.displayBuild.status === "waiting" || this.props.displayBuild.current_status === "Progress") && (	
									<div className="px-3 w-50">	
										{this.props.displayBuild.current_status === "processing" || this.props.displayBuild.current_status === "Progress" ?
											(
												<label className="pl-0">{`${buildProgress}%`}</label>
											) : (
												<label className="pl-0">Initializing Build</label>
											)}
										<ProgressBar
											variant="success"
											now={buildProgress}
										/>
									</div>
								)}
						</div>
						<div className='display-list-sub-header'>
							<label>{this.props.displayBuild.isMovedToFolder ? this.props.displayBuild.folders.map((folder, i) => <label key={i}>{folder}</label>) : null}</label>
						</div>
						<div className='display-list-sub-header'>
							<div
								className='display-list-more-action'
								onClick={(e) => e.stopPropagation()}
							>
								<DisplayBuildOptions displayBuild={this.props.displayBuild} />
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

const DisplayBuildListView = withRouter(connect(Props, Actions)(Component));

export default DisplayBuildListView;
