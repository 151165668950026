import API, { graphqlOperation } from '@aws-amplify/api';

import { updateParts } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { IPart } from '../../../Interfaces';
import { NotificationManager } from "react-notifications";
const onArchivePartInit = () => {
    return {
        type: ACTION_TYPES.PART.ARCHIVE.INIT,
        payload: null,
    };
};

const onArchivePartSuccess = (archivedPart: IPart) => {
    return {
        type: ACTION_TYPES.PART.ARCHIVE.SUCCESS,
        payload: archivedPart,
    };
};

const onArchivePartFail = (errorMessage: string) => {
    NotificationManager.error('Failure on Archiving part ');
    console.error('Failure on Archiving part - ', errorMessage);
    return {
        type: ACTION_TYPES.PART.ARCHIVE.FAIL,
        payload: errorMessage,
    };
};


const onArchivePartDone = () => {
    return {
        type: ACTION_TYPES.PART.ARCHIVE.DONE,
        payload: null,
    };
};

const onArchivePart = (partId) => {
    return async (dispatch, getState) => {
        dispatch(onArchivePartInit());
        try {
            const state = getState();
            const partToBeArchived = state.data.parts.loadedParts.find(p => p.id === partId) as IPart;
            partToBeArchived.archive = true;
            delete partToBeArchived._deleted;
            delete partToBeArchived._lastChangedAt;
            delete partToBeArchived.createdAt;
            delete partToBeArchived.updatedAt;
            const variables = {
                input: partToBeArchived,
            };
            const result = await API.graphql(graphqlOperation(updateParts, variables));
            const archivedPart = ((result as any)?.data?.updateParts) as IPart;
            dispatch(onArchivePartSuccess(archivedPart));
        }
        catch (graphqlError) {
            const errorMessage = `Archive part failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onArchivePartFail(errorMessage));
        }
        dispatch(onArchivePartDone());
    };
};

export default onArchivePart;