import IPartInfo from "../interfaces/IPartInfo";
import { IScene } from "../Scene";
import { IMatrix } from "../interfaces/IMatrix";
import { v4 as uuidv4 } from "uuid";

import * as THREE from "three";
import { PLYLoader } from "../loaders/PLYLoader";

import dataStorage from "../S3DataStore/S3DataStore";
import { NotificationManager } from "react-notifications";
import { Box3, Vector3, Matrix4 } from "three";
import API, { graphqlOperation } from "@aws-amplify/api";
import { getParts } from "../graphql/queries";
import { Storage } from "aws-amplify";

const STLLoader = require("three-stl-loader")(THREE);
const convexHull = require('convex-hull')
const stlLoader = new STLLoader();
const plyLoader = new PLYLoader();
const partColors = {
	standard: 0x005100,
	error: 0x700000,
};

interface IPartMeshPairing {
	part: IPartInfo;
	mesh?: THREE.Mesh
}

export interface IWorkspaceMeshManager {
	getListOfMeshes(): THREE.Mesh[];
	findPartForMesh(mesh: THREE.Mesh): IPartInfo;
	selectedMatrix: THREE.Group | null;
	updateParts(arg0: IPartInfo[], arg1: any): void;
	updateSelectedMatrix(arg0: IMatrix | null): void;
	snapObjectToFloor(arg0: THREE.Object3D): void;
	addListener(arg0: any): void;
	findAndHighlightPositionErrors(mesh: THREE.Mesh): void;
	setBuildAreaVolume(volume: THREE.Box3): void;
	setPurgeArea(radius?: number, offset?: THREE.Vector3): void;
	setMaterialScaling(coeffXY: number, coeffZ: number): void;
	getBuildAreaVolume(): Box3;
	updateScreen(arg0: boolean): void;
}

class WorkspaceMeshManager implements IWorkspaceMeshManager {
	partMeshPairs: IPartMeshPairing[] = [];
	downloadedPart: Set<IPartInfo> = new Set();
	selectedMatrix: THREE.Group | null = null;
	scene: IScene;
	dispatch: any;
	listeners: any[];
	buildAreaVolume: THREE.Box3;
	materialScale: THREE.Vector3;

	grid?: THREE.GridHelper;
	buildVolumeVizualization?: THREE.Object3D;
	purgeArea?: THREE.Mesh;

	constructor(scene: IScene, dispatch, buildVolume: THREE.Box3) {
		this.updateParts = this.updateParts.bind(this);
		this.updateScreen = this.updateScreen.bind(this);

		this.setBuildAreaVolume = this.setBuildAreaVolume.bind(this);
		this.getListOfMeshes = this.getListOfMeshes.bind(this);
		this.findPartForMesh = this.findPartForMesh.bind(this);
		this.setMaterialScaling = this.setMaterialScaling.bind(this);
		this.getBuildAreaVolume = this.getBuildAreaVolume.bind(this);

		this.scene = scene;
		this.dispatch = dispatch;
		this.listeners = [];
		this.materialScale = new THREE.Vector3(1, 1, 1);

		this.buildAreaVolume = buildVolume;
		this.rescaleVolumeVisualizers();
	}
	updateScreen(screenChanged: boolean) {
		if (screenChanged) {
			let canvas = this.scene.renderer.domElement;
			let width = canvas.clientWidth;
			let height = canvas.clientHeight;
			if (width !== canvas.width || height !== canvas.height) {
				this.scene.renderer.setSize(width, height, false);

				this.scene.camera!.aspect = width / height;
				this.scene.camera!.updateProjectionMatrix();

				this.scene.render();
			}
		}
	}
	updateParts(parts: IPartInfo[], screenName = '') {
		console.log('WorkspaceMeshManager', screenName)
		parts.forEach((part: IPartInfo) => {
			let partMesh = part.mesh;
			if (typeof partMesh === "undefined") {
				if (!this.partMeshDownloadingStarted(part)) {
					this.partMeshPairs.push({
						part: part
					});
					screenName !== '' ? this.downloadNewMesh(part) : this.downloadMesh(part);
				} else {
					const pair = this.findPairForPart(part)!;
					if (pair?.mesh) {
						this.deleteMesh(pair.mesh);
						screenName !== '' ? this.downloadNewMesh(part) : this.downloadMesh(part);
					}
				}
			} else {
				const pair = this.findPairForPart(part);
				if (pair?.mesh) {
					this.syncPartAndMesh(part);
				} else {
					this.partMeshPairs.push({
						part: part,
						mesh: partMesh
					});
					this.scene.scene.add(partMesh);
				}
			}
		});

		let deleteList: IPartMeshPairing[] = [];
		for (let pair of this.partMeshPairs) {
			const part = pair.part;
			if (parts.indexOf(part) < 0) {
				deleteList.push(pair);
			}
		}

		for (const trash of deleteList) {
			if (trash.mesh) {
				this.deleteMesh(trash.mesh!);
			}
			this.partMeshPairs = this.partMeshPairs.filter(pair => pair !== trash);
		}
		this.validateAllMeshes();
		this.scene.render();
	}

	private validateAllMeshes() {
		const allMeshes = this.getListOfMeshes();
		allMeshes.forEach(m => this.findAndHighlightPositionErrors(m));
	}

	private partMeshDownloadingStarted(part: IPartInfo): boolean {
		return this.partMeshPairs.findIndex(
			(pair: IPartMeshPairing) => pair.part.UUID === part.UUID
		) >= 0;
	}

	getBuildAreaVolume(): Box3 {
		return this.buildAreaVolume
	}

	private findPairForPart(part: IPartInfo): IPartMeshPairing | undefined {
		return this.partMeshPairs.find((pair: IPartMeshPairing) => pair.part === part);
	}

	findPartForMesh(mesh: THREE.Mesh): IPartInfo {
		const pair = this.partMeshPairs.find(search_pair => search_pair.mesh === mesh)!;
		return pair.part;
	}

	getListOfMeshes(): THREE.Mesh[] {
		return this.partMeshPairs
			.map((pair: IPartMeshPairing) => pair.mesh)
			.filter((result: THREE.Mesh | undefined) => result) as THREE.Mesh[];
	}

	updateSelectedMatrix(matrix: IMatrix | null) {
		if (matrix === null) {
			if (this.selectedMatrix) {
				this.scene.scene.remove(this.selectedMatrix);
				for (let listener of this.listeners) {
					listener.onDelete(this.selectedMatrix);
				}
			}
			this.selectedMatrix = null;
		} else {
			if (this.selectedMatrix === null) {
				this.selectedMatrix = new THREE.Group();
			}
			matrix.vizualizationGroup = this.selectedMatrix;

			let children = [...this.selectedMatrix.children]
			for (const child of children) {
				this.selectedMatrix.remove(child);
			}

			this.scene.scene.remove(this.selectedMatrix);

			const baseMesh = matrix.baseMesh;
			let box = new THREE.Box3().setFromObject(baseMesh);
			let meshSize = new THREE.Vector3();
			box.getSize(meshSize);

			for (let row = 0; row < matrix.rows; row++) {
				for (let column = 0; column < matrix.columns; column++) {
					let mesh = baseMesh.clone();
					mesh.material = (mesh.material as THREE.Material).clone();

					mesh.translateX(column * (meshSize.x + matrix.columnGap));
					mesh.translateY(-row * (meshSize.y + matrix.rowGap));

					this.selectedMatrix.add(mesh);
				}
			}

			this.scene.scene.add(this.selectedMatrix);
		}
		this.scene.render();
	}

	setBuildAreaVolume(volume: THREE.Box3) {
		this.buildAreaVolume = volume;
		this.rescaleVolumeVisualizers();
		for (let mesh of this.getListOfMeshes()) {
			this.findAndHighlightPositionErrors(mesh);
		}
		let newSizeVector = new THREE.Vector3()
		volume.getSize(newSizeVector);
		this.scene.updateBuildPlateSize(volume)
	}

	setMaterialScaling(coeffXY, coeffZ) {
		if (!coeffXY) {
			coeffXY = 1
		}
		if (!coeffZ) {
			coeffZ = 1
		}
		this.materialScale = new THREE.Vector3(coeffXY, coeffXY, coeffZ);
		this.applyScaling();
		this.scene.render();
	}

	applyScaling() {
		let meshes = this.getListOfMeshes();
		for (let mesh of meshes) {
			let matrixWithoutScale = new Matrix4().compose(mesh.position, mesh.quaternion, new Vector3(1, 1, 1));
			let scaleMatrix = new Matrix4().compose(new Vector3(), new THREE.Quaternion(), this.materialScale);
			let scaled = scaleMatrix.multiply(matrixWithoutScale);
			scaled.decompose(mesh.position, mesh.quaternion, mesh.scale);
		}
	}

	setPurgeArea(radius?: number, offset?: THREE.Vector3) {
		if (this.purgeArea) {
			this.scene.scene.remove(this.purgeArea);
			delete this.purgeArea;
		}
		let parametersValid =
			typeof radius === "number" &&
			typeof offset !== "undefined" &&
			typeof offset.x === "number" &&
			typeof offset.y === "number" &&
			typeof offset.z === "number";

		if (parametersValid) {
			let height = this.buildAreaVolume.getSize(new THREE.Vector3()).z;

			let cylinderMesh = new THREE.Mesh(
				new THREE.CylinderGeometry(radius, radius, 1, 64),
				new THREE.MeshBasicMaterial({ color: 0xdddddd })
			);

			cylinderMesh.applyMatrix4(new THREE.Matrix4().makeRotationX(Math.PI / 2));
			cylinderMesh.applyMatrix4(
				new THREE.Matrix4().makeTranslation(offset!.x, offset!.y, 1)
			);

			this.purgeArea = cylinderMesh;
			this.scene.scene.add(this.purgeArea);
		}
	}

	private rescaleVolumeVisualizers() {
		let buildAreaSize = new THREE.Vector3();
		this.buildAreaVolume.getSize(buildAreaSize);

		// Add grid for vizual assistance
		if (this.grid !== undefined) {
			this.scene.scene.remove(this.grid);
		}
		this.grid = new THREE.GridHelper(1, 50, 0, 0xa9a9a9);
		this.grid.rotateOnAxis(new THREE.Vector3(1, 0, 0), Math.PI / 2);
		this.grid.applyMatrix4(
			new THREE.Matrix4().makeScale(buildAreaSize.x, buildAreaSize.y, 1)
		);
		this.grid.translateX(this.buildAreaVolume.min.x + buildAreaSize.x / 2)
		this.grid.translateY(this.buildAreaVolume.min.y + buildAreaSize.y / 2)
		this.scene.scene.add(this.grid);

		// Add vizualization box
		if (this.buildVolumeVizualization !== undefined) {
			this.scene.scene.remove(this.buildVolumeVizualization);
		}

		const material = new THREE.LineBasicMaterial({ color: 0xa9a9a9 });
		let geometry = new THREE.BufferGeometry();

		let volumePoints = new Float32Array([
			//top
			-0.5,
			-0.5,
			1,
			-0.5,
			0.5,
			1,
			0.5,
			0.5,
			1,
			0.5,
			-0.5,
			1,
			//legs
			-0.5,
			-0.5,
			0,
			-0.5,
			0.5,
			0,
			0.5,
			0.5,
			0,
			0.5,
			-0.5,
			0,
		]);
		geometry.setAttribute(
			"position",
			new THREE.BufferAttribute(volumePoints, 3)
		);

		geometry.setIndex([
			//top
			0,
			1,
			1,
			2,
			2,
			3,
			3,
			0,
			//legs
			0,
			4,
			1,
			5,
			2,
			6,
			3,
			7,
		]);

		this.buildVolumeVizualization = new THREE.LineSegments(geometry, material);
		let buildVolumeScale = new THREE.Matrix4().makeScale(
			buildAreaSize.x,
			buildAreaSize.y,
			buildAreaSize.z
		);
		this.buildVolumeVizualization.applyMatrix4(buildVolumeScale);
		this.buildVolumeVizualization.translateX(this.buildAreaVolume.min.x + buildAreaSize.x / 2)
		this.buildVolumeVizualization.translateY(this.buildAreaVolume.min.y + buildAreaSize.y / 2)
		this.scene.scene.add(this.buildVolumeVizualization);

		this.scene.render();
	}

	findAndHighlightPositionErrors(mesh: THREE.Mesh) {
		mesh.userData.intersectsOtherMesh = false;
		mesh.userData.outsideBuildEnvelope = false;

		const allMeshes = this.getListOfMeshes() as THREE.Mesh[];
		const otherMeshes = allMeshes.filter(m => m !== mesh);
		mesh.userData.intersectsOtherMesh = otherMeshes.some(m => WorkspaceMeshManager.checkCollision(mesh, m));

		const meshBoundingBox = new THREE.Box3().setFromObject(mesh);
		let inappropriatePosition = !this.buildAreaVolume.containsBox(meshBoundingBox);
		if (this.purgeArea !== undefined) {
			let purgeBoundingBox = new THREE.Box3().setFromObject(this.purgeArea);
			inappropriatePosition = inappropriatePosition || purgeBoundingBox.intersectsBox(meshBoundingBox);
		}
		mesh.userData.outsideBuildEnvelope = inappropriatePosition;

		const material = mesh.material as THREE.MeshLambertMaterial;
		material.transparent = true;
		material.opacity = 0.7;
		if (mesh.userData.outsideBuildEnvelope || mesh.userData.intersectsOtherMesh) {
			material.color.setHex(partColors.error);
		} else {
			material.color.setHex(partColors.standard);
		}
	}

	private static checkCollisionOld(
		object1: THREE.Object3D,
		object2: THREE.Object3D,
		radius = 0.0
	): boolean {
		const checkCollisionAccurate = (object1, object2) => {
			const get2DConvexHull = (object) => {
				try {
					let mesh = object as THREE.Mesh
					if (!mesh)
						return [];
					const geom = mesh.geometry as THREE.BufferGeometry
					const points = geom.attributes.position.array;
					const points2d: any[] = [];
					for (let i = 0; i < points.length; i++) {
						if (typeof points[i * 3] === 'undefined') continue;
						if (typeof points[i * 3 + 1] === 'undefined') continue;
						points2d.push([points[i * 3], points[i * 3 + 1]]);
					}
					let convex = convexHull(points2d)
					console.log(geom)
					return convex
				}
				catch (err) {
					console.error(err)
				}
			}

			let len = (dx, dy) => {
				return Math.sqrt(dx * dx + dy * dy)
			}

			let dotProduct = (b1, b2) => {
				return b1[0] * b2[0] + b1[1] * b2[1]
			}

			let normalize = (dx, dy) => {
				let vlen = len(dx, dy)
				return [dx / vlen, dy / vlen]
			}

			const getNorm = (p1, p2) => {
				let dx = p2[0] - p1[0]
				let dy = p2[1] - p1[1]
				return normalize(dx, dy)
			}

			const offset = (poligon: number[][], offset) => {
				if (offset < 0.0001)
					return poligon;
				let output: number[][] = []
				for (let i = 0; i < poligon.length; i++) {
					let prevPoint = i - 1 > 0 ? poligon[i - 1] : poligon[poligon.length - 1]
					let nextPoint = i + 1 < poligon.length ? poligon[i + 1] : poligon[0]
					let currPoint = poligon[i]
					const n1 = getNorm(prevPoint, currPoint)
					const n2 = getNorm(currPoint, nextPoint)
					let bis = normalize(n1[0] + n2[0], n1[1] + n2[1])
					let l = offset / Math.sqrt(1 + dotProduct(n1, n2))
					output.push([currPoint[0] + l * bis[0], currPoint[1] + l * bis[1]])
				}
				return output
			}

			let getCoeffs = (poligon) => {
				let coeffs: number[][] = []
				for (let ind = 0; ind < poligon.length; ind++) {
					let nextValue = ind + 1 < poligon.length ? poligon[ind] : poligon[0]
					let dx = (nextValue[0] - poligon[ind][0])
					if (Math.abs(dx) < 0.000001)
						dx = 0.000001
					coeffs.push([(nextValue[1] - poligon[ind][1]) / dx, poligon[ind][1] - nextValue[1]])
				}
				return coeffs
			}
			let offsettedHull1 = offset(get2DConvexHull(object1), radius)
			let offsettedHull2 = offset(get2DConvexHull(object2), radius)

			let offsettedCoeffs1 = getCoeffs(offsettedHull1)
			let offsettedCoeffs2 = getCoeffs(offsettedHull2)

			for (let ind1 = 0; ind1 < offsettedHull1.length; ind1++) {
				let nextValue1 = ind1 + 1 < offsettedHull1.length ? offsettedHull1[ind1] : offsettedHull1[0]
				let min1 = [Math.min(nextValue1[0], offsettedHull1[ind1][0]), Math.min(nextValue1[1], offsettedHull1[ind1][1])]
				let max1 = [Math.max(nextValue1[0], offsettedHull1[ind1][0]), Math.max(nextValue1[1], offsettedHull1[ind1][1])]
				for (let ind2 = 0; ind2 < offsettedHull2.length; ind2++) {
					let nextValue2 = ind1 + 1 < offsettedHull1.length ? offsettedHull1[ind1] : offsettedHull1[0]
					let min2 = [Math.min(nextValue2[0], offsettedHull2[ind1][0]), Math.min(nextValue2[1], offsettedHull2[ind1][1])]
					let max2 = [Math.max(nextValue2[0], offsettedHull2[ind1][0]), Math.max(nextValue2[1], offsettedHull2[ind1][1])]
					if ((min1[0] <= max2[0]) && (max1[0] >= min2[0]) && (min1[1] <= max2[1]) && (max1[1] >= min2[1])) {
						let intersectPoint = (offsettedCoeffs2[ind2][1] - offsettedCoeffs1[ind1][1]) / (offsettedCoeffs2[ind2][1] - offsettedCoeffs1[ind1][1])
						if (intersectPoint >= min1[0] && intersectPoint <= max1[0] && intersectPoint >= min2[0] && intersectPoint <= max2[0])
							return true
					}
				}
			}
			return false
		}


		let bb1 = new THREE.Box3().setFromObject(object1);
		let bb2 = new THREE.Box3().setFromObject(object2);
		return bb1.intersectsBox(bb2);
		//if (bb1.intersectsBox(bb2)) {
		//	return checkCollisionAccurate(object1, object2);
		//}
		//return false
	}

	private static checkCollision(
		object1: THREE.Object3D,
		object2: THREE.Object3D,
		radius = 0.0
	): boolean {
		const checkCollisionAccurate = (object1, object2) => {
			const get2DConvexHull = (object) => {
				try {
					let mesh = object as THREE.Mesh
					if (!mesh)
						return [];
					const geom = mesh.geometry as THREE.BufferGeometry
					const points = geom.attributes.position.array;
					const points2d: any[] = [];
					for (let i = 0; i < points.length; i++) {
						if (typeof points[i * 3] === 'undefined') continue;
						if (typeof points[i * 3 + 1] === 'undefined') continue;
						points2d.push([points[i * 3], points[i * 3 + 1]]);
					}
					let convex = convexHull(points2d)
					console.log(geom)
					return convex
				}
				catch (err) {
					console.error(err)
				}
			}

			let len = (dx, dy) => {
				return Math.sqrt(dx * dx + dy * dy)
			}

			let dotProduct = (b1, b2) => {
				return b1[0] * b2[0] + b1[1] * b2[1]
			}

			let normalize = (dx, dy) => {
				let vlen = len(dx, dy)
				return [dx / vlen, dy / vlen]
			}

			const getNorm = (p1, p2) => {
				let dx = p2[0] - p1[0]
				let dy = p2[1] - p1[1]
				return normalize(dx, dy)
			}

			const offset = (poligon: number[][], offset) => {
				if (offset < 0.0001)
					return poligon;
				let output: number[][] = []
				for (let i = 0; i < poligon.length; i++) {
					let prevPoint = i - 1 > 0 ? poligon[i - 1] : poligon[poligon.length - 1]
					let nextPoint = i + 1 < poligon.length ? poligon[i + 1] : poligon[0]
					let currPoint = poligon[i]
					const n1 = getNorm(prevPoint, currPoint)
					const n2 = getNorm(currPoint, nextPoint)
					let bis = normalize(n1[0] + n2[0], n1[1] + n2[1])
					let l = offset / Math.sqrt(1 + dotProduct(n1, n2))
					output.push([currPoint[0] + l * bis[0], currPoint[1] + l * bis[1]])
				}
				return output
			}

			let getCoeffs = (poligon) => {
				let coeffs: number[][] = []
				for (let ind = 0; ind < poligon.length; ind++) {
					let nextValue = ind + 1 < poligon.length ? poligon[ind] : poligon[0]
					let dx = (nextValue[0] - poligon[ind][0])
					if (Math.abs(dx) < 0.000001)
						dx = 0.000001
					coeffs.push([(nextValue[1] - poligon[ind][1]) / dx, poligon[ind][1] - nextValue[1]])
				}
				return coeffs
			}
			let offsettedHull1 = offset(get2DConvexHull(object1), radius)
			let offsettedHull2 = offset(get2DConvexHull(object2), radius)

			let offsettedCoeffs1 = getCoeffs(offsettedHull1)
			let offsettedCoeffs2 = getCoeffs(offsettedHull2)

			for (let ind1 = 0; ind1 < offsettedHull1.length; ind1++) {
				let nextValue1 = ind1 + 1 < offsettedHull1.length ? offsettedHull1[ind1] : offsettedHull1[0]
				let min1 = [Math.min(nextValue1[0], offsettedHull1[ind1][0]), Math.min(nextValue1[1], offsettedHull1[ind1][1])]
				let max1 = [Math.max(nextValue1[0], offsettedHull1[ind1][0]), Math.max(nextValue1[1], offsettedHull1[ind1][1])]
				for (let ind2 = 0; ind2 < offsettedHull2.length; ind2++) {
					let nextValue2 = ind1 + 1 < offsettedHull1.length ? offsettedHull1[ind1] : offsettedHull1[0]
					let min2 = [Math.min(nextValue2[0], offsettedHull2[ind1][0]), Math.min(nextValue2[1], offsettedHull2[ind1][1])]
					let max2 = [Math.max(nextValue2[0], offsettedHull2[ind1][0]), Math.max(nextValue2[1], offsettedHull2[ind1][1])]
					if ((min1[0] <= max2[0]) && (max1[0] >= min2[0]) && (min1[1] <= max2[1]) && (max1[1] >= min2[1])) {
						let intersectPoint = (offsettedCoeffs2[ind2][1] - offsettedCoeffs1[ind1][1]) / (offsettedCoeffs2[ind2][1] - offsettedCoeffs1[ind1][1])
						if (intersectPoint >= min1[0] && intersectPoint <= max1[0] && intersectPoint >= min2[0] && intersectPoint <= max2[0])
							return true
					}
				}
			}
			return false
		}


		let bb1 = new THREE.Box3().setFromObject(object1);
		let bb2 = new THREE.Box3().setFromObject(object2);
		return bb1.intersectsBox(bb2);
		//if (bb1.intersectsBox(bb2)) {
		//	return checkCollisionAccurate(object1, object2);
		//}
		//return false
	}


	public newDownloadObjectFile = async (fileType: string) => {

		const getResult = await Storage.get(fileType, {
			download: true,
		})
		return await (getResult as any).Body.arrayBuffer();
	};
	public graphqlGetParts = async (PartID: string) => {
		return await API.graphql(
			graphqlOperation(getParts, {
				id: PartID,
			})
		);
	};
	private downloadNewMesh = async (part: IPartInfo) => {
		const uuidLoading = uuidv4();
		this.dispatch({
			type: 'addLoading',
			cargo: {
				uuidLoading: uuidLoading
			}
		})

		this.graphqlGetParts(part.properties.PartID).then(
			obj => {
				// console.log('graphqlGetParts obj~~~',obj)
				const filesList = obj["data"]["getParts"]["files"];
				const filesListObj = JSON.parse(filesList);
				const typeToLoad = filesListObj['data_small'] ? "data_small" : "data"
				let meshFileName = filesListObj[typeToLoad];
				console.log('Started~~~')
				this.newDownloadObjectFile(meshFileName)
					.then((meshFile: ArrayBuffer) => {
						// dataStorage().getObjectFilePath(
						//     "Parts",
						//     part.properties.PartID,
						//     typeToLoad
						// ).then( meshFileName => {
						console.log('completed~~~')
						let ext = meshFileName.split(".").pop().toLowerCase();
						let mesh = WorkspaceMeshManager.parseMesh(meshFile, ext);


						mesh.name = `Part ${part.UUID}.${ext}`;
						let userData: any = mesh.userData;
						userData.UUID = part.UUID;
						userData.PartID = part.properties.PartID;
						this.snapObjectToFloor(mesh);
						this.findAndHighlightPositionErrors(mesh);

						part.mesh = mesh;
						this.findPairForPart(part)!.mesh = mesh;
						mesh.scale.set(this.materialScale.x, this.materialScale.y, this.materialScale.z);

						this.scene.scene.add(mesh);
						this.syncPartAndMesh(part);
					})

					.catch((ex) => {
						NotificationManager.error(
							`Couldn't download mesh for Part ${part.properties.PartID}. ${ex}`
						);
					})
					.finally(() => {
						this.dispatch({
							type: 'removeLoading',
							cargo: {
								uuidLoading: uuidLoading
							}
						})
					})
				// })
			}
		)
			.catch(() => {
				NotificationManager.error(
					`Couldn't download mesh for Part ${part.properties.PartID}.`
				);
			});

	}

	private downloadMesh(part: IPartInfo) {
		const uuidLoading = uuidv4();
		this.dispatch({
			type: 'addLoading',
			cargo: {
				uuidLoading: uuidLoading
			}
		})


		dataStorage().objectStores['Parts'].getObject(part.properties.PartID).then(
			obj => {
				const typeToLoad = obj.files['data_small'] ? "data_small" : "data"
				dataStorage()
					.downloadObjectFile("Parts", part.properties.PartID, typeToLoad)
					.then((meshFile: ArrayBuffer) => {
						dataStorage().getObjectFilePath(
							"Parts",
							part.properties.PartID,
							typeToLoad
						).then(meshFileName => {
							let ext = meshFileName.split(".").pop().toLowerCase();
							let mesh = WorkspaceMeshManager.parseMesh(meshFile, ext);


							mesh.name = `Part ${part.UUID}.${ext}`;
							let userData: any = mesh.userData;
							userData.UUID = part.UUID;
							userData.PartID = part.properties.PartID;
							this.snapObjectToFloor(mesh);
							this.findAndHighlightPositionErrors(mesh);

							part.mesh = mesh;
							this.findPairForPart(part)!.mesh = mesh;
							mesh.scale.set(this.materialScale.x, this.materialScale.y, this.materialScale.z);

							this.scene.scene.add(mesh);
							this.syncPartAndMesh(part);
						})

							.catch((ex) => {
								NotificationManager.error(
									`Couldn't download mesh for Part ${part.properties.PartID}. ${ex}`
								);
							})
							.finally(() => {
								this.dispatch({
									type: 'removeLoading',
									cargo: {
										uuidLoading: uuidLoading
									}
								})
							})
					})
			}
		)
			.catch(() => {
				NotificationManager.error(
					`Couldn't download mesh for Part ${part.properties.PartID}.`
				);
			});

	}

	syncPartAndMesh(part: IPartInfo) {
		let transform_alias = {
			position: "translate",
			rotation: "rotate",
			scale: "scale",
		};
		let partMesh = part.mesh;

		["position", "rotation", "scale"].forEach((property) => {
			const propProperty = part.properties[transform_alias[property]];
			let sceneProperty = partMesh![property];

			if (propProperty && !sceneProperty.equals(propProperty)) {
				if (property === "rotation") {
					sceneProperty.copy(
						new THREE.Euler(propProperty.x, propProperty.y, propProperty.z)
					);
				} else {
					sceneProperty.copy(propProperty);
				}
				this.findAndHighlightPositionErrors(partMesh!);
			} else {
				if (property === "rotation") {
					sceneProperty = {
						x: sceneProperty._x,
						y: sceneProperty._y,
						z: sceneProperty._z,
					};
				}
				this.dispatch({
					type: "transform",
					cargo: {
						entityUUID: part.UUID,
						properties: {
							[transform_alias[property]]: sceneProperty,
						},
					},
				});
			}
		});
	}

	addListener(component: any) {
		this.listeners.push(component);
	}

	private deleteMesh(mesh: THREE.Mesh) {
		this.scene.scene.remove(mesh);
		mesh.geometry.dispose();
		(mesh.material as THREE.MeshLambertMaterial).dispose();

		for (let listener of this.listeners) {
			listener.onDelete(mesh);
		}
	}

	private static parseMesh(buffer: ArrayBuffer, ext: string): THREE.Mesh {
		switch (ext) {
			case "stl":
				let stlobject = stlLoader.parse(buffer);
				return new THREE.Mesh(
					stlobject,
					new THREE.MeshLambertMaterial({ color: partColors.standard })
				);
			case "ply":
				let plyObject = plyLoader.parse(buffer);

				if (plyObject.attributes.normal === undefined) {
					plyObject.computeVertexNormals();
				}

				return new THREE.Mesh(
					plyObject,
					new THREE.MeshLambertMaterial({ color: partColors.standard })
				);
			default:
				NotificationManager.error(`.${ext} format is unsuported`);
				return new THREE.Mesh();
		}
	}

	snapObjectToFloor(snapedMesh: THREE.Object3D) {
		let snapedBoundingBox = new THREE.Box3().setFromObject(snapedMesh);
		let pos = snapedMesh.position;
		snapedMesh.position.set(pos.x, pos.y, pos.z - snapedBoundingBox.min.z);
	}
}

export default WorkspaceMeshManager;
