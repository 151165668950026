import React, { lazy, Suspense } from "react";
import { Dropdown } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  onCancelBuild,
  onMoveBuildOpenDialog,
  onHighlightBuild,
  onRebuild,
} from "../../../Actions/Builds";
import {
  onDownloadGcode,
  onDownloadBundle,
  onDownloadLog,
} from "../../../Actions/Home";
import { IBuild, IDisplayPlate, IPlate, IState } from "../../../Interfaces";
import { onNavigateToBuildReviewPage } from "../../../Actions/Home";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { onNavigateToPlatesPage } from "../../../Actions/Home";
import IPartInfo from "../../../../interfaces/IPartInfo";
import DeleteBuildnModal from "./DeleteBuildModal";
// import ConfigDetailsModal from "./ConfigDetailsModal";
import { onDeleteBuild } from "../../../Actions/Builds";
import onGetHighlightedBuild from "../../../Actions/Search/highlightBuild";
import LoadingComponent from "../../../../LoadingComponent/LoadingComponent";
import HorizontalLoader from "../../horizontalLoader";
import { onHighlightPlate } from "../../../Actions/Plates";
import { onHighlightPlateObject } from "../../../Actions/Plates"
import onGetHighlightedPlate from '../../../Actions/Search/highlightPlate';
import { getPlate } from "../../../../graphql/queries";

const BuildConfigDetailsModal = lazy(() => import('./ConfigDetailsModal'));
const Props = (state: IState, ownProps: any) => {
  const loadedPlates = state.data.plates.loadedPlates as any;
  const isSearchEnabled = state.data.search.isSearchEnabled as boolean;
  const highlightBuild = state.data.search.highlightedBuildData as IBuild;
  const selectedFolder = state.data.folders.selectedFolderIds as any;

  const props = {
    loadedPlates: loadedPlates as any[],
    displayPlate: ownProps.displayPlate as IDisplayPlate,
    isSearchEnabled: isSearchEnabled as boolean,
    highlightBuild: highlightBuild as IBuild,
    selectedFolder: selectedFolder as any[]
  };

  return props;
};

const Actions = {
  onHighlightBuild: onHighlightBuild,
  onNavigateToBuildReviewPage: onNavigateToBuildReviewPage,
  onCancelBuild: onCancelBuild,
  onRebuild: onRebuild,
  onDeleteBuild: onDeleteBuild,
  onDownloadGcode: onDownloadGcode,
  onDownloadBundle: onDownloadBundle,
  onDownloadLog: onDownloadLog,
  onMoveBuildOpenDialog: onMoveBuildOpenDialog,
  onNavigateToPlatesPage: onNavigateToPlatesPage,
  onGetHighlightedBuild: onGetHighlightedBuild,
  onHighlightPlate: onHighlightPlate,
  onHighlightPlateObject: onHighlightPlateObject,
  onGetHighlightedPlate: onGetHighlightedPlate,
};

class Component extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteConfirmationModal: false,
      showConfigDetailsModal: false,
      isConfigDisabled : false
    };
  }

  

  getBuild = async (id) => {
    await this.props.onGetHighlightedBuild(id);
  };
  async componentDidMount() {
    Auth.currentAuthenticatedUser().then((user) => {
      //console.log(user);

      const groups =
        user.signInUserSession.accessToken.payload["cognito:groups"];

      //console.log(groups);

      if (groups == "Operator") {
        this.setState({isConfigDisabled:true})
      } else {
        this.setState({isConfigDisabled:false})
      }
    });
  }

  onNavigateToPlatesPage = async (plateId) => {
    const isPlateAvailable = this.props.loadedPlates.some(
      (lp) => lp.id === plateId
    );
    let plateToBeEdited: IPlate = {} as IPlate;
    if (!isPlateAvailable) {
      try {
        const getSelectedPlate = await API.graphql(
          graphqlOperation(getPlate, {
            id: plateId,
          })
        );
        plateToBeEdited = (getSelectedPlate as any).data.getPlate;
        this.props.onHighlightPlate(plateId);
      } catch (ex: any) {
        console.log(`Exception ${ex.toString()} getting Plates`);
      }
      this.props.onHighlightPlateObject(plateToBeEdited);
    }
    else{
      this.props.onHighlightPlate(plateId);
       plateToBeEdited = this.props.loadedPlates.find(lp => lp.id === plateId) as IPlate;
    }
    const partIds = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean)
      .map((part) => part.properties.PartID)
      .filter(Boolean) as string[];
    const parts = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean) as IPartInfo[];
    const partConfigurationIds = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean)
      .map((part) => part.properties.PartConfig["originalJson"]["id"])
      .filter(Boolean);
    const partSlicerConfigurationIds = plateToBeEdited.parts
      .filter(Boolean)
      .map((stringifiedPart) => JSON.parse(stringifiedPart))
      .filter(Boolean)
      .map((part) => part.properties.SlicerConfig["originalJson"]["id"])
      .filter(Boolean);
    const plateConfigurationIds = [
      plateToBeEdited.machineId,
      plateToBeEdited.millConfigId,
      plateToBeEdited.materialConfigId,
      plateToBeEdited.recipeId,
    ];

    const ids = {
      partIds,
      parts,
      partConfigurationIds,
      partSlicerConfigurationIds,
      plateConfigurationIds,
    };

    this.props.onNavigateToPlatesPage(ids, this.props.history, false);
  };

  handleModalClose = () =>
    this.setState({ showDeleteConfirmationModal: false });
  handleModalShow = () => this.setState({ showDeleteConfirmationModal: true });
  handleConfigDetailsModalShow = () =>
    this.setState({ showConfigDetailsModal: true });
  handleConfigDetailsModalHide = () =>
    this.setState({ showConfigDetailsModal: false });
    isValidJSON(data: any): boolean {
      if (typeof data !== 'string') {
        return false;
      }
      try {
        const json = JSON.parse(data);
        return json && typeof json === 'object';
      } catch (error) {
        return false;
      }
    }
  public render() {
    let associatedPlate = "" as string;
   
    if (!this.props.isSearchEnabled && this.props.displayBuild?.hasPlates) {
      const plateData = this.props.displayBuild.plates[0];
      associatedPlate = this.isValidJSON(plateData) ? JSON.parse(plateData)["name"] : plateData;
    }

    return (
      <>
        <Suspense
          fallback={<LoadingComponent visible={false}></LoadingComponent>}
        >
          <BuildConfigDetailsModal
            showModal={this.state.showConfigDetailsModal}
            hideModal={this.handleConfigDetailsModalHide}
            highlightedBuildId={
              !this.props.isSearchEnabled
                ? this.props.displayBuild.id
                : this.props.highlightBuild.id
            }
            buildPlatedId={
              !this.props.isSearchEnabled
                ? this.props.displayBuild.plates?.name
                : this.props.highlightBuild?.plates?.name
            }
            buildInProgress={
              !this.props.isSearchEnabled
                ? this.props.displayBuild.current_status
                : this.props.highlightBuild?.current_status
            }
            showConfigDetailsModal={this.state.showConfigDetailsModal}
          ></BuildConfigDetailsModal>
        </Suspense>

        {/* <ConfigDetailsModal
          
        ></ConfigDetailsModal> */}

        <DeleteBuildnModal
          showModal={this.state.showDeleteConfirmationModal}
          hideModal={this.handleModalClose}
          highlightedBuildId={
            !this.props.isSearchEnabled
              ? this.props.displayBuild.id
              : this.props.highlightBuild.id
          }
          handleDelete={() => {
            this.props.onDeleteBuild(
              !this.props.isSearchEnabled
                ? this.props.displayBuild.id
                : this.props.highlightBuild.id,
              this.props.history
            );
            this.handleModalClose();
          }}
        ></DeleteBuildnModal>

        <Dropdown
          onToggle={(isOpen, event) => {
            event.stopPropagation();
            return (
              isOpen &&
              (!this.props.isSearchEnabled
                ? this.props.onHighlightBuild(this.props.displayBuild.id)
                : this.props.onGetHighlightedBuild(this.props.displayBuild.id))
            );
          }}
        >
          <Dropdown.Toggle id="dropdown-basic" variant="light">
            <div className="options right-justify">
              <div className="more-check option">
                <img className="icon more-check" alt="" />
              </div>
            </div>
          </Dropdown.Toggle>

          {!this.props.isSearchEnabled && (
            <>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    this.props.onRebuild(this.props.displayBuild.id)
                  }
                >
                  Rebuild
                </Dropdown.Item>
                <Dropdown.Item onClick={this.handleModalShow}>
                  Delete
                </Dropdown.Item>

                <Dropdown.Item
                  disabled={
                    this.props.selectedFolder.length === 0
                      ? this.props.displayBuild.current_status !== "completed"
                      : this.props.displayBuild.status !== "completed"
                  }
                  onClick={() =>
                    this.props.onNavigateToBuildReviewPage(
                      this.props.displayBuild.id,
                      this.props.history
                    )
                  }
                >
                  Review Build
                </Dropdown.Item>
                <Dropdown.Item onClick={this.handleConfigDetailsModalShow}>
                  Config details
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={!this.props.displayBuild?.hasPlates}
                  onClick={() => this.onNavigateToPlatesPage(associatedPlate)}
                >
                  Plate Details - {associatedPlate}
                </Dropdown.Item>
                <Dropdown.Item
                  disabled={
                    this.props.selectedFolder.length === 0
                      ? ["completed", "fail", "canceled"].includes(
                        this.props.displayBuild.current_status
                      )
                      : ["completed", "fail", "canceled"].includes(
                        this.props.displayBuild.status
                      )
                  }
                  onClick={() =>
                    this.props.onCancelBuild(this.props.displayBuild.id)
                  }
                >
                  Stop
                </Dropdown.Item>
                {!this.state.isConfigDisabled ? (
                <Dropdown.Item
                  disabled={
                    this.props.selectedFolder.length === 0
                      ? this.props.displayBuild.current_status !== "completed"
                      : this.props.displayBuild.status !== "completed"
                  }
                  onClick={() =>
                    this.props.onDownloadGcode(this.props.displayBuild.id)
                  }
                >
                  Download gCode
                </Dropdown.Item>
                 ) : null}
                {!this.state.isConfigDisabled ? (
                  <Dropdown.Item
                    onClick={() =>
                      this.props.onDownloadBundle(this.props.displayBuild.id)
                    }
                  >
                    Download Bundle
                  </Dropdown.Item>
                ) : null}
                {!this.state.isConfigDisabled ? (
                <Dropdown.Item
                  disabled={
                    this.props.selectedFolder.length === 0
                      ? !["completed", "fail"].includes(
                        this.props.displayBuild.current_status
                      )
                      : !["completed", "fail"].includes(
                        this.props.displayBuild.status
                      )
                  }
                  onClick={() =>
                    this.props.onDownloadLog(this.props.displayBuild.id)
                  }
                >
                  Download Log
                </Dropdown.Item>
                ) : null}
                <Dropdown.Item
                  onClick={() =>
                    this.props.onMoveBuildOpenDialog(this.props.displayBuild.id)
                  }
                >
                  Move
                </Dropdown.Item>
              </Dropdown.Menu>
            </>
          )}

          {this.props.isSearchEnabled && (
            <>
              <Dropdown.Menu>
                {Object.keys(this.props.highlightBuild).length === 0 ? (
                  <Dropdown.Item style={{ height: "300px" }}>
                    <HorizontalLoader></HorizontalLoader>
                  </Dropdown.Item>
                ) : (
                  <>
                    <Dropdown.Item
                      onClick={() =>
                        this.props.onRebuild(this.props.highlightBuild.id)
                      }
                    >
                      Rebuild
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={this.handleModalShow}
                      disabled={
                        this.props.highlightBuild.current_status == "processing"
                      }
                    >
                      Delete
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={
                        this.props.highlightBuild.current_status !== "completed"
                      }
                      onClick={() =>
                        this.props.onNavigateToBuildReviewPage(
                          this.props.highlightBuild.id,
                          this.props.history
                        )
                      }
                    >
                      Review Build
                    </Dropdown.Item>
                    <Dropdown.Item onClick={this.handleConfigDetailsModalShow}>
                      Config details
                    </Dropdown.Item>
                    {
                        this.props.highlightBuild?.plates !== null && (
                          <Dropdown.Item
                            onClick={() => this.onNavigateToPlatesPage(JSON.parse(this.props.highlightBuild?.plates[0])["name"])}
                          >
                            Plate Details - {JSON.parse(this.props.highlightBuild?.plates[0])["name"]}
                          </Dropdown.Item>

                        )
                    }
                    
                    <Dropdown.Item
                      disabled={
                        this.props.highlightBuild.current_status ===
                        "completed" ||
                        this.props.highlightBuild.current_status === "fail" ||
                        this.props.highlightBuild.current_status === "canceled"
                      }
                      onClick={() =>
                        this.props.onCancelBuild(this.props.highlightBuild.id)
                      }
                    >
                      Stop
                    </Dropdown.Item>
                    {!this.state.isConfigDisabled ? (
                    <Dropdown.Item
                      disabled={
                        this.props.highlightBuild.current_status !== "completed"
                      }
                      onClick={() =>
                        this.props.onDownloadGcode(this.props.highlightBuild.id)
                      }
                    >
                      Download gCode
                    </Dropdown.Item>
                    ) : null}
                    {!this.state.isConfigDisabled ? (
                      <Dropdown.Item
                        onClick={() =>
                          this.props.onDownloadBundle(
                            this.props.highlightBuild.id
                          )
                        }
                      >
                        Download Bundle
                      </Dropdown.Item>
                    ) : null}
                    {!this.state.isConfigDisabled ? (
                    <Dropdown.Item
                      disabled={
                        this.props.highlightBuild.current_status !==
                        "completed" &&
                        this.props.highlightBuild.current_status !== "fail"
                      }
                      onClick={() =>
                        this.props.onDownloadLog(this.props.highlightBuild.id)
                      }
                    >
                      Download Log
                    </Dropdown.Item>
                    ) : null}

                    <Dropdown.Item
                      onClick={() =>
                        this.props.onMoveBuildOpenDialog(
                          this.props.highlightBuild.id
                        )
                      }
                    >
                      Move
                    </Dropdown.Item>
                  </>
                )}
              </Dropdown.Menu>
            </>
          )}
        </Dropdown>
      </>
    );
  }
}

const DisplayBuildOptions = withRouter(connect(Props, Actions)(Component));

export default DisplayBuildOptions;
