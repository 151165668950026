import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { OverlayTrigger, Tooltip, } from 'react-bootstrap';
import { SortDirection, TabIndex } from '../../../Enums';
import { IState } from '../../../Interfaces';
import { onLoadParts, onLoadPlates, onLoadBuilds, onHomePageTabChange } from '../../../Actions/Home';
import { onSearchPartsTextChange } from '../../../Actions/Parts';
import { onSearchPlatesTextChange } from '../../../Actions/Plates';
import { onShowFolderView, onShowListView } from '../../../Actions/Prepare';
import { onCollapseAccordions, onExpandAccordions } from '../../../Actions/Folders';
import { onSearchBuildsTextChange, onSortBuildsAsc, onSortBuildsDesc } from '../../../Actions/Builds';
import { onSearch, onResetSearch } from "../../../Actions/Search";

const Props = (state: IState, ownProps: any) => {

	const props = {
		showFolderView: state.data.info.showFolderView as boolean,
		showListView: state.data.info.showListView as boolean,
		isLoadingComplete: (state.data.parts.isLoadingComplete &&
			state.data.builds.isLoadingComplete &&
			state.data.plates.isLoadingComplete) as boolean,
		noOfParts: state.data.parts.loadedParts.length as number,
		noOfPlates: state.data.plates.loadedPlates.length as number,
		noOfBuilds: state.data.builds.loadedBuilds.length as number,
		// sortDirection: state.data.parts.sortDirection as SortDirection,
		// isSortDirectionDesc: state.data.parts.sortDirection === SortDirection.DESC,
		// isSortDirectionAsc: state.data.parts.sortDirection === SortDirection.ASC,
		sortDirection: state.data.builds.sortDirection as SortDirection,
		isSortDirectionDesc: state.data.builds.sortDirection === SortDirection.DESC,
		isSortDirectionAsc: state.data.builds.sortDirection === SortDirection.ASC,
		// searchText: (state.data.parts.searchText && state.data.plates.searchText && state.data.builds.searchText) as string,
		selectedTabIndex: state.data.info.homePageTabIndex as number,
		searchText: state.data.builds.searchText,
		buildsSearchText: state.data.builds.searchText,
		partsSearchText: state.data.parts.searchText,
		platesSearchText: state.data.plates.searchText,
		isSearchEnabled :state.data.search.isSearchEnabled,
		searchString: state.data.search.searchString ? state.data.search.searchString: "",
		partsSearchResultsLength : state.data.search.partsSearchCompleted ? state.data.search.searchPartsResults.length:0,
		platesSearchResultsLength: state.data.search.platesSearchCompleted ? state.data.search.searchPlatesResults.length : 0,
		buildsSearchResultsLength: state.data.search.buildsSearchCompleted ? state.data.search.searchBuildsResults.length : 0

	};

	return props;
};

const Actions = {
	onShowFolderView: onShowFolderView,
	onShowListView: onShowListView,
	onSearchPartsTextChange: onSearchPartsTextChange,
	onSearchPlatesTextChange: onSearchPlatesTextChange,
	onSearchBuildsTextChange: onSearchBuildsTextChange,
	onCollapseAccordions: onCollapseAccordions,
	onExpandAccordions: onExpandAccordions,
	onLoadParts: onLoadParts,
	onLoadPlates: onLoadPlates,
	onLoadBuilds: onLoadBuilds,
	onHomePageTabChange:  onHomePageTabChange ,
	onSearch: onSearch,
	onResetSearch: onResetSearch
};

const AllViewHeaderMethod = (props, event) => {
	const [inputValue, setInputValue] = useState('');
	const [searchInputValue, setSearchInputValue]=useState('')

	const handleUserInputOnChange = (event) => {
		setInputValue(event?.target?.value)
		const value = event?.target?.value;
		if ((event?.key === 'Enter' && value.length >= 3) || value === "") {
			let willLoad = false;
			if (props.partsSearchText !== value) {
				willLoad = true;
				props.onSearchPartsTextChange(value);
				props.onLoadParts(true);
			}
			if (props.platesSearchText !== value) {
				willLoad = true;
				props.onSearchPlatesTextChange(value);
				props.onLoadPlates(true);
			}
			if (props.buildsSearchText !== value) {
				willLoad = true;
				props.onSearchBuildsTextChange(value);
				props.onLoadBuilds(true);
			}
			if (!willLoad && value === '') {
				props.onFilter(value);
			}
		}
		else {
			props.onFilter(value);
		}
	}

	const resetInputField = () => {
		setInputValue('')
		if (props.partsSearchText !== '') {
			props.onSearchPartsTextChange('');
			props.onLoadParts(true);
		}
		if (props.platesSearchText !== '') {
			props.onSearchPlatesTextChange('');
			props.onLoadPlates(true);
		}
		if (props.buildsSearchText !== '') {
			props.onSearchBuildsTextChange('');
			props.onLoadBuilds(true);
		}
	};

	useEffect(() => {
		if (props.isLoadingComplete) {
			props.onFilter(undefined);
		}
	}, [props.isLoadingComplete]);
	useEffect(() => { 
		if (!props.isSearchEnabled){
			setSearchInputValue("")
		}

	}, [props.isSearchEnabled])
	useEffect(() => {
		setInputValue(props.searchText);
		//props.onSearchPartsTextChange('') &&
		//props.onSearchPlatesTextChange('') &&
		//props.onSearchBuildsTextChange('')
	}, [props.searchText]);
	

	const handleSearchInputChange =(event)=>{setSearchInputValue(event?.target?.value)}
	const handleSubmit = (event) => {
		event.preventDefault();
		if (searchInputValue && searchInputValue.length >= 3) {
		props.onSearch(searchInputValue.toLowerCase(), props.selectedTabIndex)
		}
	};
	const handleClear =()=>{
		props.onResetSearch()
		setSearchInputValue("")
	}

	return (
		<>
			<header>
				<div className='options border-b px-5 py-2'>
					{
						!props.isSearchEnabled &&(
							<>
								<div className='sort option'>
									<OverlayTrigger
										placement='top'
										overlay={
											<Tooltip id={props.sorted ? 'icon-desc-sort' : 'icon-asc-sort'}>
												Sorting
											</Tooltip>
										}
									>
										<img
											className={`icon ${props.sorted ? 'desc-sort' : 'asc-sort'}`}
											aria-disabled={!props.isLoadingComplete}
											alt={props.sorted ? 'DSC' : 'ASC'}
											onClick={() => props.onSort()}
										/>
									</OverlayTrigger>
								</div>
							</>
						)
					}
					
					{!props.isSearchEnabled && (<>
						{
							props.showListView ?
								(
									<>
										<div className='list-view option'>
											<OverlayTrigger
												placement='top'
												overlay={
													<Tooltip id='icon-list-view'>
														Card View
													</Tooltip>
												}
											>
												<img
													className='icon list-view'
													aria-disabled={!props.isLoadingComplete}
													alt=''
													onClick={() => props.onShowListView()}
												/>
											</OverlayTrigger>
										</div>
									</>
								)
								:
								(
									<>
										<div className='card-view option'>
											<OverlayTrigger
												placement='top'
												overlay={
													<Tooltip id='icon-card-view'>
														List View
													</Tooltip>
												}
											>
												<img
													className='icon card-view'
													aria-disabled={!props.isLoadingComplete}
													alt=''
													onClick={() => props.onShowListView()}
												/>
											</OverlayTrigger>
										</div>
									</>
								)
						}
					</>)}
					

					<div className='search option w-50'>
						<div className='w-100 ml-3'>
							{/* <form ref={formRef}> */}
							<form onSubmit={handleSubmit} className='d-flex w-100'>
								<input type="text" autoComplete="off" name="focus" required id="advanced-search-box" className="w-100 advanced-search-box"
									placeholder='Search by name'
									disabled={!props.isLoadingComplete} value={searchInputValue} onChange={handleSearchInputChange}></input>
									<span className="close-icon" onClick={handleClear}></span> 
							</form>
							{/* <input type="text" autoComplete="off" name="focus" required id="advanced-search-box" className="advanced-search-box"
								placeholder='Search by name'
								disabled={!props.isLoadingComplete}
								onKeyUp={handleUserInputOnChange}
								onChange={handleUserInputOnChange}
								value={inputValue}
							/>
							<span className="close-icon" onClick={() => resetInputField()}></span> */}
							{/* </form> */}
						</div>
						{/* {props.isSearchEnabled && (<p className='clear-serach-button' onClick={handleClear}>Clear Search</p>)} */}
					</div>
					<div className='search-icons'>
						{/* <>
						{
							props.isLoadingComplete ?
								(
									<>
										<div className='pl-2 loading-status info'>
											<img
												className='icon loading-complete'
												alt='loading complete'
											/>
											<span>{`Loading completed, ${props.noOfParts + props.noOfPlates + props.noOfBuilds} items loaded`}</span>
										</div>
									</>
								)
								:
								(
									<>
										<div className='loading-status info'>
											<img
												className='icon loading-in-progress'
												alt='loading in progress'
											/>
											<span>{`Loading in progress, ${props.noOfParts + props.noOfPlates + props.noOfBuilds} items loaded`}</span>
										</div>
									</>
								)
						}
					</> */}
					<>
						<div className='option cursor-pointer'
						onClick={() => { props.onHomePageTabChange(TabIndex.PARTS); }}>
							<OverlayTrigger
								placement='top'
								overlay={
									<Tooltip id='icon-part'>
										Indicates Part
									</Tooltip>
								}
							>
								<img
									className='icon part'
									alt=''
								/> 
							</OverlayTrigger>
								Part ({props.isSearchEnabled ? props.partsSearchResultsLength : props.noOfParts}) 
						</div>
						<div className='option cursor-pointer'
						onClick={() => { props.onHomePageTabChange(TabIndex.PLATES); }}>
							<OverlayTrigger
								placement='top'
								overlay={
									<Tooltip id='icon-plate'>
										Indicates Plate
									</Tooltip>
								}
							>
								<img
									className='icon plate'
									alt=''
								/> 
							</OverlayTrigger>
								
								Plate ({props.isSearchEnabled ? props.platesSearchResultsLength : props.noOfPlates})
						</div>
						<div className='option cursor-pointer' 
						onClick={() => { props.onHomePageTabChange(TabIndex.BUILDS); }}>
							<OverlayTrigger
								placement='top'
								overlay={
									<Tooltip id='icon-build'>
										Indicates Build
									</Tooltip>
								}
							>
								<img
									className='icon build'
									alt=''
								/> 
							</OverlayTrigger>
								Build ({props.isSearchEnabled ? props.buildsSearchResultsLength : props.noOfBuilds})
						</div>
					</>
					{!props.isSearchEnabled &&
					<>
						<div className='check option'>
							<img
								className={props.showFolderView ? 'icon uncheck' : 'icon check'}
								alt=''
								onClick={(e) => props.onShowFolderView()}
							/>
							<span>View Folders</span>
						</div>
					</>
}
					{/* <>
						<div className='option'>
							<OverlayTrigger
								placement='top'
								overlay={
									<Tooltip id='icon-archive'>
										Archive
									</Tooltip>
								}
							>
								<img
									className='icon archive'
									aria-disabled={!props.isLoadingComplete}
									alt=''
									onClick={() => props.onCollapseAccordions()}
								/>
							</OverlayTrigger>
						</div>
					</> */}
					{/* <>
						<div className='option'>
							<OverlayTrigger
								placement='top'
								overlay={
									<Tooltip id='icon-side-preview'>
										Preview
									</Tooltip>
								}
							>
								<img
									className='icon side-preview'
									aria-disabled={!props.isLoadingComplete}
									alt=''
									onClick={() => props.onExpandAccordions()}
								/>
							</OverlayTrigger>
						</div>
					</> */}
					{/* <>
						<div className='option'>
							<OverlayTrigger
								placement='top'
								overlay={
									<Tooltip id='icon-copy'>
										Copy
									</Tooltip>
								}
							>
								<img
									className='icon copy'
									aria-disabled={!props.isLoadingComplete}
									alt=''
								/>
							</OverlayTrigger>
						</div>
					</> */}
					</div>
				</div>
			</header>
		</>
	);
}

const AllViewHeader = withRouter(connect(Props, Actions)(AllViewHeaderMethod));

export default AllViewHeader;
