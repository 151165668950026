import API, { graphqlOperation } from '@aws-amplify/api';
import { NotificationManager } from "react-notifications";
import { updatePlate } from '../../../../graphql/mutations';

import ACTION_TYPES from '../../../actionTypes';
import { getNewPlate } from '../../../Instances';
import { IPlate } from '../../../Interfaces';
import onUpdateOldConfiguraitonAfterUpdatePlate from './updateOldConfiguration';
import onUpdateNewConfiguraitonAfterUpdatePlate from './updateNewConfiguration';
import onUpdateOldPartAfterUpdatePlate from './updateOldPart';
import onUpdateNewPartAfterUpdatePlate from './updateNewPart';

const onUpdatePlateInit = () => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.INIT,
        payload: null,
    };
};

const onUpdatePlateSuccess = (updatedPlate: IPlate) => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.SUCCESS,
        payload: updatedPlate,
    };
};

const onUpdatePlateFail = (errorMessage: string) => {
    //NotificationManager.error('Failure on Update Plate');
    console.error('Failure on Update Plate - ', errorMessage);
    return {
        type: ACTION_TYPES.PLATE.UPDATE.FAIL,
        payload: errorMessage,
    };
};


const onUpdatePlateDone = () => {
    return {
        type: ACTION_TYPES.PLATE.UPDATE.DONE,
        payload: null,
    };
};

const onUpdatePlate = (history) => {
    return async (dispatch, getState) => {
        dispatch(onUpdatePlateInit());
        try {
            const state = getState();
            const plateIndex = state.data.plates.loadedPlates.find(p => p.id === state.data.plates.highlightedPlateId ? state.data.plates.highlightedPlateId : state.data.search.highlightedPlateData.id);

            const plateToBeUpdated = plateIndex ? { ...state.data.plates.loadedPlates.find(p => p.id === state.data.plates.highlightedPlateId ? state.data.plates.highlightedPlateId : state.data.search.highlightedPlateData.id) } as IPlate : state.data.search.highlightedPlateData;
            const oldPartConfigs = plateToBeUpdated.parts.map(part => JSON.parse(part).properties.PartConfig.resultJson.id);
            const oldSlicerConfigs = plateToBeUpdated.parts.map(part => JSON.parse(part).properties.SlicerConfig.resultJson.id);
            const oldConfigIdsToBeUpdated = ([] as string[]).concat(plateToBeUpdated.machineId, plateToBeUpdated.recipeId, plateToBeUpdated.millConfigId, plateToBeUpdated.materialConfigId, ...oldPartConfigs, ...oldSlicerConfigs) as string[];
            const oldConfigsToBeUpdated = state.data.configurations.loadedConfigurations.filter(lc => oldConfigIdsToBeUpdated.some(id => id === lc.id));

            const oldPartIdsToBeUpdated = plateToBeUpdated.parts.map(part => JSON.parse(part).properties.PartID);
            const oldPartsToBeUpdated = state.data.parts.loadedParts.filter(lp => oldPartIdsToBeUpdated.some(id => id === lp.id));

            const newPlate = getNewPlate(state);

            const newPartConfigs = newPlate.parts.map(part => JSON.parse(part).properties.PartConfig.resultJson.id);
            const newSlicerConfigs = newPlate.parts.map(part => JSON.parse(part).properties.SlicerConfig.resultJson.id);
            const newConfigIdsToBeUpdated = ([] as string[]).concat(newPlate.machineId, newPlate.recipeId, newPlate.millConfigId, newPlate.materialConfigId, ...newPartConfigs, ...newSlicerConfigs) as string[];
            const newConfigsToBeUpdated = state.data.configurations.loadedConfigurations.filter(lc => newConfigIdsToBeUpdated.some(id => id === lc.id));

            const newPartIdsToBeUpdated = newPlate.parts.map(part => JSON.parse(part).properties.PartID);
            const newPartsToBeUpdated = state.data.parts.loadedParts.filter(lp => newPartIdsToBeUpdated.some(id => id === lp.id));

            plateToBeUpdated.parts = newPlate.parts;
            plateToBeUpdated.machineConfig = newPlate.machineConfig;
            plateToBeUpdated.machineId = newPlate.machineId;
            plateToBeUpdated.millConfig = newPlate.millConfig;
            plateToBeUpdated.millConfigId = newPlate.millConfigId;
            plateToBeUpdated.materialConfig = newPlate.materialConfig;
            plateToBeUpdated.materialConfigId = newPlate.materialConfigId;
            plateToBeUpdated.recipe = newPlate.recipe;
            plateToBeUpdated.recipeId = newPlate.recipeId;
            plateToBeUpdated.modified_by = state.creator;
            plateToBeUpdated.modified_at = new Date().toISOString();

            delete plateToBeUpdated._deleted;
            delete plateToBeUpdated._lastChangedAt;
            delete plateToBeUpdated.createdAt;
            delete plateToBeUpdated.updatedAt;
            const variables = {
                input: plateToBeUpdated,
            };
            const result = await API.graphql(graphqlOperation(updatePlate, variables));
            const updatedPlate = ((result as any)?.data?.updatePlate) as IPlate;
            dispatch(onUpdatePlateSuccess(updatedPlate));

          //  oldPartsToBeUpdated.forEach(part => dispatch(onUpdateOldPartAfterUpdatePlate(part, updatedPlate)));
            newPartsToBeUpdated.forEach(part => dispatch(onUpdateNewPartAfterUpdatePlate(part, updatedPlate)));

          //  oldConfigsToBeUpdated.forEach(config => dispatch(onUpdateOldConfiguraitonAfterUpdatePlate(config, updatedPlate)));
            newConfigsToBeUpdated.forEach(config => dispatch(onUpdateNewConfiguraitonAfterUpdatePlate(config, updatedPlate)));
            NotificationManager.success(
                `Plate Updated Succesfully`
            );
        }
        catch (graphqlError) {
            const errorMessage = `Update plate failed: ${Object((graphqlError as any)?.errors?.[0]?.message).toString()}`;
            dispatch(onUpdatePlateFail(errorMessage));
        }
        dispatch(onUpdatePlateDone());
    };
};

export default onUpdatePlate;