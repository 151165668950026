import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DisplayBuildOptions } from "../..";
import { IDisplayPlate, IPlate, IState } from "../../../Interfaces";
import IPartInfo from "../../../../interfaces/IPartInfo";
import ProgressBar from "react-bootstrap/ProgressBar";
import { onNavigateToBuildPreviewPage } from "../../../Actions/Home";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { onSelectPart } from "../../../Actions/Parts";
import { onHighlightBuild } from "../../../Actions/Builds";
import { getBuild } from "../../../../graphql/queries";
import { Storage } from "aws-amplify";

const Props = (state: IState, ownProps: any) => {
	const props = {
		loadedBuild: state.data.builds.loadedBuilds.filter(e => e.id === ownProps.displayBuild.id)[0]
	};

	return props;
};

const Actions = {
	onNavigateToBuildPreviewPage: onNavigateToBuildPreviewPage,
	onSelectPart: onSelectPart,
	onHighlightBuild: onHighlightBuild,
};

const DisplayBuildsPreviewMethod = (props) => {
	const [reviewValues, setReviewValues] = useState({
		totalTime: 0,
		timeToPasteEnd: 0,
		pasteSpent: '',
	});
	const [isConfigDisabled, setIsConfigDiabled] = useState<Boolean>(false);

	useEffect(() => {
		Auth.currentAuthenticatedUser().then((user) => {
			const groups =
				user.signInUserSession.accessToken.payload["cognito:groups"];
			if (groups == "Operator") {
				setIsConfigDiabled(true);
			} else {
				setIsConfigDiabled(false);
			}
		});
	});

	useEffect(() => {
		LoadBuild();
	}, []);

	let buildProgress = 0;
	if (props.displayBuild.current_status === "processing" && props.displayBuild.build_result) {
		buildProgress = parseFloat(props.displayBuild.build_result.replace("%", "").split(" ")[1]);
		if (isNaN(buildProgress)) {
			buildProgress = 0;
		}
	}
	const LoadBuild = async () => {
		let partList: any[] = [];
		const loadedConfig = props.loadedBuild;
		loadedConfig.parts.forEach((a) => {
			partList.push(typeof a === "string" ? JSON.parse(a) : a);
		});
		let rBuild: any = loadedConfig;
		rBuild.parts = partList;
		rBuild.machineConfig =
			typeof loadedConfig.machineConfig === "string"
				? JSON.parse(loadedConfig.machineConfig)
				: loadedConfig.machineConfig;
		rBuild.millConfig =
			typeof loadedConfig.millConfig === "string"
				? JSON.parse(loadedConfig.millConfig)
				: loadedConfig.millConfig;
		rBuild.materialConfig =
			typeof loadedConfig.materialConfig === "string"
				? JSON.parse(loadedConfig.materialConfig)
				: loadedConfig.materialConfig;
		rBuild.recipe =
			typeof loadedConfig.recipe === "string"
				? JSON.parse(loadedConfig.recipe)
				: loadedConfig.recipe;
		rBuild.bundle = loadedConfig.bundle;
		if (loadedConfig.current_status === "completed") {
			await loadResult(rBuild)
		}
	};

	const loadResult = async (rBuild) => {
		let key = `${rBuild.bundle}result.json`;
		if (key.indexOf("public/") === 0) {
			key = key.substr(7);
		}

		let resultJson = null;
		try {
			let result = await Storage.get(key, {
				download: true,
			});
			resultJson = await new Response((result as any).Body).json();
			console.log("loadResult~~~resultJson", resultJson);
			await calculateTimes(resultJson);
		} catch (e) {
			console.error(e);
		}
	};

	const calculateTimes = async (bundle) => {
		let layers = new Array(bundle.LayersCount);
		let totalTime = bundle["Times"]["All Print"]["Total"]["seconds"] * 1000;
		for (const [key, value] of Object.entries(
			bundle.Times["All Print"].Slabs
		)) {
			if (key.indexOf("Slab_") !== 0) {
				continue;
			}
			for (const [layer, layerData] of Object.entries(value as any)) {
				if (layer.indexOf("LayerPrint_") !== 0) {
					continue;
				}
				layers[Number(layer.substr("LayerPrint_".length)) - 1] = layerData;
			}
		}
		console.log("Init startpoint");
		let startPoint = {};
		startPoint["layer"] = 1;
		startPoint["stage"] = "PRG";
		startPoint["currentStartTime"] = Date.now();
		let timeSpent = 0;
		let pasteSpent = 0.0;

		for (let i = 0; i < startPoint["layer"]; i++) {
			timeSpent += layers[i]["Total"]["seconds"] * 1000;
			pasteSpent += layers[i]["extruded"] + layers[i]["extruded"];
		}

		let timeToPass = 0;
		let timeToPasteEnd = -1;
		for (let i = startPoint["layer"] - 1; i < layers.length; i++) {
			pasteSpent += layers[i]["extruded"] + layers[i]["purged"];
			if (pasteSpent >= 0) {
				timeToPasteEnd = timeToPass;
			}
			timeToPass += layers[i]["Total"]["seconds"] * 1000;
		}
		setReviewValues({
			timeToPasteEnd: Date.now() + timeToPasteEnd,
			totalTime: Date.now() + totalTime,
			pasteSpent: pasteSpent.toFixed(2),
		}
		)
	};

	return (
		<>
			<div
				className="display-card build-card-view container"
				id={`build-${props.displayBuild.index}`}
				key={`${props.displayBuild.index}`}
				// onClick={() =>
				// 	props.displayBuild.isSelected
				// 		? props.onSelectPart(props.displayBuild.id)
				// 		: props.onNavigateToBuildPreviewPage(props.displayBuild.id, props.history)
				// }
				onClick={() =>
					props.displayBuild.status &&
						(
							(props.displayBuild.status.toLowerCase().includes('completed')) ||
							(props.displayBuild.status.toLowerCase().includes('fail') && !isConfigDisabled)
						) ?
						(props.onNavigateToBuildPreviewPage(props.displayBuild.id, props.history)) : (null)
				}
			>
				<div>
					<div className="build-preview-img">
						<img src='/img/builds-image.png' className='build-image' alt="BuildImage" />
						<div className="display-card-row">
							<div className="display-card-sub-header">
								{(props.displayBuild.current_status === "completed" ||
									props.displayBuild.status === "completed") && (
										<span className="capitalise-text font-weight-bold build-status">

											<img src="/img/slicing-completed.svg" alt="Completed Status Icon" />
											{" " + "Slicing Completed"}
										</span>
									)}

								{(props.displayBuild.current_status === "failed" ||
									props.displayBuild.status === "failed") && (
										<span className="capitalise-text font-weight-bold build-status">
											<img src="/img/slicing-failed.svg" alt="Failed Status Icon" />
											{" " + "Slicing Failed"}
										</span>
									)}
								{(props.displayBuild.current_status === "canceled" ||
									props.displayBuild.status === "canceled") && (
										<span className="capitalise-text font-weight-bold build-status">
											<img src="/img/slicing-failed.svg" alt="Canceled Status Icon" />
											{" " + "Slicing Canceled"}
										</span>
									)}
								{(props.displayBuild.current_status === "processing" || props.displayBuild.current_status === "waiting" ||
									props.displayBuild.status === "processing" || props.displayBuild.status === "waiting") && (
										<div>
											{props.displayBuild.current_status === "processing" ?
												(
													<label className="pl-0">{`${buildProgress}%`}</label>
												) : (
													<span className="capitalise-text font-weight-bold build-status">
														<img src="/img/slicing-inprogress.svg" alt="Canceled Status Icon" />
														{" " + "Slicing in Progress"}
													</span>
												)}
											<ProgressBar
												variant="success"
												now={buildProgress}
											/>
										</div>
									)}
							</div>
						</div>
					</div>
					<div className="display-card-row">
						<div className="display-card-header">
							<label>{props.displayBuild.name}</label>
						</div>
						{/* <div
								className="display-card-more-action"
								onClick={(e) => e.stopPropagation()}
							>
								<DisplayBuildOptions displayBuild={props.displayBuild} />
							</div> */}
					</div>
					<div className="display-card-row">
						<div className="display-card-sub-header">
							<div className='details'>
								<label>Est. Print Time:</label>
								{(props.displayBuild.current_status === "completed" || props.displayBuild.status === "completed") ?
									(
										<span>
											{((reviewValues?.totalTime - Date.now()) / 1000 / 60 / 60 + ((reviewValues?.totalTime - Date.now()) / 1000 / 60 / 60) * 0.1).toString().split(".")[0] + " hr + " + (((((reviewValues?.totalTime - Date.now()) / 1000 / 60) % 60) +
												(((reviewValues?.totalTime - Date.now()) / 1000 / 60) % 60) * 0.1) % 60).toString().split(".")[0] + " min"}
										</span>
									) :
									(
										<span>NA</span>
									)
								}

							</div>
							<div className='details'>
								<label>Est. Paste Required:</label>
								{(props.displayBuild.current_status === "completed" || props.displayBuild.status === "completed") ?
									(
										<span>{parseInt(reviewValues?.pasteSpent) + " ml"}</span>
									) :
									(
										<span>NA</span>
									)
								}
								{/* <span>({parseInt(reviewValues?.pasteSpent) + " ml"})</span> */}

							</div>
							<div className='details'>
								<label>Dimensions:</label>
								<span>150mm x 150mm x 45mm</span>
							</div>
							<div className='details'>
								<label>Parts Plated:</label>
								<span>{props.displayBuild.parts.length}</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);

}

const DisplayBuildCardView = withRouter(connect(Props, Actions)(DisplayBuildsPreviewMethod));

export default DisplayBuildCardView;
