import React from "react";
import { connect } from "react-redux";
import {
	BuildsHeader,
	BuildsSection,
	PartsHeader,
	PartsSection,
	HomePageTabBar,
	PlatesHeader,
	PlatesSection,
	NewFolder,
	NewTag,
	AllViewHeader,
	Dashboard,
} from "../Components";
import {
	onHideAllParts,
	onHideArchivedParts,
	onSearchPartsTextChange,
	onShowRecentParts,
	onUnselectParts,
} from "../Actions/Parts";
import { onLoad } from "../Actions/Home";
import { TabIndex } from "../Enums";
import { IState } from "../Interfaces";
import { withRouter } from "react-router-dom";
import {
	onHideAllBuilds,
	onHideArchivedBuilds,
	onShowRecentBuilds,
} from "../Actions/Builds";
import {
	onHideAllPlates,
	onHideArchivedPlates,
	onShowRecentPlates,
} from "../Actions/Plates";
import ReviewPage from './ReviewPage';
import ListSearchResults from "../Components/Search/SearchResultsListing";
import LoadingComponent from "../../LoadingComponent/LoadingComponent";

const Props = (state: IState, ownProps: any) => {
	const isLoadingStarted =
		state.data.parts.isLoadingStarted ||
		state.data.plates.isLoadingStarted ||
		state.data.builds.isLoadingStarted ||
		state.data.configurations.isLoadingStarted;
	const isLoadingComplete =
		state.data.parts.isLoadingComplete &&
		state.data.plates.isLoadingComplete &&
		state.data.builds.isLoadingComplete &&
		state.data.configurations.isLoadingComplete;

	const props = {
		selectedTabIndex: state.data.info.homePageTabIndex as TabIndex,
		isLoadingStarted: isLoadingStarted as boolean,
		isLoadingComplete: isLoadingComplete as boolean,
		selectedPartIds: state.data.parts.selectedPartIds as string[],
		isPartsSearchCompleted : state.data.search.partsSearchCompleted as boolean,
		isPlatesSearchCompleted: state.data.search.platesSearchCompleted as boolean,
		isBuildsSearchCompleted: state.data.search.buildsSearchCompleted as boolean,
		isSearchEnabled:state.data.search.isSearchEnabled as boolean,
		displayLoader :state.data.search.displayLoader as boolean

	
	};

	return props;
};

const Actions = {
	onLoad: onLoad,
	onShowRecentParts: onShowRecentParts,
	onShowRecentPlates: onShowRecentPlates,
	onShowRecentBuilds: onShowRecentBuilds,
	onHideAllParts: onHideAllParts,
	onHideAllPlates: onHideAllPlates,
	onHideAllBuilds: onHideAllBuilds,
	onHideArchivedParts: onHideArchivedParts,
	onHideArchivedPlates: onHideArchivedPlates,
	onHideArchivedBuilds: onHideArchivedBuilds,
	onUnselectParts: onUnselectParts,
	onSearchPartsTextChange: onSearchPartsTextChange,
};

class Component extends React.Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = { 
			tatalBuildsCount: 0,
			totalPlatesCount: 0,
			totalPartsCount: 0,
			sorted: false,
			filter: undefined,
			searchEnabled :false
		}
  		
		if (!props.isLoadingComplete && !props.isLoadingStarted) props.onLoad();
	}

	callbackFunction = (childData) => {
		this.setState({
			tatalBuildsCount: childData,
			totalPlatesCount: childData,
			totalPartsCount: childData,
		});
	}

	componentDidMount() {
		this.props.onShowRecentParts();
		this.props.onShowRecentPlates();
		this.props.onShowRecentBuilds();
		this.props.onHideAllParts();
		this.props.onHideAllPlates();
		this.props.onHideAllBuilds();
		this.props.onHideArchivedParts();
		this.props.onHideArchivedPlates();
		this.props.onHideArchivedBuilds();
	}

	componentWillUnmount() {
	}

	onSort = (childData) => {
		this.setState({
			sorted: !this.state.sorted,
		});
	}

	onFilter = (childData) => {
		this.setState({
			filter: childData
		});
	}

	// onSort : ()=> {
	// 	this.setState({
	// 		sorted: sortedUpdate
	// 	})
	// }

	public render() {
		return (
			<>
				
				<section className="home page">
					<LoadingComponent visible={this.props.displayLoader}></LoadingComponent>
					<header>
						<HomePageTabBar />
						<NewFolder />
						<NewTag />
					</header>
					<>
						{this.props.selectedTabIndex === TabIndex.ALL ? (
							<>
								<section>
									<AllViewHeader sorted={this.state.sorted} onSort={this.onSort} onFilter={this.onFilter} />
									<div className="dashboard-page scrollabe-content">
										{(this.props.isBuildsSearchCompleted && this.props.isPlatesSearchCompleted && this.props.isPartsSearchCompleted) && (<div className="dashboard-page scrollabe-content"><ListSearchResults></ListSearchResults></div>)}
										{!this.props.isSearchEnabled && <Dashboard sorted={this.state.sorted} filter={this.state.filter} />}

										{
											(this.props.isSearchEnabled && (!this.props.isBuildsSearchCompleted && !this.props.isPlatesSearchCompleted && !this.props.isPartsSearchCompleted)) ? <LoadingComponent visible={true} /> : <LoadingComponent visible={false} />
										}
									</div>
								</section>
							</>
						) : null}
					</>
					<>
						{this.props.selectedTabIndex === TabIndex.PARTS ? (
							<>
								<section>
									{/* <PartsHeader loadedPartsCount={this.state.totalPartsCount}/> */}
									{/* <div className="scrollable-content"> */}
									<div>
									<ReviewPage />
									{/* <PartsSection  parentCallback = {this.callbackFunction}/> */}
									</div>
								</section>
							</>
						) : null}
					</>
					<>
						{this.props.selectedTabIndex === TabIndex.PLATES ? (
							<>
								<section>
									{/* <PlatesHeader loadedPlatesCount={this.state.totalPlatesCount}/> */}
									{/* <div className="scrollable-content"> */}
									<div>
									<ReviewPage />
										{/* <PlatesSection parentCallback = {this.callbackFunction} /> */}
									</div>
								</section>
							</>
						) : null}
					</>
					<>
						{this.props.selectedTabIndex === TabIndex.BUILDS ? (
							<>
								<section>
								<AllViewHeader sorted={this.state.sorted} onSort={this.onSort} onFilter={this.onFilter} />
									<div key={`div_${this.props.selectedTabIndex}`} className="dashboard-page scrollable-content" >

										{this.props.isBuildsSearchCompleted && (<div className="dashboard-page scrollabe-content"><ListSearchResults></ListSearchResults></div>)}
										{!this.props.isSearchEnabled && <BuildsSection sorted={this.state.sorted} filter={this.state.filter} />}
										{(this.props.isSearchEnabled && (!this.props.isBuildsSearchCompleted && !this.props.isPlatesSearchCompleted && !this.props.isPartsSearchCompleted)) ? <LoadingComponent visible={true} /> : <LoadingComponent visible={false} />}
									</div>
								</section>
							</>
						) : null}
					</>
				</section>
			</>
		);
	}
}

const HomePage = withRouter(connect(Props, Actions)(Component));

export default HomePage;
