import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { DisplayBuilds, NewBuild, MoveBuild, DisplayBuildCardView, DisplayBuildListView } from '../..';
import { onShowRecentBuilds, onHideRecentBuilds, onShowAllBuilds, onHideAllBuilds, onShowArchivedBuilds, onHideArchivedBuilds } from '../../../Actions/Builds';
import { onUnselectFolder, onLoadBuilds } from '../../../Actions/Home';
import { SortDirection } from '../../../Enums';
import { onSearchBuildsTextChange, onSortBuildsAsc, onSortBuildsDesc } from '../../../Actions/Builds';
import { IDisplayFolder, IDisplayBuild, IState } from '../../../Interfaces';
import { getDisplayFolders, getDisplayBuilds } from '../../../Mappers';
import DisplayDashboardBuildCardView from './DisplayDashboardBuildCardView';
import DisplayMoreBuilds from './DisplayMoreBuilds';
import NewBuildSuccess from '../../NewBuildSuccess';

const Props = (state: IState, ownProps: any) => {

	const displayFolders = getDisplayFolders(state) as IDisplayFolder[];

	const totalFolders = (
		(state.data.folders.sortDirection === SortDirection.ASC) ?
			displayFolders.reverse() :
			displayFolders
	) as IDisplayFolder[];

	const filteredFolders = (
		totalFolders.filter(df => df.isFiltered)
	) as IDisplayFolder[];

	const selectedFolders = (
		filteredFolders.filter(ff => ff.isSelected)
	) as IDisplayFolder[];

	const recentFolders = (filteredFolders.filter(ff => !ff.isSelected && ff.isRecent)) as IDisplayFolder[];

	const allFolders = (filteredFolders.filter(ff => !ff.isSelected && !ff.isArchived)) as IDisplayFolder[];

	const archivedFolders = (
		filteredFolders.filter(ff => !ff.isSelected && ff.isArchived)
	) as IDisplayFolder[];

	const hasSelectedFolders = Boolean(selectedFolders.length > 0) as boolean;

	const displayBuilds = getDisplayBuilds(state) as IDisplayBuild[];

	const totalBuilds = (
		(state.data.builds.sortDirection === SortDirection.ASC) ?
			displayBuilds.reverse() :
			displayBuilds
	) as IDisplayBuild[];

	const filteredBuilds = (
		totalBuilds.filter(db => db.isFiltered)
	) as IDisplayBuild[];

	const selectedBuilds = (
		filteredBuilds.filter(fb => fb.isSelected)
	) as IDisplayBuild[];

	const recentBuilds = (
		filteredBuilds.filter(fb => !fb.isSelected && !fb.isArchived && fb.isRecent)
	) as IDisplayBuild[];

	const allBuilds = (
		filteredBuilds.filter(fb => !fb.isSelected && !fb.isArchived)
	) as IDisplayBuild[];

	const archivedBuilds = (
		filteredBuilds.filter(fb => !fb.isSelected && fb.isArchived)
	) as IDisplayBuild[];

	const hasSelectedBuilds = Boolean(selectedBuilds.length > 0) as boolean;

	const showSelectedBuilds = Boolean(state.data.sections.recentBuilds.showItems) as boolean;
	const showRecentBuilds = Boolean(state.data.sections.recentBuilds.showItems) as boolean;
	const showAllBuilds = Boolean(state.data.sections.allBuilds.showItems) as boolean;
	const showArchivedBuilds = Boolean(state.data.sections.archivedBuilds.showItems) as boolean;

	const props = {
		showListView: state.data.info.showListView as boolean,
		filteredFolders: filteredFolders as IDisplayFolder[],
		selectedFolders: selectedFolders as IDisplayFolder[],
		recentFolders: recentFolders as IDisplayFolder[],
		allFolders: allFolders as IDisplayFolder[],
		archivedFolders: archivedFolders as IDisplayFolder[],
		hasSelectedFolders: hasSelectedFolders as Boolean,
		filteredBuilds: filteredBuilds as IDisplayBuild[],
		selectedBuilds: selectedBuilds as IDisplayBuild[],
		recentBuilds: recentBuilds as IDisplayBuild[],
		allBuilds: allBuilds as IDisplayBuild[],
		archivedBuilds: archivedBuilds as IDisplayBuild[],
		hasSelectedBuilds: hasSelectedBuilds as Boolean,
		showSelectedBuilds: showSelectedBuilds as Boolean,
		showRecentBuilds: showRecentBuilds as Boolean,
		showAllBuilds: showAllBuilds as Boolean,
		showArchivedBuilds: showArchivedBuilds as Boolean,
		loadedBuilds: state.data.builds.loadedBuilds,
		sortDirection: state.data.builds.sortDirection,
		nextToken: state.data.builds.nextToken,

	};

	return props;
};

const Actions = {
	onUnselectFolder: onUnselectFolder,
	onShowRecentBuilds: onShowRecentBuilds,
	onHideRecentBuilds: onHideRecentBuilds,
	onShowAllBuilds: onShowAllBuilds,
	onHideAllBuilds: onHideAllBuilds,
	onShowArchivedBuilds: onShowArchivedBuilds,
	onHideArchivedBuilds: onHideArchivedBuilds,
	onLoadBuilds: onLoadBuilds,
	onSearchBuildsTextChange: onSearchBuildsTextChange,
	onSortBuildsAsc: onSortBuildsAsc,
	onSortBuildsDesc: onSortBuildsDesc,
};


class Component extends React.Component<any, any> {
	public loadMore() {
		this.props.onLoadBuilds();
	};

	public componentDidUpdate(oldProps) {
		console.log(oldProps);
		if (oldProps.sorted !== this.props.sorted) {
			if (this.props.sorted) {
				if (this.props.sortDirection !== SortDirection.ASC) {
					this.props.onSortBuildsAsc();
					this.props.onLoadBuilds(true);
				}
			}
			else {
				if (this.props.sortDirection !== SortDirection.DESC) {
					this.props.onSortBuildsDesc();
					this.props.onLoadBuilds(true);
				}
			}
		}
	}

	public buildListView() {
		return (
			<>
				<dl className='grid-view'>
					<dd>
						<>
							<div className="display-list">
								<div className="display-list-row">
									<div className="display-list-header">
										<label>Type</label>
									</div>
									<div className="display-list-header">
										<label>Name</label>
									</div>
									<div className="display-list-header">
										<label>Build Date</label>
									</div>
									<div className="display-list-header">
										<label>Owner</label>
									</div>
									<div className="display-list-header">
										<label>Status</label>
									</div>
									<div className="display-list-header">
										<label>Folder</label>
									</div>
									<div className="display-list-header">
										<label>Actions</label>
									</div>
								</div>
							</div>
						</>
						<>
							{
								this.props.filteredBuilds.map((fb, i) => {
									return (
										<DisplayBuildListView
											{...{
												key: i,
												index: i,
												displayBuild: fb,
												isSelected: fb.isSelected,
												onUnselect: this.props.onUnselect,
												onSelect: this.props.onSelect,
											}}
										/>
									)
								})
							}
						</>
					</dd>
				</dl>
			</>
		)
	}

	public buildCardView() {
		return (
			<>
				{
					this.props.hasSelectedFolders ?
						this.props.selectedFolders.map(selectedFolder => this.selectedFolderView(selectedFolder)) :
						this.buildsView()
				}
			</>
		)
	}

	public selectedFolderView(selectedFolder) {
		return (
			<>

				{/* <Breadcrumb>

					<Breadcrumb.Item
						onClick={() => this.props.onUnselectFolder(selectedFolder["id"])}
					>
						Builds
					</Breadcrumb.Item>
					<Breadcrumb.Item active>
						{selectedFolder["name"]}
					</Breadcrumb.Item>
				</Breadcrumb> */}
				<dl className={`${this.props.showListView ? "grid-view" : ""}`}>
					<dd>
						<div className="options left-justify mt-3 w-100">
							<div className="back option">
								<div className="backIcon" onClick={() => this.props.onUnselectFolder(selectedFolder["id"])}></div>
								<label>{selectedFolder["name"]}</label>
							</div>
						</div>
						{
							this.props.showListView ?
								(
									<>
										<div className="display-list">
											<div className="display-list-row">
												<div className="display-list-header">
													<label>Type</label>
												</div>
												<div className="display-list-header">
													<label>Name</label>
												</div>
												<div className="display-list-header">
													<label>Build Date</label>
												</div>
												<div className="display-list-header">
													<label>Owner</label>
												</div>
												<div className="display-list-header">
													<label>Status</label>
												</div>
												<div className="display-list-header">
													<label>Folder</label>
												</div>
												<div className="display-list-header">
													<label>Actions</label>
												</div>
											</div>
										</div>
									</>
								)
								:
								(
									null
								)
						}
						{
							selectedFolder["displayBuilds"]
								.filter(db => db.folders.some(f => f === selectedFolder["name"]))
								.map((db, i) => this.props.showListView ? <DisplayBuildListView key={i} displayBuild={db} /> : <DisplayDashboardBuildCardView key={i} displayBuild={db} />)
						}
					</dd>
				</dl>
			</>
		)
	}

	public buildsView() {
		return (
			<>
				<DisplayMoreBuilds
					{...{
						label: false,
						displayFolders: this.props.allFolders,
						showOptions: false,
						showItems: true,
						filter: this.props.filter,
						onShowItems: this.props.onShowAllBuilds,
						onHideItems: this.props.onHideAllBuilds,
					}}
				/>
			</>
		)
	}

	public render() {
		return (
			<>
				{
					this.props.showListView ?
						this.buildListView() :
						this.buildCardView()
				}
				{
					this.props.nextToken && !this.props.hasSelectedFolders &&  (
						<div className="d-block w-100 text-center pb-4">
							<button onClick={() => this.loadMore()} className="btn btn-primary justify-content-center">
								Load More Builds<span className="btn-right-arrow"></span></button>
						</div>
					) 
				}
				{/* {
					(this.props.filteredBuilds && this.props.filteredBuilds?.length && this.props.hasSelectedFolders ) && <div className="d-block w-100 text-center">
					<p className="justify-content-center p-4">
						No data to load</p>
				</div>
				} */}
				<NewBuild />
				<NewBuildSuccess/>
				<MoveBuild />
			</>
		);
	}
}

const BuildsSection = withRouter(connect(Props, Actions)(Component));

export default BuildsSection;
